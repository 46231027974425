import React from 'react';

const Nothing = () => {
    return (
        <p>
            Nothing to see here, please move along.
        </p>
    );
}

export default Nothing;