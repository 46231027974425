import React, { Component } from "react";

class Graph extends Component {

    shouldComponentUpdate(nextProps) {
        // Rendering the component only if 
        // passed props value is changed

     return true
    }



    render() {

        return (

            <div className="graph" ref={this.props.data} />

        );
    }

}

export default Graph;