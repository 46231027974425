import StitchedText from "./StitchedText";
import NotionMedia from "./NotionMedia";


const NotionParagraph = ({ itemData }) => {

    //Function to create random string
    function makeID(length) {
        var result = '';
        var characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
        var charactersLength = characters.length;
        for (var i = 0; i < length; i++) {
            result += characters.charAt(Math.floor(Math.random() *
                charactersLength));
        }
        return result;
    }

    //Carefully getting data from Notion properties -- remember to replace property names depending on project db!
    const itemID = itemData.id
    const titleArray = itemData.properties['Name'] && itemData.properties['Name'].title
    const textArray = itemData.properties['Description'] && itemData.properties['Description'].rich_text
    const mediaArray = itemData.properties['Photo/ Video'] && itemData.properties['Photo/ Video'].files
    const mediaCaptionArray = itemData.properties['Media Caption'] && itemData.properties['Media Caption'].rich_text
    const coverImageArray = itemData.properties['Cover image'] && itemData.properties['Cover image'].files


    //Returning JSX elements – remember to edit classNames based on project context!
    const iconTitleClassName = 'marketStudies-iconTitleContainer'
    const iconClassName = 'marketStudies-iconContainer'
    const titleClassName = 'marketStudies-titleText'
    const textClassName = 'marketStudies-regularText'
    const mediaCaptionClassName = 'marketStudies-mediaCaption'


    return (

        <>

            {titleArray[0] !== undefined &&
                <>
                    <div className={iconTitleClassName} >

                        {coverImageArray[0] !== undefined &&
                            <div className={iconClassName}>
                                <NotionMedia mediaArray={coverImageArray} type={'sticker'} />

                            </div>

                        }

                        <div className={titleClassName} key={itemID + 'title'}>
                            {
                                titleArray.map((textPiece) => (

                                    <StitchedText data={textPiece} key={makeID(16)} type={'header'} />

                                ))
                            }


                        </div>

                    </div>


                </>

            }


            {mediaArray[0] !== undefined &&

                <NotionMedia type={'regular'} mediaArray={mediaArray} />
            }

            {mediaCaptionArray[0] !== undefined &&

                <div className={mediaCaptionClassName} key={itemID + 'mediaCaption'}>
                    {
                        mediaCaptionArray.map((textPiece) => (

                            <StitchedText data={textPiece} key={makeID(16)} type={'header'} />

                        ))
                    }
                </div>
            }


            {textArray[0] !== undefined &&

                <div className={textClassName} key={itemID + 'text'}>
                    {
                        textArray.map((textPiece) => (

                            <StitchedText data={textPiece} key={makeID(16)} type={'paragraph'} />

                        ))
                    }
                </div>

            }
        </>


    );
}

export default NotionParagraph;