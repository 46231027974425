import React, { useState } from "react";
import { useEffect } from "react";

import Plx from "react-plx";
import SlideShow from "./Animations/SlideShow";
import Slide from "./Animations/Slide";
import GrowingCircle from "./Animations/GrowingCircle";
import IntroSlide from "./Animations/IntroSlide";
import IntroSlideItem from "./Animations/IntroSlideItem";
import SlideshowVideos from "./SlideShowVideos";

import sandClock from './media/sandClock.gif'


// const SlideShow = React.lazy(() => import('./Animations/SlideShow'));
// const Slide = React.lazy(() => import('./Animations/Slide'));
// const GrowingCircle = React.lazy(() => import('./Animations/GrowingCircle'));
// const IntroSlide = React.lazy(() => import('./Animations/IntroSlide'));
// const IntroSlideItem = React.lazy(() => import('./Animations/IntroSlideItem'));
// const SlideshowVideos = React.lazy(() => import('./SlideShowVideos'));

var landscapeMode = require('./media/intro/landscapeMode.png')





var introBG = require('./media/introBG.png')
var introLayer0 = require('./media/introLayer0.png')
var introLayer1 = require('./media/introLayer1.png')
var introLight = require('./media/introLight.png')
var introLighthouse = require('./media/introLighthouse.png')

var introImage01 = require('./media/intro/image01.png')
var introImage02 = require('./media/intro/image02.png')
var introImage04 = require('./media/intro/image04.gif')

var neonMic = require('./media/intro/neonMic02.png')
var neonHeart = require('./media/intro/neonHeart02.png')

var slide01_gif01 = require('./media/intro/slide01_gif01.gif')
var slide01_gif02 = require('./media/intro/slide01_gif02.gif')
var slide01_gif03 = require('./media/intro/slide01_gif03.gif')

var bang = require('./media/intro/bang.png')
var koreaFlag = require('./media/intro/koreaFlag.png')
var laptop = require('./media/intro/laptop.png')

var slide02_01 = require('./media/intro/slide02_01.png')
var slide02_02 = require('./media/intro/slide02_02.png')
var slide02_03 = require('./media/intro/slide02_03.png')
var slide02_04a = require('./media/intro/slide02_04a.png')
var slide02_04b = require('./media/intro/slide02_04b.gif')
var slide02_05 = require('./media/intro/slide02_05.png')

var slide03_gif = require('./media/intro/slide03_gif.gif')

var slide04_01 = require('./media/intro/slide04_01.png')
var slide04_gifLeft = require('./media/intro/slide04_gifLeft.gif')
var slide04_gifRight = require('./media/intro/slide04_gifRight.gif')



var slide05_01 = require('./media/intro/slide05_01.png')

var slide06_iPhone = require('./media/intro/slide06_iPhone.png')

var slide06_shift01 = require('./media/intro/slide06_shift01.png')
var slide06_shift02 = require('./media/intro/slide06_shift02.png')
var slide06_shift03 = require('./media/intro/slide06_shift03.png')
var slide06_shift04 = require('./media/intro/slide06_shift04.png')
var slide06_shift05 = require('./media/intro/slide06_shift05.png')
var slide06_shift06 = require('./media/intro/slide06_shift06.png')
var slide06_shift07 = require('./media/intro/slide06_shift07.png')
var slide06_shift08 = require('./media/intro/slide06_shift08.png')

var slide06_01 = require('./media/intro/slide06_01.png')
var slide06_02 = require('./media/intro/slide06_02.png')
var slide06_03 = require('./media/intro/slide06_03.png')
var slide06_04 = require('./media/intro/slide06_04.png')
var slide06_05 = require('./media/intro/slide06_05.png')
var slide06_06 = require('./media/intro/slide06_06.png')
var slide06_07 = require('./media/intro/slide06_07.png')




var slide07_01 = require('./media/intro/slide07_01.png')
var slide07_02 = require('./media/intro/slide07_02.png')
var slide07_03 = require('./media/intro/slide07_03.png')
var slide07_04 = require('./media/intro/slide07_04.png')




var slide08_happy = require('./media/intro/slide08_happy.png')
var slide08_exploring = require('./media/intro/slide08_exploring.png')
var slide08_01 = require('./media/intro/slide08_01.png')
var slide08_02 = require('./media/intro/slide08_02.png')
var slide08_03 = require('./media/intro/slide08_03.png')
var slide08_04 = require('./media/intro/slide08_04.png')
var slide08_05 = require('./media/intro/slide08_05.png')
var slide08_06 = require('./media/intro/slide08_06.png')
var slide08_07 = require('./media/intro/slide08_07.png')
var slide08_08 = require('./media/intro/slide08_08.png')


var slideshow_bg1 = require('./media/intro/slideshow_bg1.jpg')
var slideshow_bg2 = require('./media/intro/slideshow_bg2.jpg')
var slideshow_bg3 = require('./media/intro/slideshow_bg3.jpg')


var cursor = require('./media/intro/cursor.png')


const Introduction = ({ intro, setGraphDone, setSidebarRightOpen, setNavbarBG, navbarScrollBehavior, mobileDevice, networkPage }) => {

    //with the 1024px DEVICE width threshold we include all mobile devices up until iPad Pro size. in combination with the 'orientation:portrait' query, this will result in ensuring: 'a mobile device in portrait mode'. (BUT exclude most instances of viewing on a laptop, while the browser window's size has a larger height than width...)
    const mediaQuery = window.matchMedia('(max-device-width: 1024px) and (orientation:portrait)')

    // const mediaQuery = window.matchMedia('(orientation:portrait)')

    const [portraitMode, setPortraitMode] = useState(mediaQuery.matches)

    window.matchMedia('(orientation:portrait)').addEventListener('change', e => {

        setPortraitMode(e.matches)

    });

    // useEffect(() => {

    //     if (!portraitMode && firstLoad) {
    //         document.getElementById('introMagic').style.opacity = '1'

    //         setTimeout(() => {
    //             document.getElementById('intro-title').style.opacity = '1'
    //         }, 200);

    //         document.getElementById('page-intro').style.visibility = 'unset'


    //     }

    //     else if (portraitMode){


    //         document.getElementById('introMagic').style.opacity = '0'

    //         setTimeout(() => {
    //             document.getElementById('intro-title').style.opacity = '0'
    //         }, 200);

    //         document.getElementById('page-intro').style.visibility = 'hidden'



    //     }


    // }, [portraitMode])
    function stickNavbar() {
        document.getElementById('navbar').style.top = '0px'
        navbarScrollBehavior.current = false

    }

    function unstickNavbar() {
        document.getElementById('navbar').style.top = '-64px'
        navbarScrollBehavior.current = true

    }



    useEffect(() => {

        setGraphDone(false)

        setSidebarRightOpen(false)

        setNavbarBG(false)
        navbarScrollBehavior.current = false



        document.getElementById('bgVideo01').style.display = 'none'
        document.getElementById('bgVideo02').style.display = 'none'


        networkPage.current = false


    }, []);


    //Intro animations settings

    const introBGAnimation = [
        {
            start: '0px',
            duration: '20vh',
            easing: 'linear',
            properties: [
                {
                    startValue: -0,
                    endValue: -400,
                    property: "bottom",
                    unit: "px"
                },

            ]

        },
    ];


    const introLayer0Animation = [
        {
            start: 'self',
            duration: '20vh',
            easing: 'linear',
            properties: [
                {
                    startValue: -13.7,
                    endValue: -11.7,
                    property: "top",
                    unit: "vw"
                },

            ]

        },
    ];


    const introLayer1Animation = [
        {
            start: 'self',
            duration: '20vh',
            easing: 'linear',
            properties: [
                {
                    startValue: -11.3,
                    endValue: -7.3,
                    property: "top",
                    unit: "vw"
                },

            ]

        },
    ];


    const introLighthouseAnimation = [
        {
            start: 'self',
            duration: '20vh',
            easing: 'linear',
            properties: [
                {
                    startValue: -5,
                    endValue: -4,
                    property: "top",
                    unit: "vw"
                },

            ]

        },
    ];

    const introTitleAnimation = [
        {
            start: 'self',
            duration: '5vh',
            easing: 'linear',
            properties: [
                {
                    startValue: 47,
                    endValue: 45.5,
                    property: "bottom",
                    unit: "%"
                },

            ]

        },
    ];

    //////Image animations


    const rotatingBubble01 = ({ start }) => [
        {
            start: start,
            startOffset: '20vh',
            duration: '40vh',
            easing: 'easeInOut',
            properties: [

                {
                    startValue: 0,
                    endValue: 360,
                    property: "rotateY",
                },


            ]

        },
        {
            start: start,
            startOffset: '20vh',
            duration: '20vh',
            easing: 'easeIn',
            properties: [

                {
                    startValue: 0,
                    endValue: 5,
                    property: "rotateZ",
                },


            ]

        },
        {
            start: start,
            startOffset: '40vh',
            duration: '20vh',
            easing: 'easeOut',
            properties: [

                {
                    startValue: 5,
                    endValue: 0,
                    property: "rotateZ",
                },


            ]

        },

    ];

    const rotatingBubble02 = ({ start }) => [
        {
            start: start,
            startOffset: '60vh',
            duration: '40vh',
            easing: 'easeInOut',
            properties: [

                {
                    startValue: 0,
                    endValue: 360,
                    property: "rotateY",
                },


            ]

        },
        {
            start: start,
            startOffset: '60vh',
            duration: '20vh',
            easing: 'easeIn',
            properties: [

                {
                    startValue: 0,
                    endValue: -5,
                    property: "rotateZ",
                },


            ]

        },
        {
            start: start,
            startOffset: '80vh',
            duration: '20vh',
            easing: 'easeOut',
            properties: [

                {
                    startValue: -5,
                    endValue: 0,
                    property: "rotateZ",
                },


            ]

        },

    ];



    const bouncingImageAnimation = ({ start, startOffset, duration }) => [

        {
            start: `#${start}`,
            startOffset: `${startOffset}vh`,
            duration: `${duration}vh`,
            easing: 'linear',
            properties: [

                {
                    startValue: 1,
                    endValue: 3,
                    property: "scale",
                },

            ]

        },
        {
            start: `#${start}`,
            startOffset: `${startOffset + duration}vh`,
            duration: `${duration}vh`,
            easing: 'linear',
            properties: [

                {
                    startValue: 3,
                    endValue: 1,
                    property: "scale",

                },

            ]

        },

    ];

    const neonMicAni = [
        {
            start: '#slide01',
            startOffset: '0vh',
            duration: '30vh',
            easing: 'linear',
            properties: [
                {
                    startValue: 150,
                    endValue: 0,
                    property: "translateY",
                },
                {
                    startValue: 0,
                    endValue: 1,
                    property: "opacity",
                },

            ]

        },
        {
            start: '#slide01',
            startOffset: '70vh',
            duration: '20vh',
            easing: 'linear',
            properties: [
                {
                    startValue: 0,
                    endValue: 5,
                    property: "top",
                    unit: "vh"
                },

            ]

        },

    ];

    const neonHeartAni = [
        {
            start: '#slide01',
            startOffset: '0vh',
            duration: '30vh',
            easing: 'linear',
            properties: [
                {
                    startValue: 150,
                    endValue: 0,
                    property: "translateY",
                },
                {
                    startValue: 0,
                    endValue: 1,
                    property: "opacity",
                },

            ]

        },
        {
            start: '#slide01',
            startOffset: '70vh',
            duration: '20vh',
            easing: 'linear',
            properties: [
                {
                    startValue: 0,
                    endValue: -5,
                    property: "bottom",
                    unit: "vh"
                },

            ]

        },
    ];

    const column1Bounce = [
        {
            start: '#slide02',
            startOffset: '30vh',
            duration: '10vh',
            easing: 'linear',
            properties: [
                {
                    startValue: 1,
                    endValue: 1.2,
                    property: "scale",
                },

            ]

        },
        {
            start: '#slide02',
            startOffset: '40vh',
            duration: '10vh',
            easing: 'linear',
            properties: [
                {
                    startValue: 1.2,
                    endValue: 1,
                    property: "scale",
                },

            ]

        },
    ];

    const column2Bounce = [
        {
            start: '#slide02',
            startOffset: '50vh',
            duration: '10vh',
            easing: 'linear',
            properties: [
                {
                    startValue: 1,
                    endValue: 1.2,
                    property: "scale",
                },

            ]

        },
        {
            start: '#slide02',
            startOffset: '60vh',
            duration: '10vh',
            easing: 'linear',
            properties: [
                {
                    startValue: 1.2,
                    endValue: 1,
                    property: "scale",
                },

            ]

        },
    ];

    const column3Bounce = [
        {
            start: '#slide02',
            startOffset: '70vh',
            duration: '10vh',
            easing: 'linear',
            properties: [
                {
                    startValue: 1,
                    endValue: 1.2,
                    property: "scale",
                },

            ]

        },
        {
            start: '#slide02',
            startOffset: '80vh',
            duration: '10vh',
            easing: 'linear',
            properties: [
                {
                    startValue: 1.2,
                    endValue: 1,
                    property: "scale",
                },

            ]

        },
    ];


    const column4Bounce = [
        {
            start: '#slide02',
            startOffset: '90vh',
            duration: '10vh',
            easing: 'linear',
            properties: [
                {
                    startValue: 1,
                    endValue: 1.2,
                    property: "scale",
                },

            ]

        },
        {
            start: '#slide02',
            startOffset: '100vh',
            duration: '10vh',
            easing: 'linear',
            properties: [
                {
                    startValue: 1.2,
                    endValue: 1,
                    property: "scale",
                },

            ]

        },
    ];

    const column5Bounce = [
        {
            start: '#slide02',
            startOffset: '110vh',
            duration: '10vh',
            easing: 'linear',
            properties: [
                {
                    startValue: 1,
                    endValue: 1.2,
                    property: "scale",
                },

            ]

        },
        {
            start: '#slide02',
            startOffset: '120vh',
            duration: '10vh',
            easing: 'linear',
            properties: [
                {
                    startValue: 1.2,
                    endValue: 1,
                    property: "scale",
                },

            ]

        },
    ];

    const slide03GifAnimation = [
        {
            start: '#slide03',
            startOffset: '0vh',
            duration: '20vh',
            easing: 'linear',
            properties: [
                {
                    startValue: 150,
                    endValue: 0,
                    property: "translateY",
                },
                {
                    startValue: 0,
                    endValue: 1,
                    property: "opacity",
                },

            ]

        },

    ];

    const shiftBubbleAni = ({ endX, endY }) =>
        [
            {
                start: '#slide06',
                startOffset: '300vh',
                duration: '60vh',
                easing: 'linear',
                properties: [
                    {
                        startValue: 0,
                        endValue: 1,
                        property: "opacity",
                    },
                    {
                        startValue: 0,
                        endValue: 0.66,
                        property: "scale",
                    },

                ]

            },
            {
                start: '#slide06',
                startOffset: '420vh',
                duration: '60vh',
                easing: 'linear',
                properties: [

                    {
                        startValue: 0.66,
                        endValue: 1,
                        property: "scale",
                    },
                    {
                        startValue: 1,
                        endValue: endX,
                        property: "translateX",
                    },
                    {
                        startValue: 1,
                        endValue: endY,
                        property: "translateY",
                    },


                ]

            },
        ]

    const iPhoneAnimation =
        [
            {
                start: '#slide06',
                startOffset: '420vh',
                duration: '60vh',
                easing: 'linear',
                properties: [
                    {
                        startValue: 1,
                        endValue: 0,
                        property: "opacity",
                    },
                    {
                        startValue: 1,
                        endValue: 0,
                        property: "scale",
                    },
                    {
                        startValue: 0,
                        endValue: 100,
                        property: "translateX",
                    },

                ]

            },
        ]


    const moveInColumn = ({ startValue }) =>


        [
            {
                start: '#slide07',
                startOffset: '30vh',
                duration: '60vh',
                easing: 'linear',
                properties: [
                    {
                        startValue: startValue,
                        endValue: 0,
                        property: "translateX",
                    },

                ]

            },
            {
                start: '#slide07',
                startOffset: '30vh',
                duration: '20vh',
                easing: 'linear',
                properties: [

                    {
                        startValue: 0,
                        endValue: 1,
                        property: "opacity",
                    },


                ]

            },


        ]

    const slide08Move = ({ zValue }) =>


        [
            {
                start: '#slide08',
                startOffset: '0vh',
                duration: '10vh',
                easing: 'linear',
                properties: [
                    {
                        startValue: 0,
                        endValue: 1,
                        property: "opacity",
                    },



                ]

            },
            {
                start: '#slide08',
                startOffset: '0vh',
                duration: '30vh',
                easing: 'linear',
                properties: [
                    {
                        startValue: 200,
                        endValue: 0,
                        property: "translateY",
                    },
                    {
                        startValue: -50,
                        endValue: 0,
                        property: "rotateX",
                    },

                    {
                        startValue: zValue,
                        endValue: 0,
                        property: "rotateZ",
                    },



                ]

            },


        ]



    const [img1Loaded, setImg1Loaded] = useState(false)
    const [img2Loaded, setImg2Loaded] = useState(false)
    const [img3Loaded, setImg3Loaded] = useState(false)
    const [img4Loaded, setImg4Loaded] = useState(false)


    function watchLoaded(no) {

        switch (no) {
            case 1:
                setImg1Loaded(true)
                break;
            case 2:
                setImg2Loaded(true)
                break;
            case 3:
                setImg3Loaded(true)
                break;
            case 4:
                setImg4Loaded(true)
                break;
        }

    }

    useEffect(() => {

        if (img1Loaded && img2Loaded && img3Loaded && img4Loaded && !portraitMode) {


            document.getElementById('introMagic').style.opacity = '1'

            setTimeout(() => {
                document.getElementById('intro-title').style.opacity = '1'
            }, 200);


            document.getElementById('introMagicPlaceholder').style.display = 'none'



        }



    }, [img1Loaded, img2Loaded, img3Loaded, img4Loaded])

    return (


        <>
            {intro && intro[0] &&
                <>

                    {!portraitMode && !(img1Loaded && img2Loaded && img3Loaded && img4Loaded) &&

                        <img src={sandClock} className="loading-icon" alt="Loading Network" />


                    }

                    {portraitMode &&

                        <div className="page-intro-portraitMode" id="page-intro-portraitMode">
                            <div className="intro-title-portraitMode">
                                {intro[0].text1} <br /> {intro[0].text2}
                            </div>
                            <img src={landscapeMode} alt="" style={{ filter: 'invert(100%)', width: '120px' }} />
                            <p>
                                Switch to landscape mode to watch intro – or choose different site in menu.
                            </p>
                        </div>

                    }


                    <>

                        <GrowingCircle />

                        <div className="introMagicPlaceholder" id="introMagicPlaceholder"></div>

                        <div className="introMagic" id="introMagic" style={{ opacity: `${portraitMode ? '0' : ''}` }}>
                            <Plx className="intro-introBG" parallaxData={introBGAnimation} >
                                <img src={introBG} onLoad={() => watchLoaded(1)} alt="" style={{ width: '100%', height: '100%' }} />
                            </Plx>
                            <Plx className="intro-layer0" parallaxData={introLayer0Animation} >
                                <img src={introLayer0} onLoad={() => watchLoaded(2)} alt="" style={{ width: '100%', height: '100%' }} />
                            </Plx>

                            <Plx className="intro-layer1" parallaxData={introLayer1Animation} >
                                <img src={introLayer1} onLoad={() => watchLoaded(3)} alt="" style={{ width: '100%', height: '100%' }} />
                            </Plx>

                            <Plx className="intro-lightHouse" parallaxData={introLighthouseAnimation} >
                                <img src={introLighthouse} onLoad={() => watchLoaded(4)} alt="" style={{ width: '100%', height: '100%' }} />
                            </Plx>

                            <Plx className="intro-title" parallaxData={introTitleAnimation} id="intro-title" style={{ opacity: `${!portraitMode ? '1' : '0'}` }} >
                                {intro[0] &&<>{intro[0].text1} <br /> {intro[0].text2}</>}
                            </Plx>


                        </div>



                        <div className="page-intro" id="page-intro" style={{ opacity: `${!portraitMode ? '1' : '0'}` }}>

                            <IntroSlide id={'slide01'} startAfter={79} endAfter={90} justify={'center'}>

                                <Plx parallaxData={neonMicAni} className="neonMic">
                                    <img src={neonMic} style={{ maxWidth: '120%', maxHeight: '120%', rotate: '-30deg' }} />
                                </Plx>
                                <Plx parallaxData={neonHeartAni} className="neonHeart">
                                    <img src={neonHeart} style={{ maxWidth: '180%', maxHeight: '180%', position: 'absolute', top: '-30%', left: '-60%' }} />
                                </Plx>

                                <IntroSlideItem id={'slide01'} startOffset={'0vh'} duration={'30vh'}>
                                    {intro[1] && <div className="slideTitle">{intro[1].text1}</div>}

                                </IntroSlideItem>

                                <IntroSlideItem id={'slide01'} startOffset={'30vh'} duration={'30vh'}>

                                    <div className="page-intro-slide1-columnContainer">
                                        <img src={koreaFlag} alt="" className="introEmoji01" />
                                        <img src={bang} alt="" className="introEmoji02" />
                                        <img src={laptop} alt="" className="introEmoji03" />


                                        <div className="page-intro-slide1-column">
                                            <div className="page-intro-slide1-column-gifContainer">
                                                <img src={slide01_gif01} alt="" className="introGif" />

                                            </div>
                                            <div className="page-intro-slide1-column-text">
                                                {intro[1] && intro[1].text2}

                                            </div>

                                        </div>
                                        <div className="page-intro-slide1-border" />
                                        <div className="page-intro-slide1-column">
                                            <div className="page-intro-slide1-column-gifContainer">
                                                <img src={slide01_gif02} alt="" className="introGif" />

                                            </div>
                                            <div className="page-intro-slide1-column-text">
                                                {intro[1] && intro[1].text3}

                                            </div>

                                        </div>
                                        <div className="page-intro-slide1-border" />
                                        <div className="page-intro-slide1-column">
                                            <div className="page-intro-slide1-column-gifContainer">
                                                <img src={slide01_gif03} alt="" className="introGif" />

                                            </div>
                                            <div className="page-intro-slide1-column-text">
                                                {intro[1] && intro[1].text4}

                                            </div>

                                        </div>

                                    </div>
                                </IntroSlideItem>


                            </IntroSlide>


                            <IntroSlide id={'slide02'} startAfter={15} endAfter={115} justify={'center'}>

                                <IntroSlideItem id={'slide02'} startOffset={'0vh'} duration={'20vh'}>

                                    <div className="page-intro-slide2-timelineContainer">
                                        <div className="page-intro-slide2-timeline-imagesRow">
                                            <Plx className="page-intro-slide2-timeline-imageColumn" style={{ width: '12%' }} parallaxData={column1Bounce}>
                                                <img src={slide02_01} alt="" style={{ maxWidth: '100%', maxHeight: '100%' }} />
                                            </Plx>
                                            <Plx className="page-intro-slide2-timeline-imageColumn" style={{ width: '12%' }} parallaxData={column2Bounce}>
                                                <img src={slide02_02} alt="" style={{ maxWidth: '100%', maxHeight: '100%' }} />
                                            </Plx>
                                            <Plx className="page-intro-slide2-timeline-imageColumn" style={{ width: '12%' }} parallaxData={column3Bounce}>
                                                <img src={slide02_03} alt="" style={{ maxWidth: '100%', maxHeight: '100%' }} />
                                            </Plx>
                                            <Plx className="page-intro-slide2-timeline-imageColumn" style={{ width: '23%' }} parallaxData={column4Bounce}>
                                                <img src={slide02_04a} alt="" style={{ maxWidth: '48%', maxHeight: '100%' }} />
                                                <img src={slide02_04b} alt="" style={{ maxWidth: '48%', maxHeight: '100%', borderRadius: '15px', border: '1.2px solid black' }} />

                                            </Plx>
                                            <Plx className="page-intro-slide2-timeline-imageColumn" style={{ width: '17%' }} parallaxData={column5Bounce}>
                                                <img src={slide02_05} alt="" style={{ maxWidth: '100%', maxHeight: '100%' }} />
                                            </Plx>


                                        </div>
                                        <div className="page-intro-slide2-timeline-arrowRow">
                                            <div className="page-intro-slide2-timeline-arrowLine">
                                                <div className="page-intro-slide2-timeline-arrow" />


                                            </div>
                                            <Plx className="page-intro-slide2-timeline-dotColumn" style={{ width: '12%' }} parallaxData={column1Bounce}>
                                                <div className="page-intro-slide2-timeline-arrowDot" />
                                                <div className="page-intro-slide2-timeline-arrowYear">1992</div>


                                            </Plx>
                                            <Plx className="page-intro-slide2-timeline-dotColumn" style={{ width: '12%' }} parallaxData={column2Bounce}>
                                                <div className="page-intro-slide2-timeline-arrowDot" />
                                                <div className="page-intro-slide2-timeline-arrowYear">1996</div>

                                            </Plx>
                                            <Plx className="page-intro-slide2-timeline-dotColumn" style={{ width: '12%' }} parallaxData={column3Bounce}>
                                                <div className="page-intro-slide2-timeline-arrowDot" />
                                                <div className="page-intro-slide2-timeline-arrowYear">2000</div>

                                            </Plx>
                                            <Plx className="page-intro-slide2-timeline-dotColumn" style={{ width: '23%' }} parallaxData={column4Bounce}>
                                                <div className="page-intro-slide2-timeline-arrowDot" />
                                                <div className="page-intro-slide2-timeline-arrowYear">2009</div>


                                            </Plx>
                                            <Plx className="page-intro-slide2-timeline-dotColumn" style={{ width: '17%' }} parallaxData={column5Bounce}>
                                                <div className="page-intro-slide2-timeline-arrowDot" />
                                                <div className="page-intro-slide2-timeline-arrowYear">Present day</div>

                                            </Plx>
                                        </div>
                                        <div className="page-intro-slide2-timeline-textRow">
                                            <Plx className="page-intro-slide2-timeline-textColumn" style={{ width: '12%' }} parallaxData={column1Bounce}>
                                                Seo Taeji &amp; Boys <span style={{ color: '#FFE31E', fontWeight: '500' }}> create “K-pop” overnight </span>by introducing American hip-hop to Korean music in a weekend MBC broadcast on April 11, 1992


                                            </Plx>
                                            <Plx className="page-intro-slide2-timeline-textColumn" style={{ width: '12%' }} parallaxData={column2Bounce}>
                                                H.O.T. debuted in 1996, signaling the<span style={{ color: '#FFE31E', fontWeight: '500' }}> birth of the K-pop idol</span>. They became a symbol of the success of the big entertainment agency formulas for training &amp; moulding future K-pop stars


                                            </Plx>
                                            <Plx className="page-intro-slide2-timeline-textColumn" style={{ width: '12%' }} parallaxData={column3Bounce}>
                                                BoA debuted in 2000 at age 13 and became<span style={{ color: '#FFE31E', fontWeight: '500' }}> K-pop’s first true international hit</span> (in Japan). She became proof that K-pop could succeed in more lucrative foreign markets


                                            </Plx>
                                            <Plx className="page-intro-slide2-timeline-textColumn" style={{ width: '23%' }} parallaxData={column4Bounce}>
                                                Wonder Girls became the first<span style={{ color: '#FFE31E', fontWeight: '500' }}> K-pop artists to chart in the Billboard Hot 100</span>, at #76 in 2009.
                                                <br /><br />
                                                This was soon eclipsed by Psy’s feverish hit, “Gangnam Style,” which came in at #2 on the Hot 100 in 2012 (it now has<span style={{ color: '#FFE31E', fontWeight: '500' }}> almost 4.5 billion views on YT</span>). Both artists showed that K-pop was increasingly a global musical force to be reckoned with, not just a niche category


                                            </Plx>
                                            <Plx className="page-intro-slide2-timeline-textColumn" style={{ width: '17%' }} parallaxData={column5Bounce}>
                                                BTS have<span style={{ color: '#FFE31E', fontWeight: '500' }}> broken almost every record in the K-pop playbook</span> and are setting new standards for what it means to be global Korean pop stars.
                                                <br /><br />

                                                Their nomination for a Grammy and visit to the White House in 2022 were symbols of just how much cultural clout the group has gained


                                            </Plx>


                                        </div>
                                    </div>



                                </IntroSlideItem>



                            </IntroSlide>



                            <IntroSlide id={'slide03'} startAfter={15} endAfter={90} align={'center'}>

                                <Plx className="page-intro-slide3-image" parallaxData={slide03GifAnimation}>
                                    <img src={slide03_gif} alt="" style={{ width: '100%', border: '1px solid black', borderRadius: '12px 0% 0% 12px' }} />
                                </Plx>
                                <IntroSlideItem id={'slide03'} startOffset={'0vh'} duration={'20vh'} >


                                    <div className="page-intro-slide3-container">
                                        <div className="page-intro-slide3-text">
                                            The Korean entertainment industry has always looked beyond its own borders for inspiration and to cultivate global audiences, and this has fit into a larger national government strategy of investing in Korean cultural products for export. Today, each of the four major entertainment companies (SM, JYP, YG, and HYBE) <Plx className="greenBubble" parallaxData={rotatingBubble01({ start: '#slide03' })}>individually generates more revenue</Plx> than any national music market in Asia (excl. Japan and China). HYBE alone generated <Plx className="greenBubble" parallaxData={rotatingBubble02({ start: '#slide03' })}>$1bn in revenue</Plx> in 2021.
                                        </div>

                                    </div>

                                </IntroSlideItem>





                            </IntroSlide>

                            <IntroSlide id={'slide04'} startAfter={15} endAfter={90} align={'center'}>

                                <IntroSlideItem id={'slide04'} startOffset={'0vh'} duration={'20vh'} >


                                    <div className="page-intro-slide4-container">
                                        <div className="page-intro-slide4-left">
                                            <img src={slide04_gifLeft} alt="" style={{ width: '100%', borderRadius: '0 12px 12px 0' }} />

                                        </div>

                                        <div className="page-intro-slide4-middle">
                                            Leveraging social media and tech has also long been a key industry strategy. For example, 90% of views for <Plx className="greenBubble" parallaxData={rotatingBubble01({ start: '#slide04' })}>K-pop music videos on YouTube</Plx> originate from outside Korea and the first YT video of any kind to ever reach 1 billion views was a <Plx className="greenBubble" parallaxData={rotatingBubble02({ start: '#slide04' })}>K-pop original</Plx>                   .
                                            <br /> <br />
                                            Recent partnerships between entertainment companies and local tech giants also highlight an ongoing focus by the industry on fan-first digital experiences.

                                        </div>

                                        <div className="page-intro-slide4-right">
                                            <img src={slide04_gifRight} alt="" style={{ width: '80%', borderRadius: '12px', border: '1px solid black', marginTop: '15%' }} />

                                        </div>
                                    </div>






                                </IntroSlideItem>





                            </IntroSlide>


                            <IntroSlide id={'slide05'} startAfter={15} endAfter={120} justify={'center'}>

                                <IntroSlideItem id={'slide05'} startOffset={'0vh'} duration={'30vh'}>
                                    <div className="slideTitle">Global fans &amp; tech innovation</div>

                                    <div className="page-intro-slide5-container" >
                                        <IntroSlideItem className={'page-intro-slide5-leftSide'} id={'slide05'} startOffset={'40vh'} duration={'30vh'}>

                                            <img src={slide05_01} alt="" style={{ maxWidth: '90%', maxHeight: '100%' }} />

                                        </IntroSlideItem>

                                        <IntroSlideItem className={'page-intro-slide5-rightSide'} id={'slide05'} startOffset={'70vh'} duration={'30vh'}>
                                            <div className="page-intro-slide5-quote">
                                                I think new ways of content creation and content consumption come out of Asia, more so than Western markets. TikTok is a great example of that. And Korea has an amazing level of fan development and fan engagement that no territory in the West has come close to replicating.
                                            </div>
                                            <div className="page-intro-slide5-source">
                                                (source:  IFPI 2022 Global Music report)
                                            </div>
                                            <div className="page-intro-slide5-quoteName">
                                                <strong>Shridhar Subramaniam</strong> <br />
                                                President of Corporate Strategy &amp; Market Development, <br />Sony Music Entertainment
                                            </div>
                                            <div className="quotationMarkOpen">“</div>
                                            <div className="quotationMarkClose">”</div>
                                        </IntroSlideItem>

                                    </div>



                                </IntroSlideItem>




                            </IntroSlide>

                            <div style={{ height: '20vh' }} />

                            <SlideShow id={'slideShow01'} slidesNo={3} showHideDuration={0.5} slideFocusDuration={0.5} switchDuration={0.5} shrinkTo={0.85}>


                                <Slide slideClassName='slide' bgImage={slideshow_bg1}>
                                    <div className="page-intro-slideShow-container">

                                        <div className="page-intro-slideShow-right">
                                            <div className="page-intro-slideShow-rightCaption">
                                                {intro[2] && intro[2].text1}
                                            </div>
                                            <SlideshowVideos video={1} />

                                        </div>
                                        <div className="page-intro-slideShow-left">
                                            <div className="page-intro-slideShow-leftText">
                                                {intro[2] && intro[2].text2}

                                            </div>
                                        </div>

                                    </div>

                                </Slide>

                                <Slide slideClassName='slide' bgImage={slideshow_bg2}>
                                    <div className="page-intro-slideShow-container">

                                        <div className="page-intro-slideShow-right">
                                            <div className="page-intro-slideShow-rightCaption">
                                                {intro[2] && intro[2].text3}

                                            </div>
                                            <SlideshowVideos video={2} />


                                        </div>
                                        <div className="page-intro-slideShow-left">
                                            <div className="page-intro-slideShow-leftText">
                                                {intro[2] && intro[2].text4}

                                            </div>
                                        </div>
                                    </div>                    </Slide>

                                <Slide slideClassName='slide' bgImage={slideshow_bg3}>
                                    <div className="page-intro-slideShow-container">

                                        <div className="page-intro-slideShow-right">
                                            <div className="page-intro-slideShow-rightCaption">
                                                {intro[2] && intro[2].text3}
                                            </div>
                                            <SlideshowVideos video={3} />

                                        </div>
                                        <div className="page-intro-slideShow-left">
                                            <div className="page-intro-slideShow-leftText">
                                                {intro[2] && intro[2].text5}
                                            </div>
                                        </div>
                                    </div>
                                </Slide>


                            </SlideShow>

                            <IntroSlide id={'slide06'} startAfter={15} endAfter={520} justify={'center'}>
                                <div className="spacer" style={{ height: '10vh' }} />
                                <IntroSlideItem id={'slide06'} startOffset={'0vh'} duration={'30vh'}>
                                    <div className="slideTitle">
                                        {intro[3] && intro[3].text1}
                                    </div>

                                    <div className="page-intro-slide6-container">
                                        <IntroSlideItem className={'page-intro-slide6-leftSide'} id={'slide06'} startOffset={'40vh'} duration={'30vh'}>
                                            <IntroSlideItem className={"page-intro-slide6-regText"} id={'slide06'} startOffset={'40vh'} duration={'30vh'} doesLeave={true} leaveAfter={'200vh'}>
                                                {intro[3] && intro[3].text2} <strong style={{ color: '#FFE31E' }}>K-pop cultural ecosystem</strong>{intro[3] && intro[3].text3}
                                            </IntroSlideItem>

                                            <IntroSlideItem className={"page-intro-slide6-regTextAbs"} id={'slide06'} startOffset={'230vh'} duration={'30vh'}>
                                                {intro[3] && intro[3].text4} <strong style={{ color: '#FFE31E' }}>intimate</strong> {intro[3].text5} <strong style={{ color: '#FFE31E' }}>multimedia story worlds</strong>.
                                            </IntroSlideItem>
                                            <IntroSlideItem className={"page-intro-slide6-regText"} id={'slide06'} startOffset={'420vh'} duration={'60vh'}>
                                                <br />{intro[3] && intro[3].text6} <strong style={{ color: '#FFE31E' }}>8 key themes </strong>{intro[3] && intro[3].text7} <strong style={{ color: '#FFE31E' }}>drivers</strong>, user <strong style={{ color: '#FFE31E' }}>needs</strong>, and potential <strong style={{ color: '#FFE31E' }}>product opportunities</strong>.
                                            </IntroSlideItem>

                                        </IntroSlideItem>



                                        <IntroSlideItem className={'page-intro-slide6-middleSide'} id={'slide06'} startOffset={'40vh'} duration={'30vh'}>

                                            <div className="page-intro-slide6-iPhoneContainer">
                                                <Plx parallaxData={iPhoneAnimation}>
                                                    <img src={slide06_iPhone} alt="" style={{ maxWidth: '100%', maxHeight: '60vh' }} />
                                                </Plx>


                                                <Plx className={"page-intro-slide6-shiftBubble"} parallaxData={shiftBubbleAni({ endX: 0, endY: -11 })} style={{ top: '10%', left: '15%' }}>
                                                    <img src={slide06_shift01} alt="" style={{ maxWidth: '100%', maxHeight: '100%', borderRadius: '50%' }} />
                                                </Plx>

                                                <Plx className={"page-intro-slide6-shiftBubble"} parallaxData={shiftBubbleAni({ endX: 40, endY: -41 })} style={{ top: '15%', right: '10%' }}>
                                                    <img src={slide06_shift02} alt="" style={{ maxWidth: '100%', maxHeight: '100%', borderRadius: '50%' }} />
                                                </Plx>

                                                <Plx className={"page-intro-slide6-shiftBubble"} parallaxData={shiftBubbleAni({ endX: 10, endY: 39 })} style={{ top: '25%', right: '40%' }}>
                                                    <img src={slide06_shift03} alt="" style={{ maxWidth: '100%', maxHeight: '100%', borderRadius: '50%' }} />
                                                </Plx>

                                                <Plx className={"page-intro-slide6-shiftBubble"} parallaxData={shiftBubbleAni({ endX: 120, endY: -21 })} style={{ top: '36%', right: '4%' }}>
                                                    <img src={slide06_shift04} alt="" style={{ maxWidth: '100%', maxHeight: '100%', borderRadius: '50%' }} />
                                                </Plx>

                                                <Plx className={"page-intro-slide6-shiftBubble"} parallaxData={shiftBubbleAni({ endX: 15, endY: 79 })} style={{ top: '48%', left: '6%' }}>
                                                    <img src={slide06_shift05} alt="" style={{ maxWidth: '100%', maxHeight: '100%', borderRadius: '50%' }} />
                                                </Plx>

                                                <Plx className={"page-intro-slide6-shiftBubble"} parallaxData={shiftBubbleAni({ endX: 65, endY: -20 })} style={{ top: '59%', right: '15%' }}>
                                                    <img src={slide06_shift06} alt="" style={{ maxWidth: '100%', maxHeight: '100%', borderRadius: '50%' }} />
                                                </Plx>

                                                <Plx className={"page-intro-slide6-shiftBubble"} parallaxData={shiftBubbleAni({ endX: 100, endY: 65 })} style={{ top: '69%', left: '15%' }}>
                                                    <img src={slide06_shift07} alt="" style={{ maxWidth: '100%', maxHeight: '100%', borderRadius: '50%' }} />
                                                </Plx>

                                                <Plx className={"page-intro-slide6-shiftBubble"} parallaxData={shiftBubbleAni({ endX: 160, endY: 9 })} style={{ top: '79%', right: '18%' }}>
                                                    <img src={slide06_shift08} alt="" style={{ maxWidth: '100%', maxHeight: '100%', borderRadius: '50%' }} />
                                                </Plx>

                                            </div>



                                        </IntroSlideItem>

                                        <IntroSlideItem className={'page-intro-slide6-rightSide'} id={'slide06'} startOffset={'130vh'} duration={'30vh'} doesLeave={true} leaveAfter={'200vh'}>
                                            <div style={{ display: 'flex', width: '80%', alignItems: 'flex-start', justifyContent: 'center' }}>
                                                <img src={slide06_01} alt="" style={{ maxWidth: '48%', maxHeight: '100%' }} />
                                                <img src={slide06_02} alt="" style={{ maxWidth: '48%', maxHeight: '100%', marginLeft: 'auto' }} />


                                            </div>
                                            <div className="page-intro-slide6-regText" style={{ opacity: '1' }} >
                                                {intro[3].text8} <strong style={{ color: '#FFE31E' }}>{intro[3].text9} </strong>{intro[3].text10} <strong style={{ color: '#FFE31E' }}>{intro[3].text11}</strong> {intro[3].text12}
                                            </div>
                                            <div style={{ display: 'flex', width: '80%', justifyContent: 'space-evenly' }}>
                                                <img src={slide06_03} alt="" style={{ maxWidth: '19%', maxHeight: '100%' }} />
                                                <img src={slide06_04} alt="" style={{ maxWidth: '19%', maxHeight: '100%' }} />
                                                <img src={slide06_05} alt="" style={{ maxWidth: '19%', maxHeight: '100%' }} />
                                                <img src={slide06_06} alt="" style={{ maxWidth: '19%', maxHeight: '100%' }} />
                                                <img src={slide06_07} alt="" style={{ maxWidth: '19%', maxHeight: '100%' }} />



                                            </div>
                                        </IntroSlideItem>

                                    </div>



                                </IntroSlideItem>




                            </IntroSlide>

                            <div id="now" style={{ marginBottom: '0vh' }} />
                            <IntroSlide id={'slide07'} startAfter={50} endAfter={100} justify={'center'} >

                                <IntroSlideItem id={'slide07'} startOffset={'0vh'} duration={'30vh'}>
                                    <div className="slideTitle">{intro[4].text1}</div>

                                    <div className="page-intro-slide7-columnContainer">
                                        <Plx className="page-intro-slide7-column" parallaxData={moveInColumn({ startValue: -200 })}>
                                            <div className="page-intro-slide7-column-row">
                                                <div className="page-intro-slide7-column-row-column1">
                                                    <img src={slide07_01} className="page-intro-slide7-icon" alt="" />
                                                </div>
                                                <div className="page-intro-slide7-column-row-column2">
                                                    {intro[4].text2} <a href='./network'>{intro[4].text3}</a> page.
                                                </div>

                                            </div>
                                            <div className="page-intro-slide7-column-row">
                                                <div className="page-intro-slide7-column-row-column1">
                                                    <img src={slide07_02} className="page-intro-slide7-icon" alt="" />

                                                </div>
                                                <div className="page-intro-slide7-column-row-column2">
                                                    {intro[4].text4} <a href='./productOpp'>{intro[4].text5}</a> {intro[4].text6}
                                                </div>

                                            </div>
                                        </Plx>

                                        <Plx className="page-intro-slide7-column" parallaxData={moveInColumn({ startValue: 200 })} onPlxStart={() => unstickNavbar()}>
                                            <div className="page-intro-slide7-column-row">
                                                <div className="page-intro-slide7-column-row-column1">
                                                    <img src={slide07_03} className="page-intro-slide7-icon" alt="" />

                                                </div>
                                                <div className="page-intro-slide7-column-row-column2">
                                                {intro[4].text7} <a href='./videoReels'>sizzle reels</a>{intro[4].text8}
                                                </div>

                                            </div>
                                            <div className="page-intro-slide7-column-row">
                                                <div className="page-intro-slide7-column-row-column1">
                                                    <img src={slide07_04} className="page-intro-slide7-icon" alt="" />

                                                </div>
                                                <div className="page-intro-slide7-column-row-column2">
                                                {intro[4].text9} <a href='./marketDD'>here</a>.
                                                </div>

                                            </div>
                                        </Plx>
                                    </div>

                                </IntroSlideItem>




                            </IntroSlide>


                            <IntroSlide id={'slide08'} startAfter={50} endAfter={300} justify={'center'} >
                                <Plx parallaxData={slide08Move({ zValue: -50 })} className="page-intro-slide8-textSignHappy" onPlxStart={() => stickNavbar()
                                }>
                                    <img src={slide08_happy} style={{ width: '100%' }} alt="" />
                                </Plx>
                                <Plx parallaxData={slide08Move({ zValue: 50 })} className="page-intro-slide8-textSignExploring">
                                    <img src={slide08_exploring} style={{ width: '100%' }} alt="" />
                                </Plx>

                                <Plx parallaxData={slide08Move({ zValue: -50 })} className="page-intro-slide8-floatingIcons" style={{ top: '10%', left: '35%', rotate: '4deg' }}>
                                    <img src={slide08_01} style={{ width: '90%' }} alt="" />
                                </Plx>
                                <Plx parallaxData={slide08Move({ zValue: -50 })} className="page-intro-slide8-floatingIcons" style={{ top: '40%', left: '7%', rotate: '4deg' }}>
                                    <img src={slide08_02} style={{ width: '100%' }} alt="" />
                                </Plx>
                                <Plx parallaxData={slide08Move({ zValue: -50 })} className="page-intro-slide8-floatingIcons" style={{ top: '65%', left: '15%', rotate: '4deg' }}>
                                    <img src={slide08_03} style={{ width: '150%' }} alt="" />
                                </Plx>
                                <Plx parallaxData={slide08Move({ zValue: -50 })} className="page-intro-slide8-floatingIcons" style={{ top: '80%', left: '40%', rotate: '4deg' }}>
                                    <img src={slide08_04} style={{ width: '130%' }} alt="" />
                                </Plx>
                                <Plx parallaxData={slide08Move({ zValue: 50 })} className="page-intro-slide8-floatingIcons" style={{ top: '15%', right: '30%', rotate: '4deg' }}>
                                    <img src={slide08_05} style={{ width: '100%' }} alt="" />
                                </Plx>
                                <Plx parallaxData={slide08Move({ zValue: 50 })} className="page-intro-slide8-floatingIcons" style={{ top: '40%', right: '10%', rotate: '4deg' }}>
                                    <img src={slide08_06} style={{ width: '150%' }} alt="" />
                                </Plx>
                                <Plx parallaxData={slide08Move({ zValue: 50 })} className="page-intro-slide8-floatingIcons" style={{ top: '45%', right: '40%', rotate: '4deg' }}>
                                    <img src={slide08_07} style={{ width: '150%' }} alt="" />
                                </Plx>
                                <Plx parallaxData={slide08Move({ zValue: 50 })} className="page-intro-slide8-floatingIcons" style={{ width: '2vw', top: '85%', right: '35%', rotate: '4deg' }}>
                                    <img src={slide08_08} style={{ width: '100%' }} alt="" />
                                </Plx>
                                <Plx parallaxData={slide08Move({ zValue: 50 })} className="slide8-greenBubble" style={{ top: `${!mobileDevice ? '90px' : '70px'}`, left: '64px', rotate: '4deg' }}>
                                    <img src={cursor} alt="" style={{ width: '8.4%', height: '12.48%', marginRight: '5%' }} />
                                    To begin, click on the menu icon in the upper lefthand corner.
                                </Plx>




                            </IntroSlide>


                            {/* <div style={{ height: '400vh' }} /> */}






                        </div>




                    </>
                </>
            }
        </>

    );
}

export default Introduction;

