import Plx from "react-plx"


const IntroSlide = ({ id, startAfter, endAfter, align, justify, children }) => {

    const alignment = align === undefined ? 'center' : align
    const justification = justify === undefined ? 'space-evenly' : justify
    const lastStepDuration = 15

    const start = '#' + id
    const startValue = `${startAfter}vh`

    const endValue = `${endAfter + lastStepDuration}vh`

    const introSlideStyle = {
        position: 'fixed',
        opacity: 0,
        width: '100vw',
        height: '100vh',
        bottom: 0,
        left: 0,
        display: 'flex',
        flexDirection: 'column',
        alignItems: alignment,
        justifyContent: justification,
        color: 'white',
        // backgroundColor: 'green'
        // zIndex: 99999999

    }

    const introSlideAnimation = [
        {
            start: start,
            duration: '1vh',
            easing: 'linear',
            properties: [

                {
                    startValue: 0,
                    endValue: 1,
                    property: "opacity",
                },
                {
                    startValue: 0,
                    endValue: 100,
                    property: "zIndex",
                },

            ]

        },
        {
            start: start,
            startOffset: endValue,
            duration: '15vh',
            easing: 'linear',
            properties: [

                {
                    startValue: 1,
                    endValue: 0,
                    property: "opacity",
                },
                {
                    startValue: 100,
                    endValue: 0,
                    property: "zIndex",
                },

            ]

        },
    ];


    return (
        <>
            <div id={id} style={{ marginTop: startValue, marginBottom: endValue }} />
            <Plx style={introSlideStyle} parallaxData={introSlideAnimation}>
                {children}
            </Plx>
        </>
    );
}

export default IntroSlide;