import { useState } from "react";
import { useEffect } from "react";

//Aux function to shuffle array

function shuffle(array) {
    let currentIndex = array.length, randomIndex;

    // While there remain elements to shuffle.
    while (currentIndex != 0) {

        // Pick a remaining element.
        randomIndex = Math.floor(Math.random() * currentIndex);
        currentIndex--;

        // And swap it with the current element.
        [array[currentIndex], array[randomIndex]] = [
            array[randomIndex], array[currentIndex]];
    }

    return array;
}


//Creating a custom hook in React. Function name MUST start with 'use' 
const useFetch = (url, setMasterArray, setDoneFetching, setShowViewToggle, appHasFetched, appLevelMasterArray) => {

    // const [data, setData] = useState(null);
    // const [isLoading, setIsLoading] = useState(true);
    // const [error, setError] = useState(null);

    //fires on every render... use for code that needs to run on every single render. e.g. fetching data from somewhere. add dependency into an [array] before closing ), to filter out only events where a specific value has changed (example: name vs cards). leave array empty to let function only run once at the first render
    useEffect(() => {

        if (!appHasFetched) {
            console.log('generating NEW data')


            const abortCont = new AbortController();

            fetch(url, { signal: abortCont.signal })
                .then(res => {
                    if (!res.ok) {
                        throw Error('could not fetch the data for that resource')
                    }

                    return res.json();
                })
                .then((data) => {
                    // updateData(data.results);
                    // setData(data.results);
                    // setIsLoading(false);
                    // setError(null);
                    setMasterArray(data.results)
                    console.log('loop 1 done')

                    //if more than 100 items (1st loop)
                    if (data.has_more) {

                        const nextUrl = url + '&start=' + data.next_cursor

                        fetch(nextUrl, { signal: abortCont.signal })
                            .then(res => {
                                if (!res.ok) {
                                    throw Error('could not fetch the data for that resource')
                                }

                                return res.json();
                            })
                            .then((nextData01) => {
                                setMasterArray(prevState => ([...prevState, ...nextData01.results]))
                                console.log('loop 2 done')

                                // setIsLoading(false);
                                // setError(null);

                                // if more than 200 items (2nd loop)
                                if (nextData01.has_more) {

                                    const nextUrl = url + '&start=' + nextData01.next_cursor

                                    fetch(nextUrl, { signal: abortCont.signal })
                                        .then(res => {
                                            if (!res.ok) {
                                                throw Error('could not fetch the data for that resource')
                                            }

                                            return res.json();
                                        })
                                        .then((nextData02) => {
                                            setMasterArray(prevState => ([...prevState, ...nextData02.results]))
                                            console.log('loop 3 done')

                                            // setIsLoading(false);
                                            // setError(null);

                                            // if more than 300 items (3rd loop)
                                            if (nextData02.has_more) {

                                                const nextUrl = url + '&start=' + nextData02.next_cursor

                                                fetch(nextUrl, { signal: abortCont.signal })
                                                    .then(res => {
                                                        if (!res.ok) {
                                                            throw Error('could not fetch the data for that resource')
                                                        }

                                                        return res.json();
                                                    })
                                                    .then((nextData03) => {
                                                        setMasterArray(prevState => ([...prevState, ...nextData03.results]))
                                                        console.log('loop 4 done')

                                                        // setIsLoading(false);
                                                        // setError(null);

                                                        setDoneFetching && setDoneFetching(true)
                                                        setShowViewToggle && setShowViewToggle(true)



                                                    })

                                            }

                                            else {

                                                setDoneFetching && setDoneFetching(true)
                                                setShowViewToggle && setShowViewToggle(true)


                                            }

                                        })

                                }

                                else {

                                    setDoneFetching && setDoneFetching(true)
                                    setShowViewToggle && setShowViewToggle(true)


                                }



                            })

                    }

                    else {

                        setDoneFetching && setDoneFetching(true)
                        setShowViewToggle && setShowViewToggle(true)


                    }

                })
                .catch(err => {
                    if (err.name === 'AbortError') {
                    } else {
                        // setIsLoading(false);
                        // setError(err.message)
                    }
                })

            return () => abortCont.abort();




            //end of use effect
        }

        else {
            console.log('using EXISTING data')
            setDoneFetching && setDoneFetching(true)
            setShowViewToggle && setShowViewToggle(true)
            setMasterArray(appLevelMasterArray)
        }
    }, [url]);

    return

}

export default useFetch;