import { useState } from "react";
import { useEffect } from "react";

//Creating a custom hook in React. Function name MUST start with 'use' 
const useFetchPost = (url, token, body) => {
    const [data, setData] = useState(null);
    const [isLoading, setIsLoading] = useState(true);
    const [error, setError] = useState(null);

    //fires on every render... use for code that needs to run on every single render. e.g. fetching data from somewhere. add dependency into an [array] before closing ), to filter out only events where a specific value has changed (example: name vs cards). leave array empty to let function only run once at the first render

    useEffect(() => {



        const abortCont = new AbortController();

        fetch(url, {
            signal: abortCont.signal,
            method: 'POST',
            headers: {
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'application/json'
            },
            body: body
        })
            .then(res => {
                if (!res.ok) {
                    throw Error('could not fetch the data for that resource')
                }

                return res.json();
            })
            .then((data) => {
                setData(data);
                setIsLoading(false);
                setError(null);
            })
            .catch(err => {
                if (err.name === 'AbortError') {
                } else {
                    setIsLoading(false);
                    setError(err.message)
                }
            })

        return () => abortCont.abort();




        //end of use effect
    }, [url, token]);

    return { data, isLoading, error }

}

export default useFetchPost;