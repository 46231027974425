import React, { useState } from "react";
import { useEffect } from "react";
import Plx from "react-plx";



const Credits = ({ credits, setGraphDone, setSidebarRightOpen, setNavbarBG, navbarScrollBehavior, mobileDevice, networkPage }) => {


    //Global things (not related to this page per se)
    useEffect(() => {

        setGraphDone(false)

        setNavbarBG(!mobileDevice ? false : true)
        navbarScrollBehavior.current = !mobileDevice ? false : true



        document.getElementById('bgVideo01').style.display = 'unset'
        document.getElementById('bgVideo02').style.display = 'unset'

        networkPage.current = false

    }, []);




    return (
        <>
            {credits &&
                <div className="page-credits" id="page-credits">

                    <div className="pageContent">
                        <div className="pageContent">

                            <p>
                                <strong>{credits && credits[0].title}</strong> <br />
                                {credits && credits[0].name1}<br />
                                {credits && credits[0].name2}<br />
                                {credits && credits[0].name3}
                            </p>
                            <p>
                                <strong>{credits && credits[1].title}</strong> <br />
                                {credits && credits[1].name1}<br />
                                {credits && credits[1].name2}<br />
                                {credits && credits[1].name3}
                            </p>
                            <p>
                                <strong>{credits && credits[2].title}</strong> <br />
                                {credits && credits[2].name1}<br />
                                {credits && credits[2].name2}<br />
                                {credits && credits[2].name3}
                            </p>

                            <p>
                                {credits && credits[3].title}
                            </p>



                        </div>
                    </div>

                </div>
            }
        </>
    );
}

export default Credits;


