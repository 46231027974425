import React from 'react';

//Function to create random string
function makeID(length) {
    var result = '';
    var characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    var charactersLength = characters.length;
    for (var i = 0; i < length; i++) {
        result += characters.charAt(Math.floor(Math.random() *
            charactersLength));
    }
    return result;
}

const StitchedText = (data) => {

    const key = makeID(16)

    const textContent = data.data.text.content;
    const annotationsPath = data.data.annotations;
    const linkPath = data.data.text.link
    const type = data.type

    if (annotationsPath.bold === false && annotationsPath.italic === false && annotationsPath.underline === false) {
        return (
            <>
                {!linkPath && <span key={key}>{textContent}</span>}
                {linkPath && <a href={linkPath.url} target="_blank" key={key}>{textContent}</a>}
            </>
        );
    }

    else if (annotationsPath.bold === true && annotationsPath.italic === false && annotationsPath.underline === false) {
        return (
            <>
                {!linkPath && <span key={key} style={{ fontWeight: "bold", color: `${type === 'header' ? 'white' : '#FFE31E'}` }}>{textContent}</span>}
                {linkPath && <a href={linkPath.url} target="_blank" key={key} style={{ fontWeight: "bold" }}>{textContent}</a>}
            </>
        );
    }

    else if (annotationsPath.bold === true && annotationsPath.italic === true && annotationsPath.underline === false) {
        return (
            <>
                {!linkPath && <span key={key} style={{ fontWeight: "bold", color: `${type === 'header' ? 'white' : '#FFE31E'}`, fontStyle: "italic" }}>{textContent}</span>}
                {linkPath && <a href={linkPath.url} target="_blank" key={key} style={{ fontWeight: "bold", fontStyle: "italic" }}>{textContent}</a>}
            </>
        );
    }

    else if (annotationsPath.bold === true && annotationsPath.italic === false && annotationsPath.underline === true) {
        return (
            <>
                {!linkPath && <span key={key} style={{ fontWeight: "bold", color: `${type === 'header' ? 'white' : '#FFE31E'}`, textDecorationLine: "underline" }}>{textContent}</span>}
                {linkPath && <a href={linkPath.url} target="_blank" key={key} style={{ fontWeight: "bold", textDecorationLine: "underline" }}>{textContent}</a>}
            </>
        );
    }

    else if (annotationsPath.bold === true && annotationsPath.italic === true && annotationsPath.underline === true) {
        return (
            <>
                {!linkPath && <span key={key} style={{ fontWeight: "bold", color: `${type === 'header' ? 'white' : '#FFE31E'}`, fontStyle: "italic", textDecorationLine: "underline" }}>{textContent}</span>}
                {linkPath && <a href={linkPath.url} target="_blank" key={key} style={{ fontWeight: "bold", fontStyle: "italic", textDecorationLine: "underline" }}>{textContent}</a>}
            </>
        );
    }

    else if (annotationsPath.bold === false && annotationsPath.italic === true && annotationsPath.underline === false) {
        return (
            <>
                {!linkPath && <span key={key} style={{ fontStyle: "italic" }}>{textContent}</span>}
                {linkPath && <a href={linkPath.url} target="_blank" key={key} style={{ fontStyle: "italic" }}>{textContent}</a>}
            </>
        );
    }

    else if (annotationsPath.bold === false && annotationsPath.italic === true && annotationsPath.underline === true) {
        return (
            <>
                {!linkPath && <span key={key} style={{ fontStyle: "italic", textDecorationLine: "underline" }}>{textContent}</span>}
                {linkPath && <a href={linkPath.url} target="_blank" key={key} style={{ fontStyle: "italic", textDecorationLine: "underline" }}>{textContent}</a>}
            </>
        );
    }

    else if (annotationsPath.bold === false && annotationsPath.italic === false && annotationsPath.underline === true) {
        return (
            <>
                {!linkPath && <span key={key} style={{ textDecorationLine: "underline" }}>{textContent}</span>}
                {linkPath && <a href={linkPath.url} target="_blank" key={key} style={{ textDecorationLine: "underline" }}>{textContent}</a>}
            </>
        );
    }

}

export default StitchedText;