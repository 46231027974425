import Plx from 'react-plx'
import { useState } from 'react'

const GrowingCircle = () => {

    var starBG = require('./stars.jpg')

    //Variables for dynamic sizes or positions -----------------------------------------------------------------
    const width = window.visualViewport.width
    const height = window.visualViewport.height

    const [largerSide, setLargerSide] = useState(width > height ? width * 3 : height * 3)


    window.addEventListener("resize", () => {
        if (width > height) {
            setLargerSide(width * 2)
        }

        else {
            setLargerSide(height * 2)
        }

    });


    //Animation settings -----------------------------------------------------------------

    const blackHoleBG = [
        {
            start: '#now',
            startOffset: '20vh',
            duration: '1vh',
            easing: 'linear',
            properties: [
                {
                    startValue: 0,
                    endValue: 1,
                    property: "opacity",
                },
             
            ]

        },
      
    ];

    const blackHole = [
        {
            start: '#now',
            startOffset: '0vh',
            duration: '20vh',
            easing: 'linear',
            properties: [
                {
                    startValue: 0,
                    endValue: 1,
                    property: "opacity",
                },
             
            ]

        },
        {
            start: '#now',
            startOffset: '20vh',
            duration: '100vh',
            easing: 'easeIn',
            properties: [
                {
                    startValue: 0,
                    endValue: largerSide,
                    property: "width",
                    unit: "px"
                },
                {
                    startValue: 0,
                    endValue: largerSide,
                    property: "height",
                    unit: "px"
                },

            ]

        },
    ];


    return (

        <Plx className="hiddenBG" style={{ backgroundImage: `url(${starBG})` }} parallaxData={blackHoleBG}>
            <Plx className="blackHole" id="blackHole" parallaxData={blackHole}></Plx>

        </Plx>
    );
}

export default GrowingCircle;








