const Slide = ({ slideClassName, bgColor, bgImage, children }) => {
    return (

        <div className={slideClassName} style={{ backgroundColor: `${bgColor}`, backgroundImage: `url(${bgImage})`, backgroundSize: 'cover', backgroundPosition: 'center' }}>
            {children}
        </div>

    );
}

export default Slide;