// import { Link } from 'react-router-dom';
import { NavLink } from 'react-router-dom';
import React from 'react';
import { useState, useImperativeHandle, forwardRef } from 'react';
import spotifyLogo from './media/spotify.svg';


var initialMode = '';

const activeLinkColorLight = '#181828'
const activeLinkColorDark = '#DDDDDD'

var gemicLogo = require('./media/gemiclogo_white.png')
var gemicLogoWhite = require('./media/gemiclogo_white.png')
var info = require('./media/info.png')
var infoWhite = require('./media/info_white.png')
var logoutIcon = require('./media/logout.png')
var logoutIcon02 = require('./media/logout02.png')
var burgerIconWhite = require('./media/burger_white.png')
var burgerIconBlack = require('./media/burger.png')




var infoOpen = false









// --------------------------------------------------------------------------------------------------------------------------
//initial check if light or dark mode is active 
if (window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)').matches) {
    initialMode = 'dark'
}

else {
    initialMode = 'light'
}

// --------------------------------------------------------------------------------------------------------------------------
// Create a media condition that targets viewports at least 769px wide (in case we want to change heights of this bar)
const mediaQuery = window.matchMedia('(min-width: 769px)')
const checkDarkMode = window.matchMedia('(prefers-color-scheme: dark)')



// --------------------------------------------------------------------------------------------------------------------------



const Navbar = forwardRef((props, ref) => {

    const access = props.access
    const user = props.user
    const logout = props.logout
    const toggleSidebarLeft = props.toggleSidebarLeft
    const showViewToggle = props.showViewToggle
    const setShowViewToggle = props.setShowViewToggle
    const toggleViewMode = props.toggleViewMode
    const productView = props.productView
    const toggleTutorial = props.toggleTutorial
    const navbarBG = props.navbarBG
    const below600px = props.below600px
    const mobileDevice = props.mobileDevice
    const networkPage = props.networkPage



    const [logoutIconURL, setLogoutIconURL] = useState(logoutIcon)

    const [toggledOnce, setToggledOnce] = useState(false)
    // var toggledOnce = false

    function tutorialMaybe() {

        if (!toggledOnce) {
            toggleTutorial()

            setTimeout(() => {
                setToggledOnce(true)
            }, 200)

        }

    }

    useImperativeHandle(ref, () => ({

        changeLogoutIcon: (mode) => {

            if (mode === 'insights') {
                setLogoutIconURL(logoutIcon)
            }

            else if (mode === 'implications') {
                setLogoutIconURL(logoutIcon02)

            }

        }

    })
    )




    //Dynamic variables for podcast link classes

    var [infoLinkClass, setInfoLinkClass] = useState('rightsidelink-inactive')

    const infoTriangle = document.getElementById("triangle-info");
    const infoPopup = document.getElementById("popup-info");


    function openInfo() {

        if (infoOpen === false) {

            infoPopup.style.display = 'unset'
            infoTriangle.style.display = 'unset'
            setInfoLinkClass('rightsidelink-active')
            infoOpen = true



        }


        else {
            infoPopup.style.display = 'none'
            infoTriangle.style.display = 'none'
            setInfoLinkClass('rightsidelink-inactive')
            infoOpen = false
        }

    }





    //lets logo urls react to state – initial urls are generated based on initial mode
    const [gemicImageURL, setGemicImageURL] = useState(initialMode === 'light' ? gemicLogo : gemicLogoWhite)
    const [activeLinkColor, setActiveLinkColor] = useState(initialMode === 'light' ? activeLinkColorLight : activeLinkColorDark)
    const [infoLogoURL, setInfoLogoURL] = useState(initialMode === 'light' ? info : infoWhite)





    //sets logos to white whenever needed
    const setLogosWhite = () => {
        setGemicImageURL(gemicLogoWhite)
        setInfoLogoURL(infoWhite)
        setActiveLinkColor(activeLinkColorDark)

    }

    //sets logos to black whenever needed
    const setLogosBlack = () => {

        setGemicImageURL(gemicLogo)
        setInfoLogoURL(info)
        setActiveLinkColor(activeLinkColorLight)

    }

    //listens to light/dark mode changes while session is active, and sets logos accordingly
    window.matchMedia('(prefers-color-scheme: dark)').addEventListener('change', e => {
        if (e.matches == true) {
            setLogosWhite()

        }
        else {
            setLogosBlack()
        }
    });

    return (


        //replacing a href with Link to means that React will intercept server requests, and the React Router will handle page switches on its own

        <nav className="navbar" id="navbar">

            {/* 

            <div className="popupwrapper">
                <div className="popup-info" id="popup-info">
                    <p>For any questions or feedback on this website, or if
                        you'd like to learn more and spark a conversation with us, here's how you can get in touch:</p>
                    <p><a href="mailto:David.Ginsborg@gemic.com">David Ginsborg (Gemic)</a></p>
                    <p><a href="mailto:Anna.Poeyry@adidas.com">Anna Poeyry (Innovation Team)</a></p>

                </div>

            </div> */}


            <div className="navbar-row1" id="navbar-row1" style={{backgroundImage: `${!navbarBG ? '' : 'linear-gradient(to right, rgba(145, 79, 174, 0.9), rgb(124, 88, 212, 0.9))'}`, paddingTop: !mobileDevice ? '10px' : '0px'}}>
                <div className="navbar-row1-leftside">
                    {/* <NavLink exact to="/" activeStyle={{ color: activeLinkColor }}>100y WALK</NavLink>
                <NavLink to="/context" activeStyle={{ color: activeLinkColor }} onClick={() => { shrinkNavbar(); enableScroll() }}>CONTEXT</NavLink> */}

                    {user && access &&
                        <img src={!productView ? burgerIconWhite : burgerIconBlack} onClick={() => toggleSidebarLeft()} className="burger-icon" alt="menu" id="burger-icon" />
                    }





                </div>


                <div className="navbar-row1-rightside">

                    {user && access &&
                        <>
                            {!below600px &&
                                <div className="navbar-logoutText" id="navbar-logoutText" style={!productView ? { color: '#bcaed8' } : { color: '#7e7480' }}>
                                    {user.email}
                                </div>
                            }
                            <img src={!productView ? logoutIcon : logoutIcon02} className="logout-icon" alt="logout" onClick={logout} />
                        </>

                    }

                    {user && access && showViewToggle && networkPage.current &&
                        <div className="navbar-viewToggleUnit">
                            <div className="navbar-expText" id="navbar-expText" style={!productView ? { color: '#FFFFFF' } : { color: '#7e7480' }}>
                                INSIGHTS


                            </div>
                            <label className="switch">
                                {!productView &&
                                    <input type="checkbox" onChange={() => { toggleViewMode(); tutorialMaybe() }} />
                                }
                                {productView &&
                                    <input type="checkbox" onChange={() => { toggleViewMode(); tutorialMaybe() }} checked />
                                }
                                <span className="slider round"></span>
                            </label>
                            <div className="navbar-prodText" id="navbar-prodText" style={!productView ? { color: '#bcaed8' } : { color: '#000000' }}>
                                IMPLICATIONS
                            </div>
                        </div>
                    }
                </div>

            </div>

            {/* <div className="navbar-row2" id="navbar-row2">
                <div className="navbar-row2-catlinks" id="navbar-row2-catlinks">
                    <a href={'#' + introLink} onClick={() => { activeLinkState(); setEraActive(introLink, 'click') }} className={introClass}>Intro</a>
                    <a href={'#' + cultureLink} onClick={() => { activeLinkState(); setEraActive(cultureLink, 'click') }} className={cultureClass}>Culture</a>
                    <a href={'#' + imaginaryLink} onClick={() => { activeLinkState(); setEraActive(imaginaryLink, 'click') }} className={imaginaryClass}>Imaginary</a>
                    <a href={'#' + innovationLink} onClick={() => { activeLinkState(); setEraActive(innovationLink, 'click') }} className={innovationClass}>Innovation</a>
                    <a href={'#' + weareLink} onClick={() => { activeLinkState(); setEraActive(weareLink, 'click') }} className={weareClass}>We Are</a>
                    <a href={'#' + deepdiveLink} onClick={() => { activeLinkState(); setEraActive(deepdiveLink, 'click') }} className={deepdiveClass}>Deep Dive</a>
                    <a href='#intro-Epilogue' onClick={() => { activeLinkState(); setEraActive('epilogue-2008-2022', 'click') }} className={epilogueClass}>Epilogue</a>


                </div>

            </div> */}
        </nav >
    );
})

export default Navbar;