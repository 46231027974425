import React from 'react';
import { useEffect } from 'react';
import spotifyLogo from './media/spotify.svg';

var gemicLogo = require('./media/gemiclogo_white.png')

const NoLogin = ({ access, setAccess, error, setError, user, authReady, login, logout, loginFailed, setGraphDone, setSidebarRightOpen, setNavbarBG }) => {

    // const { user, login, logout, authReady } = useContext(AuthContext)


    useEffect(() => {

        setGraphDone(false)

        setSidebarRightOpen(false)

        setNavbarBG('#795fb0')



    }, []);



    return (


        <div className="page-nologin">

            <div className="login-dialogue">

                {!authReady && <p>Loading...</p>}

                <div className="nologin-logos">
                    <img src={gemicLogo} className="gemic-logo" id="gemic-logo" alt="gemic logo" />
                    <p>+</p>
                    <img src={spotifyLogo} className="spotify-logo" id="spotify-logo" alt="spotify logo" />
                </div>
                <div className="intro-title-nologin">
                    The Global Influence of <br /> Korean Pop Culture
                </div>
                {authReady &&
                    <>
                        <button onClick={login}>Login</button>

                        <p></p>
                        {!loginFailed &&
                            <>
                                <p>Access for Spotify teams only – choose 'Continue with Google' for quickest log-in.</p>
                            </>
                        }
                        {loginFailed && <span>Access denied</span>}

                    </>

                }


            </div>
        </div>


    );



}

export default NoLogin;