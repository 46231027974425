import React, { useState } from "react";
import { useEffect } from "react";
import Plx from "react-plx";
import StickyText from "./Animations/StickyText";
import SlideShow from "./Animations/SlideShow";
import Slide from "./Animations/Slide";
import StickyTextMoving from "./Animations/StickyTextMoving";
import GrowingCircle from "./Animations/GrowingCircle";

const Animations = ({ setLoadingState, setGraphDone, setSidebarRightOpen, setNavbarBG }) => {


    //Global things (not related to this page per se)
    useEffect(() => {

        setLoadingState(false)
        setGraphDone(false)

        setSidebarRightOpen(false)
        setNavbarBG('transparent')

    }, []);



    //--------------------------------------------------------------------------------------------------------------
    //--------------------------------------------------------------------------------------------------------------
    //--------------------------------------------------------------------------------------------------------------

    //Animation settings -----------------------------------------------------------------



    return (
        <>

            <GrowingCircle />

            <div className="page-animations" id="page-animations" >


                <StickyText id={"sticky00"} startAfter={0} endAfter={80}>
                    Hi there! Scroll down please...
                </StickyText>

                <StickyText id={"sticky01"} startAfter={80} endAfter={80}>
                    Let's try some fun effects, shall we?
                </StickyText>

                <div style={{ height: '50vh' }} />

                <SlideShow id={'slideShow01'} slidesNo={2} showHideDuration={0.5} slideFocusDuration={1} switchDuration={0.5} shrinkTo={0.9}>
                   
                   
                    <Slide slideClassName='slide' bgColor='lightcoral'>
                        Listen up!

                    </Slide>

                    <Slide slideClassName='slide' bgColor='lightseagreen'>
                        This is important!
                    </Slide>

                   



                </SlideShow>

                <SlideShow id={'slideShow02'} slidesNo={2} showHideDuration={0.5} slideFocusDuration={1} switchDuration={0.5} shrinkTo={0.85}>
                   
                   
                   <Slide slideClassName='slide' bgColor='yellow'>
                       Another show!

                   </Slide>

                   <Slide slideClassName='slide' bgColor='red'>
                       This is important!
                   </Slide>



               </SlideShow>


                <StickyText id={"sticky02"} startAfter={80} endAfter={80}>
                    Have you been inside a black hole before?
                </StickyText>


                <p style={{ color: 'white', fontSize: '3rem', marginBottom: '400vh', marginTop: '50vh' }} id="now">Why would I ask...?</p>


                <StickyTextMoving id={'movingText01'} direction={'leftRight'} topPosition={35}>
                    Oops
                </StickyTextMoving>

                <StickyTextMoving id={'movingText02'} direction={'rightLeft'} topPosition={55}>
                    What happened just now?
                </StickyTextMoving>


                <div style={{ height: '150vh' }} />

                <RollIn />

                <p style={{ color: 'white', fontSize: '3rem', marginBottom: '50vh', marginTop: '50vh' }}>Yummy!</p>




            </div>
        </>
    );
}

export default Animations;


//Separate animation components  ----------------------------------------------------------------- ----------------------------------------------------------------------------------------------------------------------------------

const RollIn = () => {

    var kimchi = require('./media/kimchi.png')
    var rice = require('./media/rice.png')
    var soju = require('./media/soju.png')
    var bbq = require('./media/bbq.png')

    //Variables for dynamic sizes or positions -----------------------------------------------------------------
    const [viewPortWidthCenter, setViewPortWidthCenter] = useState(window.visualViewport.width / 2 - 50)

    window.addEventListener("resize", () => {

        setViewPortWidthCenter(window.visualViewport.width / 2 - 50)

    });


    //Animation settings -----------------------------------------------------------------

    const rollInFromLeft = [
        {
            start: '#rollInContainer',
            startOffset: '20vh',
            duration: '50vh',
            easing: 'easeIn',
            properties: [
                {
                    startValue: 0,
                    endValue: viewPortWidthCenter,
                    property: "marginLeft",
                    unit: "px"
                },
                {
                    startValue: 0,
                    endValue: 720,
                    property: "rotate"
                },
                {
                    startValue: 0,
                    endValue: 1,
                    property: "opacity"
                }
            ]

        },
    ];

    const rollInFromRight = [
        {
            start: '#rollInContainer',
            startOffset: '20vh',
            duration: '50vh',
            easing: 'easeIn',
            properties: [
                {
                    startValue: 0,
                    endValue: viewPortWidthCenter,
                    property: "marginRight",
                    unit: "px"
                },
                {
                    startValue: 0,
                    endValue: 720,
                    property: "rotate"
                },
                {
                    startValue: 0,
                    endValue: 1,
                    property: "opacity"
                }
            ]

        },
    ];


    return (
        <div className="rollInContainer" id="rollInContainer">


            <Plx className="rollInFromLeftElement" parallaxData={rollInFromLeft} style={{ backgroundImage: `url(${kimchi})` }} />
            <Plx className="rollInFromRightElement" parallaxData={rollInFromRight} style={{ backgroundImage: `url(${rice})` }} />
            <Plx className="rollInFromLeftElement" parallaxData={rollInFromLeft} style={{ backgroundImage: `url(${soju})` }} />
            <Plx className="rollInFromRightElement" parallaxData={rollInFromRight} style={{ backgroundImage: `url(${bbq})` }} />


        </div>
    );
}



