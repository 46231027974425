//WHAT IS THIS?
//A component to turn an array of Notion media information into rendered images or videos. Includes option to specify classNames based on 'type' input, and to manually indicate width if needed. Must-do-steps when re-using: 
// > update options for 'type' input (if needed – nicer if these can work across projects!)
// > update classNames to fit project stylesheet!


const NotionMedia = ({ mediaArray, type, width }) => {

    //Function to create random string
    function makeID(length) {
        var result = '';
        var characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
        var charactersLength = characters.length;
        for (var i = 0; i < length; i++) {
            result += characters.charAt(Math.floor(Math.random() *
                charactersLength));
        }
        return result;
    }

    const key = makeID(16)

    var widthToApply = undefined

    //Returning JSX elements – remember to edit classNames based on project context!
    var mediaClassName = undefined

    switch (type) {
        case 'regular':
            mediaClassName = 'marketStudies-regularImage'
            widthToApply = width ? (width + 'px') : '522px'
            break;
        case 'banner':
            mediaClassName = 'marketStudies-bannerImage'
            widthToApply = width ? (width + 'px') : '1040px'
            break;
        case 'bannerLeft':
            mediaClassName = 'marketStudies-bannerImageLeft'
            widthToApply = width ? (width + 'px') : ''
            break;
        case 'bannerRight':
            mediaClassName = 'marketStudies-bannerImageRight'
            widthToApply = width ? (width + 'px') : ''
            break;
        case 'sticker':
            mediaClassName = 'marketStudies-iconImage'
            widthToApply = width ? (width + 'px') : ''
            break;

    }



    return (



        mediaArray && mediaArray.map((file) => {

            const mediaSource = file.type
            var mediaURL = ''

            //defining the media url based on whether it's external vs file
            if (mediaSource === 'external') {
                mediaURL = file.external.url
            }

            else if (mediaSource === 'file') {
                mediaURL = file.file.url

            }

            //figuring out if it's a photo or video (assuming file, not URL)
            const fileNameSplitDot = file.name.split('.')
            const fileEnding = fileNameSplitDot[1]

            //figuring out if we're looking at a URL (like YouTube link)
            const fileNameSplitColon = file.name.split(':')
            const fileBeginning = fileNameSplitColon[0]

            if (fileEnding === 'mp4' || fileEnding === 'MP4') {

                if (mediaURL.startsWith('http')) {
                    return (

                        <video playsInline controls src={mediaURL} alt="" className={mediaClassName} key={key} style={{ width: `${widthToApply}` }} />


                    )

                }

                else {
                    var localVideo = require(`${mediaURL}`)

                    return (

                        <video playsInline controls src={localVideo} alt="" className={mediaClassName} key={key} style={{ width: `${widthToApply}` }} />


                    )

                }

            }

            else if (fileBeginning === 'http' || fileBeginning === 'https') {


                return (

                    <iframe className={mediaClassName} src={mediaURL} frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture full" allowFullScreen style={{ height: '237.375px' }} />

                )
            }

            else {

                if (mediaURL.startsWith('http')) {

                    return (

                        <img src={mediaURL} loading="lazy" alt="" className={mediaClassName} key={key} style={{ width: `${widthToApply}` }} />

                    )

                }

                else {

                    var localImage = require(`${mediaURL}`)

                    return (
                        <img src={localImage} loading="lazy" alt="" className={mediaClassName} key={key} style={{ width: `${widthToApply}` }} />
                    )
                }

            }



        })


    );
}

export default NotionMedia;