import Plx from "react-plx";


const StickyText = ({ id, startAfter, endAfter, children }) => {

    const start = `#${id}`

    const startValue = `${startAfter}vh`

    const endValue = `${endAfter}vh`

    const stickyText = [
        {
            start: start,
            duration: '30vh',
            properties: [
                {
                    startValue: 0,
                    endValue: -50,
                    unit: 'vh',
                    property: 'translateY',
                },
                {
                    startValue: 0,
                    endValue: 1,
                    property: 'opacity',
                },
            ],
        },
        {
            start: start,
            startOffset: endValue,
            duration: '30vh',
            properties: [
                {
                    startValue: -50,
                    endValue: -100,
                    unit: 'vh',
                    property: 'translateY',
                },
                {
                    startValue: 1,
                    endValue: 0,
                    property: 'opacity',
                },
            ],
        },
    ];

    return (
        <>
            <div style={{marginTop: startValue, marginBottom: endValue}} id={id} />
            <Plx className='stickyText' parallaxData={stickyText}>
                {children}
            </Plx>
        </>
    );
}

export default StickyText;