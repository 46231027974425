import Plx from "react-plx"


const IntroSlideItem = ({ className, id, startOffset, duration, children, doesLeave, leaveAfter }) => {


    const classNameToUse = className ? className : 'introSlideItem'
    const start = `#${id}`

    const introSlideItemAnimationComeOnly =
        [
            {
                start: start,
                startOffset: startOffset,
                duration: duration,
                easing: 'linear',
                properties: [
                    {
                        startValue: 150,
                        endValue: 0,
                        property: "translateY",
                    },
                    {
                        startValue: 0,
                        endValue: 1,
                        property: "opacity",
                    },

                ]

            },
        ]

    const introSlideItemAnimationComeAndLeave =
        [
            {
                start: start,
                startOffset: startOffset,
                duration: duration,
                easing: 'linear',
                properties: [
                    {
                        startValue: 150,
                        endValue: 0,
                        property: "translateY",
                    },
                    {
                        startValue: 0,
                        endValue: 1,
                        property: "opacity",
                    },

                ]

            },
            {
                start: start,
                startOffset: leaveAfter,
                duration: duration,
                easing: 'linear',
                properties: [
                    {
                        startValue: 0,
                        endValue: -150,
                        property: "translateY",
                    },
                    {
                        startValue: 1,
                        endValue: 0,
                        property: "opacity",
                    },

                ]

            },
        ]

    return (

        <Plx className={classNameToUse} parallaxData={doesLeave === true ? introSlideItemAnimationComeAndLeave : introSlideItemAnimationComeOnly}>
            {children}
        </Plx>


    );
}

export default IntroSlideItem;