const SlideShowVideos = ({ video }) => {

    const slideshow_video01 = require('./media/intro/slideshow_video01.mp4')
    const slideshow_video02 = require('./media/intro/slideshow_video02.mp4')
    const slideshow_video03 = require('./media/intro/slideshow_video03.mp4')

    function stopOtherVideos(activeVideo) {

        switch (activeVideo) {
            case 1:
                document.getElementById('slideShowVideo2').pause()
                document.getElementById('slideShowVideo3').pause()
                break;
            case 2:
                document.getElementById('slideShowVideo1').pause()
                document.getElementById('slideShowVideo3').pause()
                break;
            case 3:
                document.getElementById('slideShowVideo1').pause()
                document.getElementById('slideShowVideo2').pause()
                break;

        }

    }


    return (

        <video playsInline onClick={() => stopOtherVideos(video)} id={'slideShowVideo' + video} controls src={video === 1 ? slideshow_video01 : video === 2 ? slideshow_video02 : slideshow_video03} alt="" style={{ maxWidth: '100%', maxHeight: '100%' }} />


    );
}

export default SlideShowVideos;