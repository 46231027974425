import React from "react";
import { useEffect } from "react";
import { useState } from "react";

import NotionMedia from "./NotionMedia";
import NotionParagraph from "./NotionParagraph";

var image00 = require('./media/market/00.png')
var image01 = require('./media/market/01.png')

const MarketDeepDives = ({ market, notionDataMarket, setGraphDone, setSidebarRightOpen, setNavbarBG, shouldUpdate, navbarScrollBehavior, mobileDevice, networkPage }) => {

    //Global useEffect settings
    useEffect(() => {

        setGraphDone(false)

        setSidebarRightOpen(false)

        setNavbarBG(!mobileDevice ? false : true)
        navbarScrollBehavior.current = !mobileDevice ? false : true



        document.getElementById('bgVideo01').style.display = 'unset'
        document.getElementById('bgVideo02').style.display = 'unset'

        networkPage.current = false


    }, []);


    //Function to create random string
    function makeID(length) {
        var result = '';
        var characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
        var charactersLength = characters.length;
        for (var i = 0; i < length; i++) {
            result += characters.charAt(Math.floor(Math.random() *
                charactersLength));
        }
        return result;
    }




    //Fetch data from Notion

    // const [masterArray, setMasterArray] = useState([])
    const [doneFetching, setDoneFetching] = useState(undefined)

    // useFetch('/api/fetchNotion?site=marketStudies&secret=ZqQBumxrt7UHeqL2x3A11QBTxdBneT3Q', setMasterArray, setDoneFetching)

    return (
        <>
            {market &&
                <div className="page-marketStudies" id="page-marketStudies">

                    <div className="pageContent">

                        <div className="page-marketStudies-header">
                            <div className="page-marketStudies-header-column1">
                                <img src={image00} alt="introImage" style={{ width: '85px', borderRadius: '50%' }} />
                            </div>
                            <div className="page-marketStudies-header-column2">
                                <strong>{market && market[0] && market[0].subHeader}</strong>  <p>{market && market[0] && market[0].subHeaderMinutes}</p>
                            </div>
                            <div className="page-marketStudies-header-column3">
                                {market && market[0] && market[0].header}
                            </div>

                        </div>

                        <img src={image01} alt="introImage" className="marketStudies-bannerImage" />
                        {market && market[0] &&
                            <div className="marketStudies-introPara">
                                {market[0].text1}<strong style={{ color: '#FFE31E' }}>Indonesia</strong> and <strong style={{ color: '#FFE31E' }}>Brazil</strong>. {market[0].text2}
                                <br />
                                <br />
                                {market[0].text3}
                            </div>
                        }

                        {notionDataMarket && notionDataMarket[0] &&

                            notionDataMarket.map((item) => {

                                const itemType = item.properties['Website Type'] && item.properties['Website Type'].select.name
                                const mediaArray = item.properties['Photo/ Video'] && item.properties['Photo/ Video'].files
                                const mediaWidth = item.properties['Width in px'] && item.properties['Width in px'].number
                                var returnComponent = undefined

                                switch (itemType) {


                                    case 'Paragraph':
                                        returnComponent = <NotionParagraph itemData={item} key={makeID(16)} />
                                        break;
                                    case 'Banner Image':
                                        returnComponent = <NotionMedia mediaArray={mediaArray} type={'banner'} width={mediaWidth} key={makeID(16)} />
                                        break;
                                    case 'Banner Image (Left)':
                                        returnComponent = <NotionMedia mediaArray={mediaArray} type={'bannerLeft'} width={mediaWidth} key={makeID(16)} />
                                        break;
                                    case 'Banner Image (Right)':
                                        returnComponent = <NotionMedia mediaArray={mediaArray} type={'bannerRight'} width={mediaWidth} key={makeID(16)} />
                                        break;

                                }

                                return (
                                    returnComponent
                                )
                            })

                        }


                        <div style={{ height: '50vh', width: '100vh' }} />







                    </div>
                </div>
            }
        </>

    );
}

export default MarketDeepDives;

// const update = (prevProps, nextProps) => {
//     if (prevProps.shouldUpdate === false) {
//         return true //no re-render
//     }
//     return false //re render
// }

// export default React.memo(MarketDeepDives, update)


