import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import { scryRenderedDOMComponentsWithClass } from "react-dom/test-utils";
import { NavLink } from 'react-router-dom';
import spotifyLogo from './media/spotify.svg';
import { useRef } from "react";

var gemicLogo = require('./media/gemiclogo.png')


var initialMode = ''

if (window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)').matches) {
  initialMode = 'dark'
}

else {
  initialMode = 'light'
}


var timeoutAfterLoad = undefined
var timeoutAfterClick = undefined



const SidebarLeft = ({ toggleSidebarLeft, sidebarLeftOpen, setShowViewToggle, toggleViewMode, productView, setProductView, sidebarRightOpen, setSidebarRightOpen, setSidebarRightData, below600px, networkPage, currentSite }) => {

  // const [arrowLeftIconURL, setArrowLeftIconURL] = useState(initialMode === 'light' ? arrowLeft : arrowLeftWhite)
  // const [arrowRightIconURL, setArrowRightIconURL] = useState(initialMode === 'light' ? arrowRight : arrowRightWhite)
  // const [burgerURL, setBurgerURL] = useState(initialMode === 'light' ? burger : burgerWhite)



  // const [anchorButton, setAnchorButton] = useState(arrowLeftIconURL)


  //sets anchor button to white whenever needed
  const setButtonsWhite = () => {
    // setArrowLeftIconURL(arrowLeftWhite)
    // setArrowRightIconURL(arrowRightWhite)
    // setAnchorButton(sidebarOpen ? arrowLeftWhite : arrowRightWhite)

    // setBurgerURL(burgerWhite)

  }

  //sets anchor button to black whenever needed
  const setButtonsBlack = () => {

    // setArrowLeftIconURL(arrowLeft)s
    // setArrowRightIconURL(arrowRight)
    // setAnchorButton(sidebarOpen ? arrowLeft : arrowRight)

    // setBurgerURL(burger)



  }

  //listens to light/dark mode changes while session is active, and sets logos accordingly
  window.matchMedia('(prefers-color-scheme: dark)').addEventListener('change', e => {
    if (e.matches == true) {
      setButtonsWhite()
    }
    else {

      setButtonsBlack()
    }
  });

  function closeAllPopups() {
    const signalPopup = document.getElementById('popup-signal')
    const driverPopup = document.getElementById('popup-driver')
    const oppPopup = document.getElementById('popup-opp')
    const needPopup = document.getElementById('popup-need')


    signalPopup.style.display = 'none'
    driverPopup.style.display = 'none'
    oppPopup.style.display = 'none'
    needPopup.style.display = 'none'


    // setTimeout(() => {
    //     signalPopupOpen = false
    //     driverPopupOpen = false
    //     oppPopupOpen = false
    //     needPopupOpen = false

    // }, 200);
  }




  // Re touch

  let touchstartX = 0
  let touchendX = 0


  document.addEventListener('touchstart', e => {
    touchstartX = e.changedTouches[0].screenX
  })

  document.addEventListener('touchend', e => {
    touchendX = e.changedTouches[0].screenX
    checkDirection()

  })

  // hides sidebar when swiping left, starting from inside it
  function checkDirection() {

    if (touchendX < touchstartX && touchstartX < (!below600px ? 374 : 280) && sidebarLeftOpen.current === true) {

      console.log('checking things and causing toggle... sidebarLeftOpen: ' + sidebarLeftOpen.current)
      toggleSidebarLeft()

    }
  }


  //sidebar closes if click outside of it

  document.addEventListener('click', function (event) {

    if (event.pageX > (!below600px ? 374 : 280) && sidebarLeftOpen.current === true) {

      toggleSidebarLeft()

    }

  });






  // function toggleSidebar(state) {

  //   const sidebar = document.getElementById('sidebar')
  //   const sidebarContent = document.getElementById('sidebar-content')
  //   const sidebarText = document.getElementById('sidebar-content-text')

  //   var action = ''

  //   if (state === 'click' && sidebarOpen === false) {
  //     action = 'show'
  //   }

  //   else if (state === 'click' && sidebarOpen === true) {

  //     action = 'hide'
  //   }

  //   else {
  //     action = state
  //   }


  //   if (action === 'show') {
  // setTimeout(() => {
  //   sidebarOpen = true
  // }, 200);
  //     sidebar.style.width = '200px'
  //     sidebarContent.style.width = '170px'
  //     setTimeout(() => {
  //       sidebarText.style.display = 'unset'
  //     }, 400);

  //   }


  //   else if (action === 'hide') {
  //     setTimeout(() => {
  //       sidebarOpen = false
  //     }, 200);
  //     sidebar.style.width = '30px'
  //     sidebarContent.style.width = '0px'
  //     sidebarText.style.display = 'none'
  //     sidebarOpen = false
  //     // setTimeout(() => { setAnchorButton(arrowRightIconURL) }, 400);


  //   }

  // }


  function waitAndClose(delay) {

    timeoutAfterLoad && clearTimeout(timeoutAfterLoad)

    function doTheThing() {
      const sidebar = document.getElementById('sidebar')
      const sidebarContent = document.getElementById('sidebar-content')
      const sidebarText = document.getElementById('sidebar-content-text')

      // setTimeout(() => {
      //   sidebarOpen = false
      // }, 200);
      // sidebar.style.width = '30px'
      // sidebarContent.style.width = '0px'
      // sidebarText.style.display = 'none'
      // sidebarOpen = false
      // setTimeout(() => { setAnchorButton(arrowRightIconURL) }, 400);

    }


    timeoutAfterClick = setTimeout(doTheThing, delay)





  }

  const activeLinkStyle = { color: '#6580EF', textDecoration: 'none' }

  const inactiveLinkStyle = { color: 'black', textDecoration: 'none' }


  //handling clicks on sidebar links...


  function handleClick(origin, target) {

    if (target === 'other') {

        //root site always becomes click destination if it's a non-network site
        currentSite.current = target

    }

    //if clicking on either of the network sites for the first time
    if ((target === 'network' || target === 'opps') && (origin === undefined || origin === 'other')) {

        //updating root site (will now say 'network' or 'opps')
        currentSite.current = target
    }

    if (origin === 'network' && target === 'network') {
      if (productView) {
        toggleViewMode()
      }
    }

    if (origin === 'opps' && target === 'opps') {
      if (!productView) {
        toggleViewMode()
      }
    }

    if (origin === 'network' && target === 'opps') {
      currentSite.current = 'network'

      if (!productView) {
        toggleViewMode()
      }

    }

    if (origin === 'opps' && target === 'network') {
      currentSite.current = 'opps'

      if (productView) {
        toggleViewMode()
      }

    }




    //do anything we want to happen on ALL clicks
    setTimeout(() => {
      toggleSidebarLeft()
    }, 500);

    //scroll app to top
    window.scrollTo(0, 0)

    document.getElementById('graph-tutorial').style.display = 'none'
    document.getElementById('graph-tutorial-content').style.display = 'none'
    document.getElementById('graph-helpIcon').style.visibility = 'hidden'

    const sidebarRight = document.getElementById('sidebarRight')

    sidebarRight.style.right = '-533px'

    setTimeout(() => {
      setSidebarRightData(undefined)
    }, 600);



    //do things only when NOT clicking on 'Network'
    if (target === 'other') {

      closeAllPopups()

      setShowViewToggle(false)



      if (productView) { toggleViewMode() }


    }


    // else if (target === 'network') {


    //   if (productView) {
    //     toggleViewMode()

    //     currentSite.current = 'opps'

    //   }




    // }

    // else if (target === 'opps') {


    //   if (!productView) {
    //     toggleViewMode()

    //     currentSite.current = 'network'
    //   }


    // }


  }




  return (

    <div className="sidebarLeft" id="sidebarLeft">

      <div className="sidebarLeft-content" id="sidebarLeft-content">




        <span className="sidebarLeft-closebutton" onClick={() => toggleSidebarLeft()}>&times;</span>

        <NavLink to="/" style={({ isActive }) => isActive ? activeLinkStyle : inactiveLinkStyle} onClick={() => handleClick(currentSite.current, 'other')}><h2>Introduction</h2></NavLink>

        {/* <NavLink to={currentSite.current === 'opps' ? '/productOpp' : '/network'} style={networkPage.current && !productView ? activeLinkStyle : inactiveLinkStyle} onClick={() => handleClick(currentSite.current, 'network')}><h2>Insights Network</h2></NavLink> */}

        <NavLink to='/network' style={networkPage.current && !productView ? activeLinkStyle : inactiveLinkStyle} onClick={() => handleClick(currentSite.current, 'network')}><h2>Insights Network</h2></NavLink>

        <NavLink to={currentSite.current === 'network' ? '/network' : '/productOpp'} style={productView ? activeLinkStyle : inactiveLinkStyle} onClick={() => handleClick(currentSite.current, 'opps')}><h2>Product Inspiration</h2></NavLink>

        <NavLink to="/marketDD" style={({ isActive }) => isActive ? activeLinkStyle : inactiveLinkStyle} onClick={() => handleClick(currentSite.current, 'other')}><h2>Market Studies (BR &amp; ID)</h2>
        </NavLink>
        <NavLink to="/videoReels" style={({ isActive }) => isActive ? activeLinkStyle : inactiveLinkStyle} onClick={() => handleClick(currentSite.current, 'other')}><h2>Sizzle Reels</h2>
        </NavLink>
        <NavLink to="/credits" style={({ isActive }) => isActive ? activeLinkStyle : inactiveLinkStyle} onClick={() => handleClick(currentSite.current, 'other')}><h2>Credits</h2>
        </NavLink>

        <div className="sidebarLeft-logoContainer">
          <img src={gemicLogo} className="sidebarLeft-gemic-logo" alt="gemic logo" />
          <p>+</p>
          <img src={spotifyLogo} className="sidebarLeft-spotify-logo" alt="spotify logo" />
        </div>


        {/* <div className="sidebar-anchorcolumn">
        <div className="sidebar-aboveanchor"></div>
        <div className="sidebar-anchor" onClick={() => toggleSidebar('click')}>
          <img src={burgerURL} alt='Sidebar' className="sidebar-anchor-button" />
        </div>
      </div> */}

      </div>

    </div>
  );
}

export default SidebarLeft;