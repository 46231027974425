import React, { useContext, useEffect, useState, useRef, Component, useImperativeHandle, forwardRef } from "react";
// import Graph from "react-vis-network-graph";
import "./vis-network.min.css";
import useFetch from "./useFetchOld";
import { data, network, Network } from "vis-network";
import { DataSet } from "vis-data";
import StitchedText from "./StitchedText";
import { makeConsoleLogger } from "@notionhq/client/build/src/logging";
import { toHaveFormValues } from "@testing-library/jest-dom/dist/matchers";
import { click } from "@testing-library/user-event/dist/click";

import { networkEdges } from "./data/networkEdges";


// import { networkNodesAll } from "./data/networkNodesAll";
// import { networkNodesIns } from "./data/networkNodesIns";


var globalNetwork = undefined



const Main = forwardRef((props, ref) => {



    //Fetch data from Notion
    // const [masterArray, setMasterArray] = useState([])
    const [doneFetching, setDoneFetching] = useState(undefined)
    const setShowViewToggle = props.setShowViewToggle
    const appHasFetched = props.appHasFetched
    const appLevelMasterArray = props.appLevelMasterArray

    // useFetch('/api/fetchNotion?site=network&secret=ZqQBumxrt7UHeqL2x3A11QBTxdBneT3Q', setMasterArray, setDoneFetching, setShowViewToggle, appHasFetched, appLevelMasterArray)

    const masterArray = appLevelMasterArray

    const toggleSidebarRight = props.toggleSidebarRight
    const updateSidebarRightData = props.updateSidebarRightData
    const shouldUpdate = props.shouldUpdate
    const showViewToggle = props.showViewToggle
    const updatePopupContent = props.updatePopupContent
    const stopTimeoutApp = props.stopTimeoutApp
    const setGraphDone = props.setGraphDone
    const graphDone = props.graphDone
    const setNavbarBG = props.setNavbarBG
    const below600px = props.below600px
    const navbarScrollBehavior = props.navbarScrollBehavior
    const networkPage = props.networkPage

    const networkNodesAll = props.networkNodesAll
    const networkNodesIns = props.networkNodesIns

    //the first time we receive props.networkNodesAll, save it into a separate variable called myBla and make sure we will pertain it even when user reloads the page
    const myBla = useRef(networkNodesAll)
    const myBlub = useRef(networkNodesIns)

    useEffect(() => {

        if (networkNodesAll) {
            myBla.current = networkNodesAll
        }

    }, [networkNodesAll]);

    useEffect(() => {

        if (networkNodesIns) {
            myBlub.current = networkNodesIns
        }

    }, [networkNodesIns]);


    const sidebarRightOpenApp = props.sidebarRightOpen

    const setSidebarRightOpen = props.setSidebarRightOpen

    const currentSite = props.currentSite

    const stopOtherVideos = props.stopOtherVideos

    var sidebarRightOpen = false


    var oppsStatusChanged = false
    var currentScale = 0
    const oppsThreshold = 0.7

    var lastZoomPosition = { x: 0, y: 0 }


    var clickSelection = []

    var productViewOpen = false

    var lastPopupParentShiftLocal = undefined


    const nodesToAdd = myBla.current

    //Vis nav button things

    function repositionNavButtons(state) {

        const rightButton = Array.from(document.getElementsByClassName('vis-button vis-right'))[0]
        const leftButton = Array.from(document.getElementsByClassName('vis-button vis-left'))[0]


        if (state === 'focus') {
            leftButton.style.display = 'unset'
            rightButton.style.right = '573px'
        }

        else {
            rightButton.style.right = '40px'

        }

    }


    function showOpps() {

        // APPROACH 1: setting hidden=false for all opps

        // finding all opps nodes
        const allOppsNodes = nodes.get({
            fields: ["id", "group"],
            filter: item => item.group === 'opportunities'
        });

        var oppsObjectsToUpdate = []

        allOppsNodes.map((opp) => {
            const newObject = { id: opp.id, hidden: false }
            oppsObjectsToUpdate.push(newObject)
        })

        nodes.update(oppsObjectsToUpdate)


        oppsStatusChanged = true
    }

    function hideSignals() {

        const allSignalNodes = nodes.get({
            fields: ["id", "group"],
            filter: item => item.group === 'signals'
        });

        var signalsToHide = []

        allSignalNodes.map((signal) => {
            const updateObject = { id: signal.id, hidden: true }
            signalsToHide.push(updateObject)
        })

        nodes.update(signalsToHide)

    }

    function hideOpps() {

        const allOppsNodes = nodes.get({
            fields: ["id", "group"],
            filter: item => item.group === 'opportunities'
        });

        var oppsToHide = []

        allOppsNodes.map((opp) => {
            const updateObject = { id: opp.id, hidden: true }
            oppsToHide.push(updateObject)
        })

        nodes.update(oppsToHide)

        oppsStatusChanged = false


    }

    var timeoutUseEffect = undefined

    function closeTutorial() {

        window.clearTimeout(timeoutUseEffect)

        stopTimeoutApp()

        const tutorial = document.getElementById('graph-tutorial')
        const tutorialContent = document.getElementById('graph-tutorial-content')
        const helpIcon = document.getElementById('graph-helpIcon')

        tutorialContent.style.display = 'none'

        tutorial.style.width = "0vw"
        tutorial.style.height = "0px"
        tutorial.style.bottom = "34px"
        tutorial.style.left = "70px"



        setTimeout(() => {

            helpIcon.style.display = 'unset'
            helpIcon.style.visibility = 'unset'





        }, 400);
    }




    useImperativeHandle(ref, () => ({

        //name is understatement: by now, this function does all kinds of things to switch to 'Implications' mode
        showProductNodes: () => {

            closeAllPopups()

            globalNetwork.setOptions({
                layout: {
                    hierarchical: true
                },
                groups: {

                    shifts: {
                        chosen: {
                            node: function (values, id, selected, hovering) {

                                values.color = nodeColorShiftSelected;
                                values.shadow = true;
                                values.shadowColor = 'rgba(255,255,255,0.6)';
                                values.shadowSize = 40;
                                values.shadowX = '0';
                                values.shadowY = '0';


                                if (hovering) {
                                    globalNetwork.canvas.body.container.style.cursor = 'pointer'
                                }



                            },
                            label: function (values, id, selected, hovering) {

                                values.color = fontColorShiftSelected;

                            }
                        }
                    }
                }
            })

            const allEdges = edges.getIds()
            var edgesToUpdate = []
            allEdges.map((edge) => {
                const updateObject = {
                    id: edge,
                    color: {
                        color: edgeColorUnselectedImp,
                        highlight: edgeColorSelectedImp,
                        hover: edgeColorSelectedImp,
                    },
                }
                edgesToUpdate.push(updateObject)
            })

            edges.update(edgesToUpdate)


            const nodesToHide = nodes.get({
                fields: ["id", "group"],
                filter: item => item.group === 'signals' || item.group === 'drivers' || item.group === 'heroSignals'
            });

            nodes.remove(nodesToHide)

            setTimeout(() => {
                productViewOpen = true
            }, 200);

            // const shiftsToChange = nodes.get({
            //     fields: ["id", "group"],
            //     filter: item => item.group === 'shifts'
            // });


            // shiftsToChange.map((shift) => {
            //     nodes.update({ id: shift.id, group: 'shifts2' })
            // })

            const nodesToShow = nodesToAdd.filter(item => item.group === 'needs' || item.group === 'opportunities')

            nodes.add(nodesToShow)

            // TK ideal woud be if we could instead run 'globalNetwork.fit()' here, but this appears to be hard to time
            globalNetwork.moveTo({
                position: { x: !below600px ? -3192 : -3600, y: 11.54 },
                scale: 0.56245
            })




        },

        showExplorationNodes: () => {

            closeAllPopups()

            globalNetwork.setOptions(options)


            const allEdges = edges.getIds()
            var edgesToUpdate = []
            allEdges.map((edge) => {
                const updateObject = {
                    id: edge,
                    color: {
                        color: edgeColorUnselected,
                        highlight: edgeColorSelected,
                        hover: edgeColorSelected,
                    },
                }
                edgesToUpdate.push(updateObject)
            })

            edges.update(edgesToUpdate)


            const nodesToHide = nodes.get({
                fields: ["id", "group"],
                filter: item => item.group === 'needs' || item.group === 'opportunities'
            });

            nodes.remove(nodesToHide)

            setTimeout(() => {
                productViewOpen = false
            }, 200);

            // const shiftsToChange = nodes.get({
            //     fields: ["id", "group"],
            //     filter: item => item.group === 'shifts2'
            // });


            // shiftsToChange.map((shift) => {
            //     nodes.update({ id: shift.id, group: 'shifts' })
            // })


            const nodesToShow = nodesToAdd.filter(item => item.group === 'signals' || item.group === 'drivers' || item.group === 'heroSignals')

            nodes.add(nodesToShow)

            //TK ideal woud be if we could instead run 'globalNetwork.fit()' here, but this appears to be hard to time
            globalNetwork.moveTo({
                position: { x: 0, y: 0 },
                scale: 0.3
            })




        },

        setLegendToImplications: () => {

            const otherLegend = document.getElementById('graph-legend-implications')
            const legend = document.getElementById('graph-legend-insights')

            const node1 = document.getElementById('graph-legend-node1')
            const node2 = document.getElementById('graph-legend-node2')
            const node3 = document.getElementById('graph-legend-node3')
            const node1txt = document.getElementById('graph-legend-node1-txt')
            const node2txt = document.getElementById('graph-legend-node2-txt')
            const node3txt = document.getElementById('graph-legend-node3-txt')


            legend.style.display = 'unset'
            otherLegend.style.display = 'none'

            node1.style.backgroundColor = '#FFFFFF'
            node1txt.style.color = '#FFFFFF'
            node2.style.backgroundColor = '#FFFFFF'
            node2txt.style.color = '#FFFFFF'
            node3.style.backgroundColor = '#6580EF'
            node3txt.style.color = '#6580EF'




        },

        setLegendToInsights: () => {

            const legend = document.getElementById('graph-legend-implications')
            const otherLegend = document.getElementById('graph-legend-insights')

            const node1 = document.getElementById('graph-legend-node1')
            const node2 = document.getElementById('graph-legend-node2')
            const node3 = document.getElementById('graph-legend-node3')
            const node1txt = document.getElementById('graph-legend-node1-txt')
            const node2txt = document.getElementById('graph-legend-node2-txt')
            const node3txt = document.getElementById('graph-legend-node3-txt')

            legend.style.display = 'unset'
            otherLegend.style.display = 'none'

            node1.style.backgroundColor = '#FFFFFF'
            node1txt.style.color = '#FFFFFF'
            node2.style.backgroundColor = '#CFFFB7'
            node2txt.style.color = '#CFFFB7'
            node3.style.backgroundColor = '#FFE41E'
            node3txt.style.color = '#FFE41E'


        },

        setSidebarRightOpen: (state) => {
            if (state === true) {
                sidebarRightOpen = true
            }
            else {
                sidebarRightOpen = false


            }
        },

        closeAllPopups: () => {

            stopOtherVideos('all')
            const signalPopup = document.getElementById('popup-signal')
            const driverPopup = document.getElementById('popup-driver')
            const oppPopup = document.getElementById('popup-opp')
            const needPopup = document.getElementById('popup-need')

            if (signalPopupOpen) { signalPopup.style.display = 'none' }
            if (driverPopupOpen) { driverPopup.style.display = 'none' }
            if (oppPopupOpen) { oppPopup.style.display = 'none' }
            if (needPopupOpen) { needPopup.style.display = 'none' }


            setTimeout(() => {
                signalPopupOpen = false
                driverPopupOpen = false
                oppPopupOpen = false
                needPopupOpen = false

            }, 200);
        },

        getViewPosition: () => {
            const position = globalNetwork.getViewPosition()

            return position
        },

        focusNode: () => {

            //get the index of the item
            const itemIndex = masterArray.indexOf(lastPopupParentShiftLocal)
            const nodeID = itemIndex + 1

            //higer value = left; lower value = right
            const xOffset = window.innerWidth * 0.2

            //higer value = up; lower value = down
            const yOffset = window.innerHeight * 0.3

            const zoomScale = 0.0004 * (window.innerWidth + window.innerHeight)

            globalNetwork.focus(nodeID, {
                scale: zoomScale,
                offset: { x: -xOffset, y: -yOffset },
                locked: false,
                animation: {
                    duration: 2000,
                    easingFunction: "easeOutQuad"
                },

            })


        },

        repositionNavButtons: repositionNavButtons,

    }))





    function closeAllPopups() {

        stopOtherVideos('all')

        const signalPopup = document.getElementById('popup-signal')
        const driverPopup = document.getElementById('popup-driver')
        const oppPopup = document.getElementById('popup-opp')
        const needPopup = document.getElementById('popup-need')


        signalPopup.style.display = 'none'
        driverPopup.style.display = 'none'
        oppPopup.style.display = 'none'
        needPopup.style.display = 'none'


        setTimeout(() => {
            signalPopupOpen = false
            driverPopupOpen = false
            oppPopupOpen = false
            needPopupOpen = false

        }, 200);
    }

    // Variables for vis 

    var selectedNodeID = undefined
    var signalPopupOpen = false
    var driverPopupOpen = false
    var oppPopupOpen = false
    var needPopupOpen = false


    var nodes = new DataSet(myBlub.current)

    // useEffect(() => {

    //     if (networkNodesIns) {
    //         nodes = new DataSet(networkNodesIns)
    //     }

    // }, [networkNodesIns]);

    var edges = new DataSet(networkEdges)


    var nodesCounter = 0
    var edgesCounter = 0


    //defining some global colors for nodes (to only have them change once)

    const globalShadowSize = '30'

    const nodeColorShiftUnselected = 'transparent'
    const nodeColorShiftSelected = 'white'
    const nodeColorShift2Unselected = '#e2dae5'
    const nodeColorShift2Selected = '#ffffff'

    const nodeColorShiftSkelUnselected = '#947a93'
    const nodeColorShiftSkelSelected = '#FFE41E'

    const fontColorShiftUnselected = '#FFFFFF'
    const fontColorShiftSelected = '#FFFFFF'
    const fontColorShift2Unselected = '#888388'
    const fontColorShift2Selected = '#000000'

    const nodeColorDriverUnselected = '#CFFFB7'
    const nodeColorDriverSelected = '#CFFFB7'
    const fontColorDriverUnselected = '#000000'
    const fontColorDriverSelected = '#000000'

    const nodeColorNeedUnselected = '#ffffff'
    const nodeColorNeedSelected = '#ffffff'
    const fontColorNeedUnselected = '#000000'
    const fontColorNeedSelected = '#000000'


    var nodeColorSignalUnselected = '#795FB0'
    var nodeColorSignalSelected = 'white'

    //when zoomed in:
    // var nodeColorSignalUnselected = '#8068b4'
    // var nodeColorSignalSelected = '#a493c9'

    const nodeColorOppsUnselected = '#6480ef'
    const nodeColorOppsSelected = '#6480ef'
    const fontColorOppsUnselected = '#FFFFFF'
    const fontColorOppsSelected = '#FFFFFF'

    var edgeColorUnselected = '#c5c5c5'
    var edgeColorSelected = '#ebebeb'
    var edgeColorHover = '#ebebeb'

    var edgeColorUnselectedImp = '#838383'
    var edgeColorSelectedImp = '#9d9d9d'
    var edgeColorHoverImp = '#ebebeb'










    // const nodesToAdd = networkNodesAll


    // {


    //         masterArray.map((item) => {

    //             nodesCounter += 1
    //             const type = item.properties.Type.select.name
    //             var group = ''
    //             switch (type) {
    //                 case 'Shift':
    //                     group = 'shifts'
    //                     break;
    //                 case 'Driver':
    //                     group = 'drivers'
    //                     break;
    //                 case 'Signal':

    //                     const isHero = item.properties['Hero?'].checkbox

    //                     if (isHero === true) {

    //                         group = 'heroSignals'
    //                     }

    //                     else if (isHero === false) {
    //                         group = 'signals'
    //                     }

    //                     break;

    //                 case 'Need':
    //                     group = 'needs'
    //                     break;
    //                 case 'Opportunity Area':
    //                     group = 'opportunities'
    //                     break;
    //                 default:
    //                     return
    //             }

    //             const titleArray = item.properties.Name.title
    //             var rawTitle = ''
    //             titleArray.map((textPiece) => (rawTitle += textPiece.text.content))
    //             const titleWithColon = rawTitle.replace(/\r?\n|\r/g, '')
    //             const titleSplit = titleWithColon.split(': ')
    //             const title = titleSplit[1]



    //             var image = 'undefined'

    //             const mediaSource = item.properties['Cover image'].files[0] && item.properties['Cover image'].files[0].type

    //             //defining the media url based on whether it's external vs file
    //             if (mediaSource === 'external') {
    //                 image = item.properties['Cover image'].files[0].external.url
    //             }

    //             else if (mediaSource === 'file') {
    //                 image = item.properties['Cover image'].files[0].file.url

    //             }

    //             else {

    //                 image = 'https://cdn1.iconfinder.com/data/icons/leto-files/64/leto_files-58-512.png'

    //             }

    //             // const xPosition = item.properties.x.number
    //             // const yPosition = item.properties.y.number


    //             const descriptionArray = item.properties['Description'].rich_text
    //             var rawDescription = ''
    //             descriptionArray.map((textPiece) => (rawDescription += textPiece.text.content))
    //             const description = rawDescription.replace(/\r?\n|\r/g, '')

    //             var newNode = undefined

    //             switch (group) {
    //                 case 'signals':
    //                     newNode = { id: nodesCounter, group: group, image: image, title: title }
    //                     break;
    //                 case 'opportunities':
    //                     newNode = { id: nodesCounter, group: group, label: title }
    //                     break;
    //                 case 'heroSignals':
    //                     newNode = { id: nodesCounter, group: group, image: image, title: title }
    //                     break;
    //                 case 'drivers':
    //                     newNode = { id: nodesCounter, group: group, label: title }
    //                     break;
    //                 case 'needs':
    //                     newNode = { id: nodesCounter, group: group, label: title }
    //                     break;
    //                 case 'shifts':
    //                     newNode = { id: nodesCounter, group: group, image: image }
    //                     break;
    //                 case 'shifts2':
    //                     newNode = { id: nodesCounter, group: group, image: image }
    //                     break;


    //             }

    //             nodesToAdd.push(newNode)


    //         })

    //     const explorationViewNodesOnly = nodesToAdd.filter(item => item.group === 'shifts' || item.group === 'signals' || item.group === 'heroSignals' || item.group === 'drivers')


    //     const allToRemove = nodes.get({
    //         fields: ["id"],
    //         filter: item => item.id.startsWith('skeleton') === true
    //     });

    //     nodes.remove(allToRemove)

    //     nodes.add(explorationViewNodesOnly)


    // }


    const edgesToAdd = []


    // {



    //     masterArray.map((item) => {


    //         edgesCounter += 1

    //         const type = item.properties.Type.select.name

    //         //just covering shifts and needs for now
    //         // if (type !== 'Shift' && type !== 'Need') {
    //         //     return
    //         // }

    //         //creates an array of relations, each with an object containing an 'id' property
    //         const relations = item.properties['Related content'].relation

    //         //now we need to map through that relations array
    //         relations.map((rel) => {

    //             //getting the id of the relation (= a Notion page ID)
    //             const relationID = rel.id

    //             //now we need to find in the notionData array the page with said ID
    //             const relatedItem = masterArray.find(({ id }) => id === relationID);

    //             //get type of related item
    //             const relatedItemType = relatedItem && relatedItem.properties.Type.select.name

    //             //now we get the index of the found item
    //             const relatedItemIndex = masterArray.indexOf(relatedItem)

    //             //now we need to define the target node ID for the vis edge
    //             const edgeTo = relatedItemIndex + 1

    //             //making language easier...
    //             const edgeFrom = edgesCounter

    //             //generate id as random string
    //             const idString = makeID(64)

    //             //hide edges if they connect to signals
    //             var isHidden = false
    //             if (type === 'Signal' || relatedItemType === 'Signal') {
    //                 isHidden = true
    //             }

    //             //now defining new vis edge
    //             const newEdge = { id: idString, from: edgeFrom, to: edgeTo, hidden: isHidden }




    //             //adding new edge to vis data...   
    //             // edges.add(newEdge)

    //             edgesToAdd.push(newEdge)



    //         })



    //     })

    //     const allToRemove = edges.get({
    //         fields: ["id"],
    //         filter: item => item.id.startsWith('skeleton') === true
    //     });

    //     edges.remove(allToRemove)

    //     console.log(edgesToAdd)

    //     edges.add(edgesToAdd)

    // }




    const options = {

        autoResize: true,
        groups: {

            shifts: {
                borderWidth: 0,
                chosen: {
                    node: function (values, id, selected, hovering) {

                        values.color = nodeColorShiftSelected;

                        values.shadow = true;
                        values.shadowColor = 'rgba(255,255,255,0.3)';
                        values.shadowSize = 40;

                        values.shadowX = '0';
                        values.shadowY = '0';


                        if (hovering) {
                            globalNetwork.canvas.body.container.style.cursor = 'pointer'
                        }



                    },
                    label: function (values, id, selected, hovering) {

                        values.color = fontColorShiftSelected;

                    }
                },
                color: {
                    border: '#0b0b0b',
                    background: nodeColorShiftUnselected,
                    highlight: {
                        border: '#1db954',
                    },
                    hover: {
                        border: '#1db954',
                        background: nodeColorShiftSelected
                    }
                },
                shape: 'circularImage',
                // image: 'https://cdn-icons-png.flaticon.com/512/1032/1032685.png',
                font: {
                    color: fontColorShiftUnselected,
                    face: 'Inconsolata',

                },
                value: 1,
                labelHighlightBold: false,
                widthConstraint: {
                    minimum: 140,
                    maximum: 140
                },
                scaling: {
                    min: 120,
                    max: 120,
                    label: {
                        enabled: true,
                        min: 22,
                        max: 22,
                        // maxVisible: 30,
                        drawThreshold: 6
                    }
                }

            },
            shifts2: {
                borderWidth: 0,
                chosen: {
                    node: function (values, id, selected, hovering) {

                        values.color = nodeColorShift2Selected;

                        values.shadow = true;
                        values.shadowColor = 'rgba(255,255,255,0.3)';
                        values.shadowSize = globalShadowSize;
                        values.shadowX = '0';
                        values.shadowY = '0';

                        if (hovering) {
                            globalNetwork.canvas.body.container.style.cursor = 'pointer'
                        }



                    },
                    label: function (values, id, selected, hovering) {

                        values.color = fontColorShift2Selected;

                    }
                },
                color: {
                    border: '#0b0b0b',
                    background: nodeColorShift2Unselected,
                    highlight: {
                        border: '#1db954',
                    },
                    hover: {
                        border: '#1db954',
                        background: nodeColorShift2Selected
                    }
                },
                shape: 'circle',
                font: {
                    color: fontColorShift2Unselected,
                    face: 'Inconsolata',
                },
                value: 1,
                labelHighlightBold: false,
                widthConstraint: {
                    minimum: 140,
                    maximum: 140
                },
                scaling: {
                    min: 75,
                    max: 75,
                    label: {
                        enabled: true,
                        min: 16,
                        max: 16,
                        // maxVisible: 30,
                        drawThreshold: 6
                    }
                }

            },
            shiftsSkel: {
                borderWidth: 0,
                chosen: {
                    node: function (values, id, selected, hovering) {

                        values.color = nodeColorShiftSkelSelected;

                        values.shadow = true;
                        values.shadowColor = nodeColorShiftSkelSelected;
                        values.shadowSize = globalShadowSize;
                        values.shadowX = '0';
                        values.shadowY = '0';

                        if (hovering) {
                            globalNetwork.canvas.body.container.style.cursor = 'pointer'
                        }



                    },
                    label: function (values, id, selected, hovering) {

                        values.color = fontColorShiftSelected;

                    }
                },
                color: {
                    border: '#0b0b0b',
                    background: nodeColorShiftSkelUnselected,
                    highlight: {
                        border: '#1db954',
                    },
                    hover: {
                        border: '#1db954',
                        background: nodeColorShiftSkelSelected
                    }
                },
                shape: 'circle',
                font: {
                    color: fontColorShiftUnselected,
                    face: 'Inconsolata',

                },
                value: 1,
                labelHighlightBold: false,
                widthConstraint: {
                    minimum: 140,
                    maximum: 140
                },
                scaling: {
                    min: 75,
                    max: 75,
                    label: {
                        enabled: true,
                        min: 16,
                        max: 16,
                        // maxVisible: 30,
                        drawThreshold: 6
                    }
                }

            },
            drivers: {
                borderWidth: 0,
                chosen: {
                    node: function (values, id, selected, hovering) {

                        values.color = nodeColorDriverSelected;
                        values.shadow = true;
                        values.shadowColor = 'rgba(207,255,183, 0.5)';
                        values.shadowSize = globalShadowSize;
                        values.shadowX = '0';
                        values.shadowY = '0';

                        if (hovering) {
                            globalNetwork.canvas.body.container.style.cursor = 'pointer'
                        }

                    },
                    label: function (values, id, selected, hovering) {

                        values.color = fontColorDriverSelected;

                    }
                },
                color: {
                    background: nodeColorDriverUnselected
                },
                shape: 'circle',
                font: {
                    color: fontColorDriverUnselected,
                    face: 'Inconsolata',
                },
                value: 1,
                margin: 10,
                labelHighlightBold: false,
                widthConstraint: {
                    minimum: 80,
                    maximum: 80,
                },
                scaling: {
                    min: 50,
                    max: 50,
                    label: {
                        enabled: true,
                        min: 12,
                        max: 12,
                        // maxVisible: 30,
                        drawThreshold: 5
                    }
                }


            },

            driversSkel: {
                borderWidth: 0,
                chosen: {
                    node: function (values, id, selected, hovering) {

                        values.color = nodeColorDriverSelected;
                        values.shadow = true;
                        values.shadowColor = nodeColorDriverSelected;
                        values.shadowSize = globalShadowSize;
                        values.shadowX = '0';
                        values.shadowY = '0';

                        if (hovering) {
                            globalNetwork.canvas.body.container.style.cursor = 'pointer'
                        }

                    },
                    label: function (values, id, selected, hovering) {

                        values.color = fontColorDriverSelected;

                    }
                },
                color: {
                    background: nodeColorDriverUnselected
                },
                shape: 'circle',
                font: {
                    color: fontColorDriverUnselected,
                    face: 'Inconsolata',
                },
                value: 1,
                labelHighlightBold: false,
                widthConstraint: {
                    minimum: 80,
                    maximum: 80,
                },
                scaling: {
                    min: 45,
                    max: 45,
                    label: {
                        enabled: true,
                        min: 12,
                        max: 12,
                        // maxVisible: 30,
                        drawThreshold: 5
                    }
                }


            },

            needs: {
                borderWidth: 0,
                chosen: {
                    node: function (values, id, selected, hovering) {

                        values.color = nodeColorNeedSelected;
                        values.shadow = true;
                        values.shadowColor = 'rgba(255,255,255,0.6)';
                        values.shadowSize = globalShadowSize;
                        values.shadowX = '0';
                        values.shadowY = '0';

                        if (hovering) {
                            globalNetwork.canvas.body.container.style.cursor = 'pointer'
                        }


                    },
                    label: function (values, id, selected, hovering) {

                        values.color = fontColorNeedSelected;

                    }
                },
                color: {
                    background: nodeColorNeedUnselected
                },
                shape: 'circle',
                font: {
                    color: fontColorNeedUnselected,
                    face: 'Inconsolata',
                },
                value: 1,
                margin: 10,
                labelHighlightBold: false,
                widthConstraint: {
                    minimum: 90,
                    maximum: 90,
                },
                scaling: {
                    min: 65,
                    max: 65,
                    label: {
                        enabled: true,
                        min: 12,
                        max: 12,
                        // maxVisible: 30,
                        drawThreshold: 6
                    }
                }


            },


            signals: {
                hidden: true,
                // opacity: 0.5,
                borderWidth: 0,
                chosen: {
                    node: function (values, id, selected, hovering) {


                        values.color = nodeColorSignalSelected;
                        values.shadow = true;
                        values.shadowColor = 'rgba(255,255,255,0.3)';
                        values.shadowSize = globalShadowSize;
                        values.shadowX = '0';
                        values.shadowY = '0';


                        if (hovering) {
                            globalNetwork.canvas.body.container.style.cursor = 'pointer'
                        }



                    },
                    label: function (values, id, selected, hovering) {

                        values.color = fontColorShiftSelected;

                    }
                },
                color: {
                    background: nodeColorSignalUnselected,
                    border: 'black',
                },
                shape: 'circularImage',
                // image: 'https://cdn1.vectorstock.com/i/thumb-large/50/20/no-photo-or-blank-image-icon-loading-images-vector-37375020.jpg',
                // brokenImage: 'https://cdn1.vectorstock.com/i/thumb-large/50/20/no-photo-or-blank-image-icon-loading-images-vector-37375020.jpg',
                value: 1,
                labelHighlightBold: false,
                widthConstraint: {
                    minimum: 40,
                    maximum: 40
                },
                scaling: {
                    min: 24,
                    max: 24,
                    label: {
                        enabled: true,
                        min: 7,
                        max: 7,
                        // maxVisible: 30,
                        drawThreshold: 8
                    }
                }


            },

            heroSignals: {
                hidden: false,
                // opacity: 0.5,
                borderWidth: 0,
                chosen: {
                    node: function (values, id, selected, hovering) {

                        values.color = nodeColorSignalSelected;
                        values.shadow = true;
                        values.shadowColor = nodeColorSignalSelected;
                        values.shadowSize = globalShadowSize;
                        values.shadowX = '0';
                        values.shadowY = '0';

                        if (hovering) {
                            globalNetwork.canvas.body.container.style.cursor = 'pointer'
                        }

                    },
                    label: function (values, id, selected, hovering) {

                        values.color = fontColorShiftSelected;

                    }
                },
                color: {
                    background: nodeColorSignalUnselected,
                    border: 'black',
                },
                shape: 'circularImage',
                // image: 'https://cdn1.vectorstock.com/i/thumb-large/50/20/no-photo-or-blank-image-icon-loading-images-vector-37375020.jpg',
                // brokenImage: 'https://cdn1.vectorstock.com/i/thumb-large/50/20/no-photo-or-blank-image-icon-loading-images-vector-37375020.jpg',
                value: 1,
                labelHighlightBold: false,
                widthConstraint: {
                    minimum: 40,
                    maximum: 40
                },
                scaling: {
                    min: 24,
                    max: 24,
                    label: {
                        enabled: true,
                        min: 7,
                        max: 7,
                        // maxVisible: 30,
                        drawThreshold: 8
                    }
                }


            },

            signalsSkel: {
                hidden: false,
                // opacity: 0.5,
                borderWidth: 0,
                chosen: {
                    node: function (values, id, selected, hovering) {


                        values.color = 'nodeColorSignalSelected';
                        values.shadow = true;
                        values.shadowColor = nodeColorSignalSelected;
                        values.shadowSize = globalShadowSize;
                        values.shadowX = '0';
                        values.shadowY = '0';


                        if (hovering) {
                            globalNetwork.canvas.body.container.style.cursor = 'pointer'
                        }



                    },
                    label: function (values, id, selected, hovering) {

                        values.color = fontColorShiftSelected;

                    }
                },
                color: {
                    background: nodeColorSignalUnselected,
                    border: 'black',
                },
                shape: 'circle',
                // image: 'https://cdn1.vectorstock.com/i/thumb-large/50/20/no-photo-or-blank-image-icon-loading-images-vector-37375020.jpg',
                // brokenImage: 'https://cdn1.vectorstock.com/i/thumb-large/50/20/no-photo-or-blank-image-icon-loading-images-vector-37375020.jpg',
                value: 1,
                labelHighlightBold: false,
                widthConstraint: {
                    minimum: 40,
                    maximum: 40
                },
                scaling: {
                    min: 24,
                    max: 24,
                    label: {
                        enabled: true,
                        min: 7,
                        max: 7,
                        // maxVisible: 30,
                        drawThreshold: 8
                    }
                }


            },

            opportunities: {
                hidden: false,
                // opacity: 0.5,
                borderWidth: 0,
                chosen: {
                    node: function (values, id, selected, hovering) {


                        values.color = nodeColorOppsSelected;
                        values.shadow = true;
                        values.shadowColor = 'rgba(100,128,239, 0.5)';
                        values.shadowSize = globalShadowSize;
                        values.shadowX = '0';
                        values.shadowY = '0';


                        if (hovering) {
                            globalNetwork.canvas.body.container.style.cursor = 'pointer'
                        }



                    },
                    label: function (values, id, selected, hovering) {

                        values.color = fontColorOppsSelected;

                    }
                },
                color: {
                    background: nodeColorOppsUnselected,
                    border: 'black',
                },
                shape: 'circle',
                font: {
                    color: fontColorOppsUnselected,
                    face: 'Inconsolata',
                },
                // image: 'https://cdn1.vectorstock.com/i/thumb-large/50/20/no-photo-or-blank-image-icon-loading-images-vector-37375020.jpg',
                // brokenImage: 'https://cdn1.vectorstock.com/i/thumb-large/50/20/no-photo-or-blank-image-icon-loading-images-vector-37375020.jpg',
                value: 1,
                margin: 10,
                labelHighlightBold: false,
                widthConstraint: {
                    minimum: 70,
                    maximum: 70
                },
                scaling: {
                    min: 54,
                    max: 54,
                    label: {
                        enabled: true,
                        min: 11,
                        max: 11,
                        // maxVisible: 30,
                        drawThreshold: 6
                    }
                }


            },


        },

        configure: {
            enabled: false
        },
        layout: {
            // randomSeed: '0.25348439363487185:1661847910242',
            // randomSeed: '0.1354936220467441:1662448022344',
            // randomSeed: '0.9640791857255703:1662448496447',
            // randomSeed: '0.733218921962384:1662458386676',
            // randomSeed: '0.0828743700198512:1662478436612',
            // randomSeed: '0.7130682705601238:1662539949429',
            randomSeed: '0.02317546780659885:1662708502726',

            hierarchical: {
                enabled: false,

                levelSeparation: 300,
                //space between 'levels', as created by connections

                nodeSpacing: 200,
                //changes node spacing globally?

                treeSpacing: 10,
                //e.g. if the direction is UD, the levels will be from up to down (/rows) but the 'trees' will be columns, and this sets the space between columns

                blockShifting: true,
                edgeMinimization: true,
                //cannot see effect of both atm

                parentCentralization: true,
                //puts parent centrally above its children

                direction: 'UD',        // UD, DU, LR, RL

                sortMethod: 'directed',  // hubsize, directed
                //in simple example, 'directed' puts shifts on top (and 2 connected drivers below), while 'hubsize' puts the shift into the middle

                shakeTowards: 'roots'  // roots, leaves
                //in simple example, this changes whether layout aligns on shifts ('root') or drivers ('leaves') level
            }
        },

        edges: {
            // hidden: true,
            arrows: {
                to: {
                    enabled: false
                }
            },
            color: {
                color: edgeColorUnselected,
                highlight: edgeColorSelected,
                hover: edgeColorHover,
                inherit: false
            },
            dashes: true,
            smooth: {
                enabled: true,
                type: "continuous",
                roundness: 0,
            },
            width: 0.5,
            // length: 120,
            hoverWidth: 0.5,
            selectionWidth: 1.5,

        },


        interaction: {
            dragNodes: true,
            dragView: true,
            hideEdgesOnDrag: false,
            hideEdgesOnZoom: false,
            hideNodesOnDrag: false,
            hover: true,
            tooltipDelay: 200,
            zoomView: true,
            zoomSpeed: 0.3,
            selectConnectedEdges: false,
            navigationButtons: true,
            //for some reason the below still controls the speed of movement for pressing nav buttons (even if keyboard control is disabled)
            keyboard: {
                enabled: false,
                speed: { x: 5, y: 1, zoom: 0.02 },
                bindToWindow: true,
                autoFocus: true,
            },
        },

        physics: {
            forceAtlas2Based: {
                gravitationalConstant: -126,
                springLength: 200,
                springConstant: 0.01
            },
            maxVelocity: 50,
            solver: "forceAtlas2Based",
            timestep: 0.35,
            stabilization: true
        },




    }



    //deprecated – now putting events directly into useEffect

    const events = {

        hoverNode: function (event) {
            var { node } = event;
            // const bla = globalNetwork.getConnectedNodes(node)
            // console.log('hover... connections are: ' + bla)


        },
    };



    //New vis approach
    const visJsRef = useRef(null);

    // useEffect(() => {


    //     masterArray && setAppLevelMasterArray(masterArray)



    // }, [masterArray]);

    useEffect(() => {

        setSidebarRightOpen(false)
        setNavbarBG(false)
        navbarScrollBehavior.current = false

        document.getElementById('bgVideo01').style.display = 'unset'
        document.getElementById('bgVideo02').style.display = 'unset'

        networkPage.current = true

        currentSite.current = 'network'



    }, []);



    useEffect(() => {

        globalNetwork =
            visJsRef.current &&
            new Network(visJsRef.current, { nodes, edges, options, events });



    }, [nodes, edges]);



    useEffect(() => {

        //whenever edges changes, let's clean it from duplicates

        //going through entire edges dataset
        edges.map((edge) => {

            //for each edge, check if there is a dupliate edge where from = to and to = from
            const duplicateCheck = edges.get({
                fields: ["id", "from", "to"],
                filter: item => item.from === edge.to && item.to === edge.from
            });


            //any resuts of this check shall be removed from the edges dataset:

            // const duplicateID = duplicateCheck && duplicateCheck[0].id

            const duplicateID = duplicateCheck[0] && duplicateCheck[0].id

            if (!duplicateID) {
                return
            }

            edges.remove(duplicateID)

        })




    }, [edges]);


    useEffect(() => {

        document.getElementById('bgVideo01').style.display = 'unset'
        document.getElementById('bgVideo02').style.display = 'unset'


        // document.getElementById('graph-tutorial').style.display = 'none'
        // document.getElementById('graph-tutorial-content').style.display = 'none'
        // document.getElementById('graph-helpIcon').style.display = 'none'

        setGraphDone(false)
        setShowViewToggle(true)



    }, []);

    useEffect(() => {



        // Tutorial stuff

        const tutorial = document.getElementById('graph-tutorial')
        const tutorialContent = document.getElementById('graph-tutorial-content')
        const helpIcon = document.getElementById('graph-helpIcon')
        helpIcon.style.visibility = 'unset'
        helpIcon.style.display = 'none'



        tutorial.style.display = 'flex'
        tutorial.style.width = "80vw"
        tutorial.style.height = "100px"
        tutorial.style.bottom = "40px"
        tutorial.style.left = "calc(20vw/2)"
        tutorialContent.style.display = 'flex'



        timeoutUseEffect = window.setTimeout(() => {

            tutorialContent.style.display = 'none'

            tutorial.style.width = "0vw"
            tutorial.style.height = "0px"
            tutorial.style.bottom = "34px"
            tutorial.style.left = "70px"



            setTimeout(() => {

                helpIcon.style.display = 'unset'





            }, 400);


        }, 10000);



    }, []);





    useEffect(() => {

        const currentSeed = globalNetwork.getSeed()

        // console.log(currentSeed)

        globalNetwork.on('stabilizationIterationsDone', (data) => {

            setGraphDone(true)

            if (productViewOpen) {
                globalNetwork.moveTo({
                    position: { x: !below600px ? -3192 : -3600, y: 11.54 },
                    scale: 0.56245
                })

            }

            else {
                globalNetwork.fit()
            }


        })


        globalNetwork.on('selectNode', (data) => {

            // const selectedNode = nodes.get(data.nodes[0])
            // const selecteddNodeGroup = selectedNode.group


            // if (selecteddNodeGroup === 'signals' && currentScale <= signalThreshold) {
            //     return
            // }


            // const connections = globalNetwork.getConnectedNodes(data.nodes)

            // for (const node of connections) {

            //     switch (nodes.get(node).group) {
            //         case 'shifts':
            //             nodes.update({ id: node, color: { background: nodeColorShiftSelected } })
            //             break;
            //         case 'drivers':
            //             nodes.update({ id: node, color: { background: nodeColorDriverSelected } })
            //             break;
            //         case 'signals':
            //             nodes.update({ id: node, color: { background: nodeColorSignalSelected } })
            //             break;
            //         case 'needs':
            //             nodes.update({ id: node, color: { background: nodeColorNeedSelected } })
            //             break;
            //         case 'opportunities':
            //             nodes.update({ id: node, color: { background: nodeColorOpportunitySelected } })
            //             break;



            //     }


            // }
        })


        globalNetwork.on('deselectNode', (data) => {




            const connections = globalNetwork.getConnectedNodes(data.previousSelection.nodes[0].id)
            // console.log('DEselect connections are ' + connections)


            // for (const node of connections) {

            //     switch (nodes.get(node).group) {
            //         case 'shifts':
            //             nodes.update({ id: node, color: { background: nodeColorShiftUnselected } })
            //             break;
            //         case 'drivers':
            //             nodes.update({ id: node, color: { background: nodeColorDriverUnselected } })
            //             break;
            //         case 'signals':
            //             nodes.update({ id: node, color: { background: nodeColorSignalUnselected } })
            //             break;
            //         case 'needs':
            //             nodes.update({ id: node, color: { background: nodeColorNeedUnselected } })
            //             break;
            //         case 'opportunities':
            //             nodes.update({ id: node, color: { background: nodeColorOpportunityUnselected } })
            //             break;



            //     }

            // }
        })

        globalNetwork.on('dragStart', (data) => {

            //if any popup is open and user starts dragging, close all popups
            if (signalPopupOpen || driverPopupOpen || needPopupOpen || oppPopupOpen) {

                closeAllPopups()
            }

            // const allIDs = nodes.getIds()
            // var nodesToDeactivate = undefined

            // //if drag pointing to a node
            // if (data.nodes[0] !== undefined) {
            //     const connections = globalNetwork.getConnectedNodes(data.nodes)
            //     nodesToDeactivate = allIDs.filter(n => !connections.includes(n))
            // }

            // //if drag is not pointing to a node but the canvas, we want the SELECTED node along with its connections to remain highlighted
            // else {

            //     const selectedNode = globalNetwork.getSelectedNodes()
            //     const selectedNodeConnections = globalNetwork.getConnectedNodes(selectedNode)
            //     const resultArray = selectedNodeConnections.push(selectedNode)
            //     nodesToDeactivate = allIDs.filter(n => !resultArray.includes(n))

            // }


            // for (const node of connections) {

            //     switch (nodes.get(node).group) {
            //         case 'shifts':
            //             nodes.update({ id: node, color: { background: nodeColorShiftSelected } })
            //             break;
            //         case 'drivers':
            //             nodes.update({ id: node, color: { background: nodeColorDriverSelected } })
            //             break;
            //         case 'signals':
            //             nodes.update({ id: node, color: { background: nodeColorSignalSelected } })
            //             break;
            //         case 'needs':
            //             nodes.update({ id: node, color: { background: nodeColorNeedSelected } })
            //             break;
            //         case 'opportunities':
            //             nodes.update({ id: node, color: { background: nodeColorOpportunitySelected } })
            //             break;



            //     }

            // }

            // for (const node of nodesToDeactivate) {
            //     switch (nodes.get(node).group) {
            //         case 'shifts':
            //             nodes.update({ id: node, color: { background: nodeColorShiftUnselected } })
            //             break;
            //         case 'drivers':
            //             nodes.update({ id: node, color: { background: nodeColorDriverUnselected } })
            //             break;
            //         case 'signals':
            //             nodes.update({ id: node, color: { background: nodeColorSignalUnselected } })
            //             break;
            //         case 'needs':
            //             nodes.update({ id: node, color: { background: nodeColorNeedUnselected } })
            //             break;
            //         case 'opportunities':
            //             nodes.update({ id: node, color: { background: nodeColorOppsUnselected } })
            //             break;



            //     }
            // }

            //BEN FRIDAY VERSION START

            // const allIDs = nodes.getIds()
            // var nodesToActivate = []
            // var nodesToDeactivate = []
            // var signalsToKeepUnhidden = []


            // //if drag pointing to a node
            // if (data.nodes[0] !== undefined) {
            //     const connections = globalNetwork.getConnectedNodes(data.nodes)
            //     nodesToDeactivate = allIDs.filter(n => !connections.includes(n))

            //     nodesToActivate = connections

            // }

            // //if drag is not pointing to a node but the canvas, we want all SELECTED nodes to remain highlighted
            // else {


            //     // const selectedNode = globalNetwork.getSelectedNodes()
            //     // const selectedNodeConnections = globalNetwork.getConnectedNodes(selectedNode)
            //     // const resultArray = selectedNodeConnections.push(selectedNode)

            //     nodesToDeactivate = allIDs.filter(n => !clickSelection.includes(n))

            //     // and we make sure we keep 'signals' unhidden if they are part of a current click selection

            //     clickSelection.map((clickedNode) => {
            //         const clickedNodeInfo = nodes.get(clickedNode)

            //         if (clickedNodeInfo.group === 'signals') {
            //             const updateObject = { id: clickedNodeInfo.id, hidden: false }
            //             signalsToKeepUnhidden.push(updateObject)
            //         }

            //     })

            // }

            // //signalsToKeepUnhidden now has objects // nodeToDeactivate just IDs at this point

            // var nodesToDeactiveObjects = []
            // var updateObject = undefined

            // for (const node of nodesToDeactivate) {
            //     switch (nodes.get(node).group) {
            //         case 'shifts':
            //             updateObject = { id: node, color: { background: nodeColorShiftUnselected } }
            //             nodesToDeactiveObjects.push(updateObject)
            //             break;
            //         case 'drivers':
            //             updateObject = { id: node, color: { background: nodeColorDriverUnselected } }
            //             nodesToDeactiveObjects.push(updateObject)
            //             break;
            //         case 'signals':
            //             updateObject = { id: node, color: { background: nodeColorSignalUnselected } }
            //             nodesToDeactiveObjects.push(updateObject)
            //             break;
            //         case 'heroSignals':
            //             updateObject = { id: node, color: { background: nodeColorSignalUnselected } }
            //             nodesToDeactiveObjects.push(updateObject)
            //             break;
            //         case 'needs':
            //             updateObject = { id: node, color: { background: nodeColorNeedUnselected } }
            //             nodesToDeactiveObjects.push(updateObject)
            //             break;
            //         case 'opportunities':
            //             updateObject = { id: node, color: { background: nodeColorOppsUnselected } }
            //             nodesToDeactiveObjects.push(updateObject)
            //             break;



            //     }
            // }

            // var nodesToActiveObjects = []
            // var updateObject2 = undefined

            // for (const node of nodesToActivate) {
            //     switch (nodes.get(node).group) {
            //         case 'shifts':
            //             updateObject2 = { id: node, color: { background: nodeColorShiftSelected } }
            //             nodesToActiveObjects.push(updateObject)
            //             break;
            //         case 'drivers':
            //             updateObject2 = { id: node, color: { background: nodeColorDriverSelected } }
            //             nodesToActiveObjects.push(updateObject)
            //             break;
            //         case 'signals':
            //             updateObject2 = { id: node, hidden: false, color: { background: nodeColorSignalSelected } }
            //             nodesToActiveObjects.push(updateObject)
            //             break;
            //         case 'heroSignals':
            //             updateObject2 = { id: node, hidden: false, color: { background: nodeColorSignalSelected } }
            //             nodesToActiveObjects.push(updateObject)
            //             break;
            //         case 'needs':
            //             updateObject2 = { id: node, color: { background: nodeColorNeedSelected } }
            //             nodesToActiveObjects.push(updateObject)
            //             break;
            //         case 'opportunities':
            //             updateObject2 = { id: node, color: { background: nodeColorOppsSelected } }
            //             nodesToActiveObjects.push(updateObject)
            //             break;



            //     }
            // }



            // const allObjectsToUpdate = [...signalsToKeepUnhidden, ...nodesToDeactiveObjects, ...nodesToActiveObjects]
            // nodes.update(allObjectsToUpdate)


            //BEN FRIDAY VERSION END










        })


        globalNetwork.on('dragEnd', (data) => {

            lastZoomPosition = globalNetwork.getViewPosition()


            // console.log('drag ended!')

            // console.log(globalNetwork.getScale())
            // console.log(globalNetwork.getViewPosition())



            // const connections = globalNetwork.getConnectedNodes(data.nodes)
            // console.log('DEselect connections are ' + connections)


            // for (const node of connections) {

            //     switch (nodes.get(node).group) {
            //         case 'shifts':
            //             nodes.update({ id: node, color: { background: nodeColorShiftUnselected } })
            //             break;
            //         case 'drivers':
            //             nodes.update({ id: node, color: { background: nodeColorDriverUnselected } })
            //             break;
            //         case 'signals':
            //             nodes.update({ id: node, color: { background: nodeColorSignalUnselected } })
            //             break;
            //         case 'needs':
            //             nodes.update({ id: node, color: { background: nodeColorNeedUnselected } })
            //             break;
            //         case 'opportunities':
            //             nodes.update({ id: node, color: { background: nodeColorOpportunityUnselected } })
            //             break;



            //     }

            // }

            // globalNetwork.unselectAll()

        })


        globalNetwork.on('click', (data) => {


            //provides ID as number
            const clickedNodeID = data.nodes[0]

            //provides object, containing id/group/label/color
            const clickedNodeInfo = nodes.get(clickedNodeID)

            //provides IDs of all clicked node's connections
            const clickedNodeConnections = globalNetwork.getConnectedNodes(clickedNodeID)

            //if user clicks on a node ------------------------------------------------------------------------------------------
            if (clickedNodeID !== undefined) {

                //if user clicks on any node that is NOT a signal (or hero signal)
                if (clickedNodeInfo.group !== 'signals' && clickedNodeInfo.group !== 'heroSignals') {

                    //excluding the instance where the right side bar ('focus view') is active AND user clicks on a driver (we don't want the selection to switch in that case, but we want the previous shift selection stay intact)
                    if (!(clickedNodeInfo.group === 'drivers' && sidebarRightOpen)) {

                        //we set both itself and its connections as selection
                        var allNodesToSelect = clickedNodeConnections
                        allNodesToSelect.push(clickedNodeID)
                        globalNetwork.selectNodes(allNodesToSelect)

                        //and we unhide connected nodes in case they are 'signals' 
                        var signalsToUnhide = []

                        clickedNodeConnections.map((connection) => {
                            const connectionInfo = nodes.get(connection)

                            if (connectionInfo.group === 'signals') {
                                const updateObject = { id: connectionInfo.id, hidden: false }
                                signalsToUnhide.push(updateObject)
                            }


                        })

                        nodes.update(signalsToUnhide)


                        //We empty and put the IDs of both the clicked node and its connections into the clickSelection array
                        clickSelection = []
                        clickSelection = globalNetwork.getSelectedNodes()
                        // clickSelection.push(clickedNodeID)
                        // clickedNodeConnections.map((connection) => {
                        //     clickSelection.push(connection)
                        // })

                    }

                    //instance where focus view is active and user clicks on a driver (see above)
                    else {
                        var allNodesToSelect = clickSelection
                        allNodesToSelect.push(clickedNodeID)
                        globalNetwork.selectNodes(allNodesToSelect)
                    }

                    //if user is clicking on shift, we zoom closer and show right side panel
                    if (clickedNodeInfo.group === 'shifts' || clickedNodeInfo.group === 'shifts2') {

                        //close any popups 
                        closeAllPopups()

                        //we zoom on clicked shift node (different value based on whether we are in insights vs implications view)
                        const zoomScale = !productViewOpen ? 0.0003 * (window.innerWidth + window.innerHeight) : 0.0004 * (window.innerWidth + window.innerHeight)

                        //higer value = left; lower value = right
                        const xOffset = window.innerWidth * 0.2

                        //higer value = up; lower value = down
                        const yOffset = !productViewOpen ? (window.innerHeight * 0.005) : (window.innerHeight * 0.3)

                        globalNetwork.focus(data.nodes, {
                            scale: zoomScale,
                            offset: { x: -xOffset, y: -yOffset },
                            locked: false,
                            animation: {
                                duration: 2000,
                                easingFunction: "easeOutQuad"
                            },

                        })

                        //defining Notion item containing all info we need
                        const notionInfo = masterArray[clickedNodeID - 1]

                        //we show product opp nodes
                        // showOpps()



                        //if sidebarRight is not showing, we show it and update its info
                        if (!sidebarRightOpen) {

                            //open right sidebar
                            toggleSidebarRight(notionInfo, '')

                            setTimeout(() => {
                                sidebarRightOpen = true

                            }, 200);

                        }

                        //if sidebarRight is already showing, we only update its info
                        else {
                            updateSidebarRightData(notionInfo)

                        }

                        //reposition vis nav buttons
                        if (productViewOpen) { repositionNavButtons('focus') }

                    }
                }

                //if user is clicking on a signal, heroSignal, driver, need, or product opp, we open popups
                if (clickedNodeInfo.group === 'signals' || clickedNodeInfo.group === 'heroSignals' || clickedNodeInfo.group === 'drivers' || clickedNodeInfo.group === 'needs' || clickedNodeInfo.group === 'opportunities') {

                    const popupSignal = document.getElementById('popup-signal')
                    const popupDriver = document.getElementById('popup-driver')
                    const popupNeed = document.getElementById('popup-need')
                    const popupOpp = document.getElementById('popup-opp')



                    const currentScale = globalNetwork.getScale()
                    const nodeX = data.pointer.DOM.x
                    const nodeY = data.pointer.DOM.y
                    var popupX = undefined
                    var popupY = undefined
                    const popupWidth = 0.9 * 273

                    //if no side bar right is open (hence full width available)
                    if (!sidebarRightOpen) {

                        const bufferX = currentScale * 35

                        //if clicking on the left half of the screen
                        if (nodeX < (window.visualViewport.width / 2)) {
                            popupX = (nodeX + bufferX) + 'px'
                            // popupY = nodeY + 'px'

                            //(mobile) and position is too close to the middle, so that the popup leaves the right end of vw...
                            if (below600px && nodeX + popupWidth > (window.visualViewport.width - 20)) {
                                popupX = (window.visualViewport.width - popupWidth - 10) + 'px'


                            }
                        }

                        //if clicking on the right half of the screen
                        else {
                            //subtracting the px width of the popup plus the buffer
                            popupX = (nodeX - popupWidth - bufferX) + 'px'
                            // popupY = nodeY + 'px'

                            //(mobile) and position is too close to the middle, so that the popup leaves the left end of vw...
                            if (below600px && nodeX - popupWidth <= 20) {
                                popupX = '10px'
                            }
                        }
                    }

                    //if right side bar open, hence less width available
                    else {
                        const bufferX = currentScale * 50

                        if (nodeX < ((window.visualViewport.width - 533) / 2)) {
                            popupX = (nodeX + bufferX) + 'px'
                            // popupY = nodeY + 'px'
                        }

                        else {
                            //subtracting the px width of the popup plus the buffer
                            popupX = (nodeX - popupWidth - bufferX) + 'px'
                            // popupY = nodeY + 'px'
                        }
                    }







                    //setting (corrected) Y position 

                    var popupHeight = undefined

                    if (clickedNodeInfo.group === 'signals' || clickedNodeInfo.group === 'opportunities') {

                        //cf css sheet for current height settings
                        popupHeight = 0.9 * 234


                    }

                    else if (clickedNodeInfo.group === 'needs' || clickedNodeInfo.group === 'drivers') {

                        //cf css sheet for current height settings
                        popupHeight = 0.9 * 163

                    }

                    //now checking how close to the bottom we are
                    if (nodeY < (window.visualViewport.height - popupHeight)) {
                        popupY = nodeY + 'px'
                    }

                    else {
                        popupY = (window.visualViewport.height - popupHeight - 2) + 'px'
                    }


                    //defining Notion item containing all info we need
                    const notionInfo = masterArray[clickedNodeID - 1]

                    //findings its relations, creating an array of relations, each with an object containing an 'id' property
                    const relations = notionInfo.properties['Related content'].relation

                    //map through all relations, find their Notion item in the masterArray, figure out if it's a 'Shift', and if so, add it into the 'parentShifts' array

                    var parentShifts = []

                    relations.map((rel) => {

                        //getting the id of the relation (= a Notion page ID)
                        const relationID = rel.id

                        //now we need to find in the notionData array the page with said ID
                        const relatedItem = masterArray.find(({ id }) => id === relationID);

                        //get type of related item
                        const relatedItemType = relatedItem && relatedItem.properties.Type.select.name

                        if (relatedItemType === 'Shift') {
                            parentShifts.push(relatedItem)
                        }

                        else if (relatedItemType === 'Need' && clickedNodeInfo.group !== 'drivers' && clickedNodeInfo.group !== 'signals') {
                            const needRelations = relatedItem && relatedItem.properties['Related content'].relation
                            needRelations.map((needRel) => {
                                const needRelationID = needRel.id
                                const relatedItemNeed = masterArray.find(({ id }) => id === needRelationID);
                                const relatedItemNeedType = relatedItemNeed && relatedItemNeed.properties.Type.select.name
                                if (relatedItemNeedType === 'Shift') {
                                    parentShifts.push(relatedItemNeed)
                                }
                            })

                        }

                    })

                    //open popups
                    if (clickedNodeInfo.group === 'signals' || clickedNodeInfo.group === 'heroSignals') {

                        globalNetwork.selectNodes(clickSelection)

                        popupSignal.style.left = popupX
                        popupSignal.style.top = popupY

                        popupSignal.style.display = 'flex'
                        setTimeout(() => {
                            signalPopupOpen = true
                        }, 200);

                        popupDriver.style.display = 'none'
                        setTimeout(() => {
                            driverPopupOpen = false
                        }, 200);

                        updatePopupContent('signal', notionInfo, parentShifts)
                    }

                    else if (clickedNodeInfo.group === 'drivers') {
                        popupDriver.style.left = popupX
                        popupDriver.style.top = popupY

                        popupDriver.style.display = 'flex'
                        setTimeout(() => {
                            driverPopupOpen = true
                        }, 200);

                        popupSignal.style.display = 'none'
                        setTimeout(() => {
                            signalPopupOpen = false
                        }, 200);

                        updatePopupContent('driver', notionInfo, parentShifts)


                    }

                    else if (clickedNodeInfo.group === 'opportunities') {

                        globalNetwork.selectNodes(clickSelection)

                        popupOpp.style.left = popupX
                        popupOpp.style.top = popupY

                        popupOpp.style.display = 'flex'
                        setTimeout(() => {
                            oppPopupOpen = true
                        }, 200);

                        popupNeed.style.display = 'none'
                        setTimeout(() => {
                            needPopupOpen = false
                        }, 200);

                        updatePopupContent('opp', notionInfo, parentShifts)
                    }

                    else if (clickedNodeInfo.group === 'needs') {
                        popupNeed.style.left = popupX
                        popupNeed.style.top = popupY

                        popupNeed.style.display = 'flex'
                        setTimeout(() => {
                            needPopupOpen = true
                        }, 200);

                        popupOpp.style.display = 'none'
                        setTimeout(() => {
                            oppPopupOpen = false
                        }, 200);

                        updatePopupContent('need', notionInfo, parentShifts)


                    }

                    lastPopupParentShiftLocal = parentShifts[0]


                }



            }

            //if user clicks into empty canvas ------------------------------------------------------------------------------------------
            else {

                //empty clickSelection array
                clickSelection = []

                //hide all signals
                hideSignals()

                //close tutorial (if open) 
                closeTutorial()


                //if focus view is active, zoom back to overview
                if (sidebarRightOpen) {

                    if (!productViewOpen) {
                        globalNetwork.fit({
                            animation: {
                                duration: 2000,
                                easingFunction: "easeInQuad"
                            },
                        })



                    }

                    else {

                        globalNetwork.moveTo({
                            scale: 0.56245,
                            animation: {
                                duration: 2000,
                                easingFunction: "easeInQuad"
                            },
                        })

                    }

                    toggleSidebarRight('override', '')
                    setTimeout(() => {
                        sidebarRightOpen = false

                    }, 200);

                    //we hide product opp nodes
                    // hideOpps()

                    //reposition vis nav buttons
                    repositionNavButtons('')

                }

                //if any popup is open and user clicks on empty canvas, close all popups
                if (signalPopupOpen || driverPopupOpen || needPopupOpen || oppPopupOpen) {

                    closeAllPopups()
                }
            }



        })

        globalNetwork.on('hoverNode', (data) => {

            const hoveredNodeID = data.node
            const hoveredNodeConnections = globalNetwork.getConnectedNodes(hoveredNodeID)

            // const position = globalNetwork.getPosition(hoveredNodeID)
            // console.log(position)

            //we select all connections of the hovered node, AND we keep the current click-selection intact
            const allNodesToSelect = [...hoveredNodeConnections, ...clickSelection]
            globalNetwork.selectNodes(allNodesToSelect)

            //we also unhide connected nodes in case they are 'signals' 
            var signalsToUnhide = []

            hoveredNodeConnections.map((connection) => {
                const connectionInfo = nodes.get(connection)

                if (connectionInfo.group === 'signals') {
                    const updateObject = { id: connectionInfo.id, hidden: false }
                    signalsToUnhide.push(updateObject)
                }

            })

            //and we make sure we keep 'signals' unhidden if they are part of a current click selection
            var signalsToKeepUnhidden = []

            clickSelection.map((clickedNode) => {
                const clickedNodeInfo = nodes.get(clickedNode)

                if (clickedNodeInfo.group === 'signals') {
                    const updateObject = { id: clickedNodeInfo.id, hidden: false }
                    signalsToKeepUnhidden.push(updateObject)
                }

            })

            const allSignalsToUnhide = [...signalsToUnhide, ...signalsToKeepUnhidden]
            nodes.update(allSignalsToUnhide)








            //     //the hovered node, its connections, and its group
            //     const hoveredNode = nodes.get(data.node)
            //     const hoveredNodeConnections = globalNetwork.getConnectedNodes(data.node)
            //     const hoveredNodeGroup = hoveredNode.group

            //     const selectedNode = globalNetwork.getSelectedNodes()


            //     //array to fill with all to-be-updated node objects
            //     var nodesToUpdate = []

            //     //going through each of the hovered node's connections
            //     hoveredNodeConnections.map((connection) => {

            //         //object to define for each updated node
            //         var updatedNodeObject = undefined

            //         //going through various cases of node groups, setting updated colors and/or 'hidden' states for each
            //         switch (nodes.get(connection).group) {
            //             case 'shifts':
            //                 updatedNodeObject = { id: connection, color: { background: nodeColorShiftSelected } }
            //                 nodesToUpdate.push(updatedNodeObject)
            //                 break;

            //             case 'drivers':
            //                 updatedNodeObject = { id: connection, color: { background: nodeColorDriverSelected } }
            //                 nodesToUpdate.push(updatedNodeObject)
            //                 break;

            //             case 'signals':

            //                 if (hoveredNodeGroup === 'shifts') {

            //                     updatedNodeObject = { id: connection, hidden: false }
            //                 }
            //                 else if (hoveredNodeGroup === 'drivers') {

            //                     updatedNodeObject = { id: connection, opacity: 1, hidden: false }

            //                 }
            //                 else {
            //                     return
            //                 }
            //                 nodesToUpdate.push(updatedNodeObject)
            //                 break;

            //             case 'heroSignals':

            //                 if (hoveredNodeGroup === 'drivers') {

            //                     updatedNodeObject = { id: connection, opacity: 1 }

            //                 }
            //                 else {
            //                     return
            //                 }
            //                 nodesToUpdate.push(updatedNodeObject)
            //                 break;

            //             case 'needs':
            //                 updatedNodeObject = { id: connection, color: { background: nodeColorNeedSelected } }
            //                 nodesToUpdate.push(updatedNodeObject)
            //                 break;

            //             case 'opportunities':
            //                 updatedNodeObject = { id: connection, color: { background: nodeColorOpportunitySelected } }
            //                 nodesToUpdate.push(updatedNodeObject)
            //                 break;

            //         }

            //     })

            //     nodes.update(nodesToUpdate)


            //     // if (hoveredNodeGroup === 'signals' && currentScale <= signalThreshold) {
            //     //     return
            //     // }

            //     //opening signal/ driver hovers if user hovers over node – DEACTIVATED FOR NOW
            //     // if ((selectedNode.group === 'signals' || selectedNode.group === 'drivers') && !signalPopupOpen && !driverPopupOpen) {

            //     //     const hoverSignal = document.getElementById('hover-signal')
            //     //     const hoverDriver = document.getElementById('hover-driver')

            //     //     const currentScale = globalNetwork.getScale()
            //     //     const nodeX = data.pointer.DOM.x
            //     //     const nodeY = data.pointer.DOM.y
            //     //     var popupX = undefined
            //     //     var popupY = undefined
            //     //     const popupWidth = 140

            //     //     if (!focusViewActive) {

            //     //         const buffer = currentScale * 35

            //     //         if (nodeX < (window.visualViewport.width / 2)) {
            //     //             popupX = (nodeX + buffer) + 'px'
            //     //             popupY = nodeY + 'px'
            //     //         }

            //     //         else {
            //     //             //subtracting the px width of the popup plus the buffer
            //     //             popupX = (nodeX - popupWidth - buffer) + 'px'
            //     //             popupY = nodeY + 'px'
            //     //         }
            //     //     }

            //     //     else {
            //     //         const buffer = currentScale * 50

            //     //         if (nodeX < ((window.visualViewport.width - 533) / 2)) {
            //     //             popupX = (nodeX + buffer) + 'px'
            //     //             popupY = nodeY + 'px'
            //     //         }

            //     //         else {
            //     //             //subtracting the px width of the popup plus the buffer
            //     //             popupX = (nodeX - popupWidth - buffer) + 'px'
            //     //             popupY = nodeY + 'px'
            //     //         }
            //     //     }

            //     //     if (selectedNode.group === 'signals') {

            //     //         hoverSignal.style.left = popupX
            //     //         hoverSignal.style.top = popupY

            //     //         globalLeft = popupX
            //     //         globalTop = popupY

            //     //         hoverSignal.style.display = 'flex'
            //     //         setTimeout(() => {
            //     //             signalHoverOpen = true
            //     //         }, 200);

            //     //         hoverDriver.style.display = 'none'
            //     //         setTimeout(() => {
            //     //             driverHoverOpen = false
            //     //         }, 200);
            //     //     }

            //     //     else {
            //     //         hoverDriver.style.left = popupX
            //     //         hoverDriver.style.top = popupY

            //     //         globalLeft = popupX
            //     //         globalTop = popupY

            //     //         hoverDriver.style.display = 'flex'
            //     //         setTimeout(() => {
            //     //             driverHoverOpen = true
            //     //         }, 200);

            //     //         hoverSignal.style.display = 'none'
            //     //         setTimeout(() => {
            //     //             signalHoverOpen = false
            //     //         }, 200);
            //     //     }







            //     // }

        })

        globalNetwork.on('blurNode', (data) => {

            //setting cursor to default
            globalNetwork.canvas.body.container.style.cursor = 'default'

            //defining useful variables
            const leftNodeID = data.node
            const leftNodeConnections = globalNetwork.getConnectedNodes(leftNodeID)

            //if no set of nodes is currently active because of a click, we are good to dim all nodes (and hide them if they are 'signals')
            if (clickSelection[0] === undefined) {


                //unselect all nodes
                globalNetwork.unselectAll()

                //hide all nodes in case they are 'signals' 
                var signalsToHide = []

                const allSignalNodes = nodes.get({
                    fields: ["id", "group"],
                    filter: item => item.group === 'signals'
                });

                allSignalNodes.map((signal) => {

                    const updateObject = { id: signal.id, hidden: true }
                    signalsToHide.push(updateObject)


                })

                nodes.update(signalsToHide)

            }

            //else, there is a set of currently active nodes, so we need to make sure they remain activated (and stay unhidden if they are 'signals')
            else {



                //select nodes that are active by click
                globalNetwork.selectNodes(clickSelection)

                //unhide selected nodes in case they are 'signals' 
                var signalsToUnhide = []
                var signalsToUnhideIDs = []

                clickSelection.map((clickedNode) => {
                    const clickedNodeInfo = nodes.get(clickedNode)

                    if (clickedNodeInfo.group === 'signals') {
                        const updateObject = { id: clickedNodeInfo.id, hidden: false }

                        signalsToUnhide.push(updateObject)
                        signalsToUnhideIDs.push(clickedNodeInfo.id)
                    }

                })

                // nodes.update(signalsToUnhide)

                //AND hide all signals that are not part of the click selection
                const allSignalNodes = nodes.get({
                    fields: ["id", "group"],
                    filter: item => item.group === 'signals'
                });

                var allSignalNodesIDs = []

                allSignalNodes.map((signal) => {
                    const signalID = signal.id
                    allSignalNodesIDs.push(signalID)
                })



                const allSignalsMinusActive = allSignalNodesIDs.filter(n => !signalsToUnhideIDs.includes(n))

                var signalsToHide = []


                allSignalsMinusActive.map((signal) => {

                    const updateObject = { id: signal, hidden: true }
                    signalsToHide.push(updateObject)


                })

                const allSignalsToChange = [...signalsToUnhide, ...signalsToHide]
                nodes.update(allSignalsToChange)


            }


            // //terminate if the left-behind node is also selected at this moment (because we don't want those to get 'turn off')
            // const selectedNode = globalNetwork.getSelectedNodes()
            // if (selectedNode == data.node) {
            //     return
            // }


            // //also, terminate if the left-behind node is a connection of a connection of the currently selected node...

            // //...get all connections of the currently selected node
            // const selectedNodeConnections = globalNetwork.getConnectedNodes(selectedNode)
            // var allSecondConnectionsOfSelectedNode = []

            // //...go through all these connections and get their connections, and add those to a big array
            // for (const connection of selectedNodeConnections) {
            //     const connectionsConnections = globalNetwork.getConnectedNodes(connection)

            //     for (const connetionConnection of connectionsConnections) {
            //         allSecondConnectionsOfSelectedNode.push(connetionConnection)

            //     }

            // }

            // //...now, see if the left-behind node is listed in the array we just filled
            // if (allSecondConnectionsOfSelectedNode.includes(data.node) === true) {

            //     //...in that case, we cannot just terminate, but still need to 'turn off' the connected nodes of the left-behind node (all the ones that are NOT also a connection of the selected node)

            //     //all connections of left-behind node (the one that is a 2nd order connection of the selected node)
            //     const connections = globalNetwork.getConnectedNodes(data.node)

            //     //that minus the connections of the selected node
            //     const selectedNodeConnections = globalNetwork.getConnectedNodes(selectedNode)
            //     const connectionsToTurnOff = connections.filter(n => !selectedNodeConnections.includes(n))


            //     //now 'turning these off'
            //     for (const node of connectionsToTurnOff) {

            //         switch (nodes.get(node).group) {
            //             case 'shifts':
            //                 nodes.update({ id: node, color: { background: nodeColorShiftUnselected } })
            //                 break;
            //             case 'drivers':
            //                 nodes.update({ id: node, color: { background: nodeColorDriverUnselected } })
            //                 break;
            //             case 'signals':
            //                 nodes.update({ id: node, color: { background: nodeColorSignalUnselected } })
            //                 break;
            //             case 'needs':
            //                 nodes.update({ id: node, color: { background: nodeColorNeedUnselected } })
            //                 break;
            //             case 'opportunities':
            //                 nodes.update({ id: node, color: { background: nodeColorOpportunityUnselected } })
            //                 break;



            //         }



            //     }

            //     return

            // }


            // //regular functionality
            // const connections = globalNetwork.getConnectedNodes(data.node)

            // for (const node of connections) {

            //     switch (nodes.get(node).group) {
            //         case 'shifts':
            //             nodes.update({ id: node, color: { background: nodeColorShiftUnselected } })
            //             break;
            //         case 'drivers':
            //             nodes.update({ id: node, color: { background: nodeColorDriverUnselected } })
            //             break;
            //         case 'signals':
            //             nodes.update({ id: node, color: { background: nodeColorSignalUnselected } })
            //             break;
            //         case 'needs':
            //             nodes.update({ id: node, color: { background: nodeColorNeedUnselected } })
            //             break;
            //         case 'opportunities':
            //             nodes.update({ id: node, color: { background: nodeColorOpportunityUnselected } })
            //             break;



            //     }



            // }

            // // if (signalHoverOpen || driverHoverOpen) {

            // //     closeAllHovers()

            // // }

        })

        globalNetwork.on('zoom', (data) => {

            currentScale = data.scale

            // console.log(currentScale)


            //global zoom limits

            if (currentScale <= 0.15) {
                globalNetwork.moveTo({
                    position: lastZoomPosition,
                    scale: 0.15
                })
            }

            else if (currentScale >= 2.4) {
                globalNetwork.moveTo({
                    position: lastZoomPosition,
                    scale: 2.4
                })
            }

            else {
                lastZoomPosition = globalNetwork.getViewPosition()

            }

            //if user zooms, close any open popup
            if (signalPopupOpen || driverPopupOpen || oppPopupOpen || needPopupOpen) {

                closeAllPopups()

            }



            if (currentScale > oppsThreshold && oppsStatusChanged === false) {

                // showOpps()


            }

            else if (currentScale <= oppsThreshold && oppsStatusChanged === true) {

                // hideOpps()
            }


            //view Position may be useful to create popup as part of the simulation, but parking that attempt for now
            // const viewPosition = globalNetwork.getViewPosition()
            // console.log('view Position: ' + JSON.stringify(viewPosition))

            // if (selectedNodeID) {
            //     const selectedNodePosition = globalNetwork.getPosition(selectedNodeID)
            //     const thePopup = document.getElementById('the-popup')

            //     const newLeft = selectedNodePosition.x * data.scale 
            //     const newRight = selectedNodePosition.y * data.scale



            //     // console.log(selectedNodePosition.x)



            //     thePopup.style.left = newLeft + 'px'
            //     thePopup.style.top = newRight + 'px'


            // }



        })




    }, [globalNetwork]);


    //Rendering content

    return (


        <div className="page-main" id="page-main">

            {/* <div className="hover-signal" id="hover-signal">
                <div className="hover-description">I am a full description of the signal and this is my text box.</div>
                <div className="hover-image-placeholder">Image/video</div>
                <div className="popup-bottomRow-close"></div>

            </div>

            <div className="hover-driver" id="hover-driver">
                <div className="hover-description">I am a full description of the driver and this is my text box.</div>
                <div className="hover-image-placeholder">Image/video</div>
                <div className="popup-bottomRow-close"></div>

            </div> */}

            <>
                {/* <div className="graph-legend-insights" id="graph-legend-insights">
                    <div className="graph-legend-row">
                        <div className="graph-legend-node1" id="graph-legend-node1"></div>
                        <div className="graph-legend-node1-txt" id="graph-legend-node1-txt"><strong>Shift</strong></div>
                    </div>
                    <div className="graph-legend-row">
                        <div className="graph-legend-node2" id="graph-legend-node2"></div>
                        <div className="graph-legend-node2-txt" id="graph-legend-node2-txt"><strong>Driver</strong></div>


                    </div>
                    <div className="graph-legend-row">
                        <div className="graph-legend-node3" id="graph-legend-node3"></div>
                        <div className="graph-legend-node3-txt" id="graph-legend-node3-txt"><strong>Signal</strong></div>


                    </div>
                </div> */}

                {/* <div className="graph-legend-implications" id="graph-legend-implications">
                        <div className="graph-legend-row">
                            <div className="graph-legend-node1" id="graph-legend-node1"></div>
                            <div className="graph-legend-node1-txt" id="graph-legend-node1-txt"><strong>Shift</strong></div>
                        </div>
                        <div className="graph-legend-row">
                            <div className="graph-legend-node2" id="graph-legend-node2"></div>
                            <div className="graph-legend-node2-txt" id="graph-legend-node2-txt"><strong>Need</strong></div>


                        </div>
                        <div className="graph-legend-row">
                            <div className="graph-legend-node3" id="graph-legend-node3"></div>
                            <div className="graph-legend-node3-txt" id="graph-legend-node3-txt"><strong>Opportunity</strong></div>


                        </div>
                    </div> */}
            </>



            <>


                <div className="graph" ref={visJsRef} />


            </>



        </div >


    );
})

const update = (prevProps, nextProps) => {
    if (prevProps.shouldUpdate === false) {
        return true //no re-render
    }
    return false //re render
}

export default React.memo(Main, update)


