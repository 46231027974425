import Plx from "react-plx";
import { useState } from "react";

const SlideShow = ({ id, slidesNo, showHideDuration, switchDuration, slideFocusDuration, shrinkTo, children }) => {


    //Variables for dynamic sizes or positions -----------------------------------------------------------------

    const [viewportHeight, setViewportHeight] = useState(window.visualViewport.height * 1)

    window.addEventListener("resize", () => {

        setViewportHeight(window.visualViewport.height)

    });

    //Calculating container width based on number of slides
    const containerWidth = slidesNo * window.visualViewport.width + 'px'

    //Calculating no of animation steps ---------------------------------

    //...number of 'from to' movements
    const fromToAnimations = slidesNo - 1

    //...number of 'grow in/ out' movements
    const growAnimations = slidesNo * 2 - 2

    //...total number of animations between container show/hide
    const betweenAnimations = fromToAnimations + growAnimations

    //Calculating duration until final hiding of container
    const durationBeforeFinalHide = betweenAnimations * switchDuration

    //Total animation duration (to be used for spacer div)
    const totalDuration = viewportHeight * (durationBeforeFinalHide + 2 * showHideDuration + slidesNo * slideFocusDuration) + 'px'

    //Animation settings -----------------------------------------------------------------

    const slideContainer = [

        //slideContainer opacity switching to 1
        {
            start: `#${id}`,
            duration: `${viewportHeight * showHideDuration}px`,
            properties: [
                {
                    startValue: 0,
                    endValue: 1,
                    property: 'opacity',
                },
                {
                    startValue: 0,
                    endValue: 100,
                    property: "zIndex",
                },
            ]

        },
        {
            start: `#${id}`,
            startOffset: `${viewportHeight * (durationBeforeFinalHide + showHideDuration + slidesNo * slideFocusDuration)}px`,
            duration: `${viewportHeight * showHideDuration}px`,
            properties: [
                {
                    startValue: 1,
                    endValue: 0,
                    property: 'opacity',
                },
                {
                    startValue: 100,
                    endValue: 0,
                    property: "zIndex",
                },
            ]

        },

    ]


    var slideShowDynamic = []

    for (let i = 1; i <= slidesNo; i++) {

        if (i === 1) {

            const newObject =
            //slideShow shrinking (away from FIRST Slide)
            {
                start: `#${id}`,
                startOffset: `${viewportHeight * (showHideDuration + slideFocusDuration)}px`,
                duration: `${viewportHeight * switchDuration}px`,
                properties: [
                    // {
                    //     startValue: 100 * slidesNo,
                    //     endValue: 100 * slidesNo * shrinkTo,
                    //     property: "width",
                    //     unit: 'vw'
                    // },
                    // {
                    //     startValue: 100,
                    //     endValue: 100 * shrinkTo,
                    //     property: "height",
                    //     unit: 'vh'
                    // },
                    // {
                    //     startValue: 0,
                    //     endValue: (100 - 100 * shrinkTo),
                    //     property: "marginLeft",
                    //     unit: 'vh'
                    // },
                    {
                        startValue: 1,
                        endValue: shrinkTo + (1 - shrinkTo) / slidesNo,
                        property: "scaleX",
                    },
                    {
                        startValue: 1,
                        endValue: shrinkTo,
                        property: "scaleY",
                    },
                ]

            }

            slideShowDynamic.push(newObject)

        }

        else if (i === slidesNo) {

            const newObject =
            //slideShow growing (into LAST Slide)
            {
                start: `#${id}`,
                startOffset: `${viewportHeight * (3 * slidesNo * switchDuration - 4 * switchDuration + showHideDuration + (slidesNo - 1) * slideFocusDuration)}px`,
                duration: `${viewportHeight * switchDuration}px`,
                properties: [
                    // {
                    //     startValue: 100 * slidesNo * shrinkTo,
                    //     endValue: 100 * slidesNo,
                    //     property: "width",
                    //     unit: 'vw'
                    // },
                    // {
                    //     startValue: 100 * shrinkTo,
                    //     endValue: 100,
                    //     property: "height",
                    //     unit: 'vh'
                    // },
                    // {
                    //     startValue: (100 - 100 * shrinkTo),
                    //     endValue: 0,
                    //     property: "marginLeft",
                    //     unit: 'vh'
                    // },
                    {
                        startValue: shrinkTo + (1 - shrinkTo) / slidesNo,
                        endValue: 1,
                        property: "scaleX",
                    },
                    {
                        startValue: shrinkTo,
                        endValue: 1,
                        property: "scaleY",
                    },
                ]

            }

            slideShowDynamic.push(newObject)
        }

        else {


            const newObject01 =
            //slideShow growing (into Slide i)

            {
                start: `#${id}`,
                startOffset: `${viewportHeight * (i * 3 * switchDuration - 4 * switchDuration + showHideDuration + (i - 1) * slideFocusDuration)}px`,
                duration: `${viewportHeight * switchDuration}px`,
                properties: [
                    // {
                    //     startValue: 100 * slidesNo * shrinkTo,
                    //     endValue: 100 * slidesNo,
                    //     property: "width",
                    //     unit: 'vw'
                    // },
                    // {
                    //     startValue: 100 * shrinkTo,
                    //     endValue: 100,
                    //     property: "height",
                    //     unit: 'vh'
                    // },
                    // {
                    //     startValue: (100 - 100 * shrinkTo),
                    //     endValue: 0,
                    //     property: "marginLeft",
                    //     unit: 'vh'
                    // },
                    {
                        startValue: shrinkTo + (1 - shrinkTo) / slidesNo,
                        endValue: 1,
                        property: "scaleX",
                    },
                    {
                        startValue: shrinkTo,
                        endValue: 1,
                        property: "scaleY",
                    },
                ]

            }

            const newObject02 =
            //slideShow shrinking (away from Slide i)
            {
                start: `#${id}`,
                startOffset: `${viewportHeight * (i * 3 * switchDuration - 3 * switchDuration + showHideDuration + (i) * slideFocusDuration)}px`,
                duration: `${viewportHeight * switchDuration}px`,
                properties: [
                    // {
                    //     startValue: 100 * slidesNo,
                    //     endValue: 100 * slidesNo * shrinkTo,
                    //     property: "width",
                    //     unit: 'vw'
                    // },
                    // {
                    //     startValue: 100,
                    //     endValue: 100 * shrinkTo,
                    //     property: "height",
                    //     unit: 'vh'
                    // },
                    // {
                    //     startValue: 0,
                    //     endValue: (100 - 100 * shrinkTo),
                    //     property: "marginLeft",
                    //     unit: 'vh'
                    // },
                    {
                        startValue: 1,
                        endValue: shrinkTo + (1 - shrinkTo) / slidesNo,
                        property: "scaleX",
                    },
                    {
                        startValue: 1,
                        endValue: shrinkTo,
                        property: "scaleY",
                    },
                ]

            }

            slideShowDynamic.push(newObject01)
            slideShowDynamic.push(newObject02)



        }

    }


    //Calculate % by which we need to move left with every slide switch. Depends on total amount of slides. E.g. when 4 slides, need to move by 25%.
    const leftMovingPercentage = -100 / slidesNo

    var allSlidesDynamic = []

    for (let i = 1; i <= slidesNo; i++) {


        if (i !== 1) {

            const newObject =
            {
                start: `#${id}`,
                startOffset: `${viewportHeight * (i * 3 * switchDuration - 5 * switchDuration + showHideDuration + (i - 1) * slideFocusDuration)}px`,
                duration: `${viewportHeight * switchDuration}px`,
                properties: [
                    {
                        startValue: leftMovingPercentage * (i - 2),
                        endValue: leftMovingPercentage * (i - 1),
                        property: "left",
                        unit: '%'
                    },

                ]

            }

            allSlidesDynamic.push(newObject)
        }

    }



    return (
        <>
            <div id={id} />

            <Plx className="slideContainer" id="slideContainer" parallaxData={slideContainer} style={{ width: `${containerWidth}` }}>

                <Plx className="slideShow" id="slideShow" parallaxData={slideShowDynamic} >

                    <Plx className="allSlides" id="allSlides" parallaxData={allSlidesDynamic}>

                        {children}

                    </Plx>
                </Plx>


            </Plx>

            <div style={{ height: `${totalDuration}` }} />

        </>
    );
}

export default SlideShow;