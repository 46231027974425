import React from "react";
import { useEffect } from "react";
import Plx from "react-plx";
import { useState } from "react";
import { network } from "vis-network";

var neonLight = require('./media/reels_Stick.png')
var neonLove = require('./media/reels_Love.png')
var drip = require('./media/drip.png')


const VideoReels = ({ sizzle, setGraphDone, setSidebarRightOpen, setNavbarBG, navbarScrollBehavior, mobileDevice, networkPage }) => {

    useEffect(() => {

        setGraphDone(false)

        setSidebarRightOpen(false)

        setNavbarBG(!mobileDevice ? false : true)
        navbarScrollBehavior.current = !mobileDevice ? false : true



        document.getElementById('bgVideo01').style.display = 'unset'
        document.getElementById('bgVideo02').style.display = 'unset'

        networkPage.current = false

    }, []);

    var currentScaleFromWidth = 1
    var currentScaleFromHeight = 1
    var lastViewPortWidth = undefined
    var lastViewPortHeight = undefined

    const [widthGrowing, setWidthGrowing] = useState(undefined)
    const [heightGrowing, setHeightGrowing] = useState(undefined)


    //Experiment: a container that is always 16:9 format, growing/shrinking with viewport size. Feels like a decent solution, at least for all situations where vw > vh.

    // window.addEventListener("resize", () => {

    //     const viewPortWidth = window.visualViewport.width
    //     const viewPortHeight = window.visualViewport.height

    //     if (viewPortWidth > lastViewPortWidth) {
    //         setWidthGrowing(true)
    //     }
    //     else if (viewPortWidth < lastViewPortWidth) {
    //         setWidthGrowing(false)
    //     }
    //     if (viewPortHeight > lastViewPortHeight) {
    //         setHeightGrowing(true)
    //     }
    //     else if (viewPortHeight < lastViewPortHeight) {
    //         setHeightGrowing(false)
    //     }


    //     const scaleDiv = document.getElementById('someDivText')

    //     if (viewPortWidth < 1700) {

    //         const newScale = (viewPortWidth - 100) / 1600

    //         // const newLeft = (viewPortWidth - 1600)/2
    //         // const newTop = (viewPortHeight - 900)/2

    //         if ((newScale < currentScaleFromHeight) || (newScale > currentScaleFromHeight && widthGrowing)) {
    //             scaleDiv.style.transform = `scale(${newScale})`
    //             currentScaleFromWidth = newScale

    //         }

    //         // scaleDiv.style.left = `${newLeft}px`
    //         // scaleDiv.style.top = `${newTop}px`


    //         // scaleDiv.style.transform = `scale(${newScale})`
    //         // scaleDiv.style['-o-transform'] = `scale(${newScale})`
    //         // scaleDiv.style['-webkit-transform'] = `scale(${newScale})`
    //         // scaleDiv.style['-moz-transform'] = `scale(${newScale})`

    //     }

    //     if (viewPortHeight < 1000) {

    //         const newScale = (viewPortHeight - 100) / 900

    //         if ((newScale < currentScaleFromWidth) || (newScale > currentScaleFromWidth && heightGrowing)) {

    //             scaleDiv.style.transform = `scale(${newScale})`
    //             currentScaleFromHeight = newScale

    //         }

    //     }

    //     lastViewPortWidth = viewPortWidth
    //     lastViewPortHeight = viewPortHeight

    // });


    const stickingSth = [
        {
            start: 'self',
            startOffset: '50vh',
            duration: '30vh',
            easing: 'linear',
            properties: [
                {
                    startValue: 0,
                    endValue: 30,
                    property: "translateY",
                    unit: 'vh'
                },

            ]

        },

    ];


    return (
        <>
            {sizzle &&
                <div className="page-videoReels" id="page-videoReels">
                    <div className="pageContent">

                        <div className="page-videoReels-neonLight">
                            <img src={neonLight} style={{ maxWidth: '120%', maxHeight: '120%', rotate: '-30deg', position: 'absolute', top: '0%', right: '-25   %' }} />
                        </div>
                        {/* <div className="page-videoReels-neonLove">
                    <img src={neonLove} style={{ maxWidth: '170%', maxHeight: '170%', position: 'absolute', top: '-21%', left: '-35%' }} />
                </div> */}

                        <div className="page-videoReels-header">
                            <strong>{sizzle && sizzle[1].header}</strong>
                        </div>

                        {sizzle &&
                            <p>{sizzle[1].text1}<strong style={{ color: '#FFE31E' }}>3 sizzle reels</strong> {sizzle[1].text2}<a href='./network'>Network</a> and <a href='./productOpp'>Product Inspiration</a> pages.</p>
                        }

                        <div className="page-videoReels-3videos">

                            <div className="x" style={{ width: '30%', display: 'flex', flexDirection: 'column' }}>
                                <iframe src='https://player.vimeo.com/video/741976840?h=07dce6147c' height='100%' frameBorder='0' allow='autoplay; fullscreen' allowFullScreen />
                                <div className="marketStudies-mediaCaption">Sizzle Reel 1 <br /><br />pw: {sizzle && sizzle[0].password1}</div>
                            </div>

                            <div className="x" style={{ width: '30%', display: 'flex', flexDirection: 'column' }}>
                                <iframe src='https://player.vimeo.com/video/749297056?h=fd1d26686f' height='100%' frameBorder='0' allow='autoplay; fullscreen' allowFullScreen />
                                <div className="marketStudies-mediaCaption">Sizzle Reel 2 <br /><br />pw: {sizzle && sizzle[0].password2}</div>

                            </div>

                            <div className="x" style={{ width: '30%', display: 'flex', flexDirection: 'column' }}>
                                <iframe src='https://player.vimeo.com/video/759530003?h=20a5f98f86' height='100%' frameBorder='0' allow='autoplay; fullscreen' allowFullScreen />
                                <div className="marketStudies-mediaCaption">Sizzle Reel 3 <br /><br />pw: {sizzle && sizzle[0].password3}</div>

                            </div>







                        </div>

                        <div className="spacer" style={{ marginBottom: '20vh' }}></div>


                        {/* <p>Video Reels</p>

                <p>Text</p>
                <p>Text</p>
                <p>Text</p>
                <p>Text</p>
                <p>Text</p>
                <p>Text</p>
                <p>Text</p>
                <p>Text</p>
                <p>Text</p>
                <p>Text</p>
                <p>Text</p>
                <p>Text</p>

                <Plx parallaxData={stickingSth} style={{marginBottom: '30vh'}}>
                    <p><strong style={{ color: 'white' }}>Text</strong></p>
                </Plx>
                <p>Text</p>
                <p>Text</p>
                <p>Text</p>
                <p>Text</p>
                <p>Text</p>
                <p>Text</p>
                <p>Text</p>
                <p>Text</p>
                <p>Text</p>
                <p>Text</p>
                <p>Text</p>
                <p>Text</p>
                <p>Text</p>
                <p>Text</p>
                <p>Text</p>
                <p>Text</p>

                <Plx parallaxData={stickingSth} style={{marginBottom: '30vh'}}>
                    <p><strong style={{ color: 'white' }}>Text</strong></p>
                </Plx>
                <p>Text</p>
                <p>Text</p>
                <p>Text</p>
                <p>Text</p>
                <p>Text</p>
                <p>Text</p>
                <p>Text</p>
                <p>Text</p>
                <p>Text</p>
                <p>Text</p>
                <p>Text</p>
                <p>Text</p>
                <p>Text</p>
                <p>Text</p>
                <p>Text</p>
                <p>Text</p> */}







                        {/* <div className="someDivContainer">

                    <div className="someDivText" id="someDivText">
                        I have a lot of text to fit. I have a lot of text to fit. I have a lot of text to fit. I have a lot of text to fit. I have a lot of text to fit. I have a lot of text to fit. I have a lot of text to fit. I have a lot of text to fit. Yes that is right
                    </div>

                </div> */}


                    </div>
                </div>
            }
        </>


    );
}

export default VideoReels;