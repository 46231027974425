import Plx from "react-plx";

const StickyTextMoving = ({ id, direction, topPosition, children }) => {


    const stickyText = direction === 'leftRight' ?
        [
            {
                start: `#${id}`,
                duration: '200vh',
                properties: [
                    {
                        startValue: 0,
                        endValue: 210,
                        unit: 'vw',
                        property: 'translateX',
                    },

                ],
            },

            {
                start: `#${id}`,
                duration: '10vh',
                properties: [
                    {
                        startValue: 0,
                        endValue: 1,
                        property: 'opacity',
                    },

                ],
            },

            {
                start: `#${id}`,
                startOffset: '190vh',
                duration: '10vh',
                properties: [
                    {
                        startValue: 1,
                        endValue: 0,
                        property: 'opacity',
                    },

                ],
            },

        ]
        :
        [
            {
                start: `#${id}`,
                duration: '200vh',
                properties: [
                    {
                        startValue: 0,
                        endValue: -210,
                        unit: 'vw',
                        property: 'translateX',
                    },

                ],
            },
            {
                start: `#${id}`,
                duration: '10vh',
                properties: [
                    {
                        startValue: 0,
                        endValue: 1,
                        property: 'opacity',
                    },

                ],
            },

            {
                start: `#${id}`,
                startOffset: '190vh',
                duration: '10vh',
                properties: [
                    {
                        startValue: 1,
                        endValue: 0,
                        property: 'opacity',
                    },

                ],
            },

        ]


    return (
        <>
            <div className="stickyMovingTrigger" id={id} />

            <Plx className={direction === 'leftRight' ? 'stickyTextMovingLeftRight' : 'stickyTextMovingRightLeft'} parallaxData={stickyText} style={{top: `${topPosition}%`}}>
                {children}
            </Plx>

        </>
    );
}

export default StickyTextMoving;