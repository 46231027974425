import React from "react";
import { useEffect } from "react";
import { useState } from "react"


var initialMode = ''

if (window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)').matches) {
  initialMode = 'dark'
}

else {
  initialMode = 'light'
}

const SidebarRight = ({ sidebarRightData, allData, toggleSidebarRight, productView, below600px, stopOtherVideos, makeID }) => {

  //TOUCH etc

  let touchstartX = 0
  let touchendX = 0

  const thresholdX = !below600px ? (window.visualViewport.width - 533) : (window.visualViewport.width - window.visualViewport.width * 0.83)


  document.addEventListener('touchstart', e => {
    touchstartX = e.changedTouches[0].screenX
  })

  document.addEventListener('touchend', e => {
    touchendX = e.changedTouches[0].screenX
    checkDirection()

  })

  // hides sidebar when swiping right, starting from inside it
  function checkDirection() {

    if (touchendX > touchstartX && (touchendX - touchstartX > 100) && touchstartX > thresholdX) {

      toggleSidebarRight('override')

    }
  }


  //SIMPLE PROPERTIES (TITLE, TEXT, IMAGES ETC.) ----------------------------------------------------------
  var image = ''

  const mediaSource = sidebarRightData && sidebarRightData.properties['Photo/ Video'].files[0] && sidebarRightData.properties['Photo/ Video'].files[0].type

  //defining the media url based on whether it's external vs file
  if (mediaSource === 'external') {
    image = sidebarRightData.properties['Photo/ Video'].files[0].external.url
  }

  else if (mediaSource === 'file') {
    image = sidebarRightData.properties['Photo/ Video'].files[0].file.url

  }

  else {
    image = ''
  }

  var localImage = undefined

  if (image !== undefined && image !== '' && !image.startsWith('http')) {

    localImage = require(`${image}`)
  }






  const titleArray = sidebarRightData && sidebarRightData.properties.Name.title
  var rawTitle = ''
  var contentTitle = ''
  var titleSplit = ''
  var titleWithoutType = ''
  {
    titleArray &&
      titleArray.map((textPiece) => (rawTitle += textPiece.text.content))
    contentTitle = rawTitle.replace(/\r?\n|\r/g, '')
    titleSplit = contentTitle.split(': ')
    titleWithoutType = titleSplit[1]
  }



  const descriptionArray = sidebarRightData && sidebarRightData.properties.Description && sidebarRightData.properties.Description.rich_text
  var rawDescription = ''
  var contentDescription = ''
  {
    descriptionArray &&
      descriptionArray.map((textPiece) => (rawDescription += textPiece.text.content))
    contentDescription = rawDescription.replace(/\r?\n|\r/g, '')
  }

  const emergingNeedsArray = sidebarRightData && sidebarRightData.properties['(Shift) Emerging Needs Overview'] && sidebarRightData.properties['(Shift) Emerging Needs Overview'].rich_text
  var rawEmeergingNeeds = ''
  var contentEmergingNeeds = ''
  {
    emergingNeedsArray &&
      emergingNeedsArray.map((textPiece) => (rawEmeergingNeeds += textPiece.text.content))
    contentEmergingNeeds = rawEmeergingNeeds.replace(/\r?\n|\r/g, '')
  }

  const fromArray = sidebarRightData && sidebarRightData.properties['(Shift) From:'] && sidebarRightData.properties['(Shift) From:'].rich_text
  var rawFrom = ''
  var contentFrom = ''
  {
    fromArray &&
      fromArray.map((textPiece) => (rawFrom += textPiece.text.content))
    contentFrom = rawFrom.replace(/\r?\n|\r/g, '')
  }

  const toArray = sidebarRightData && sidebarRightData.properties['(Shift) To:'] && sidebarRightData.properties['(Shift) To:'].rich_text
  var rawTo = ''
  var contentTo = ''
  {
    toArray &&
      toArray.map((textPiece) => (rawTo += textPiece.text.content))
    contentTo = rawTo.replace(/\r?\n|\r/g, '')
  }


  //RELATIONS ----------------------------------------------------------

  //creates an array of relations, each with an object containing an 'id' property
  const relations = sidebarRightData && sidebarRightData.properties['Related content'].relation

  //...
  var driversRelatedToShift = []
  var needsRelatedToShift = []

  //now we need to map through that relations array
  relations && relations.map((rel) => {

    //getting the id of the relation (= a Notion page ID)
    const relationID = rel.id

    //now we need to find in the notionData array the page with said ID
    const relatedItem = allData.find(({ id }) => id === relationID);

    //get type of related item
    const relatedItemType = relatedItem && relatedItem.properties.Type.select.name

    if (relatedItemType === 'Need') {
      needsRelatedToShift.push(relatedItem)
    }

    else if (relatedItemType === 'Driver') {
      driversRelatedToShift.push(relatedItem)
    }

  })

  var needsCounter = 0
  var driversCounter = 0

  useEffect(() => {

    if (sidebarRightData === undefined) {
      image = ''
    }

    // console.log('sidebarRightData changed, image is now: ' + image)


  }, [sidebarRightData])

  return (

    <div className="sidebarRight" id="sidebarRight">
      <div className="sidebarRight-container">
        {!below600px &&
          <span className="sidebarRight-closebutton" onClick={() => toggleSidebarRight('override', '')}>&times;</span>

        }
        {localImage === undefined &&
          <img src={image} alt="shiftImage" className="sidebarRight-shiftImage" id="sidebarRight-shiftImage" />
        }

        {localImage !== undefined &&
          <img src={localImage} alt="shiftImage" className="sidebarRight-shiftImage" id="sidebarRight-shiftImage" />
        }

        <div className="sidebarRight-content">


          <>


            <h2>{titleWithoutType}</h2>
            <div className="sidebarRight-fromToContainer">
              <div className="sidebarRight-shiftRow">
                <div className="sidebarRight-shiftRowLeft">
                  <h3>FROM</h3>
                  <div className="sidebarRight-fromToLine">
                    <div className="sidebarRight-fromToDot"></div>
                  </div>
                  {/* <img src={fromTo} alt="fromTo" className="sidebarRight-fromToSVG" /> */}
                </div>
                <div className="sidebarRight-shiftRowRight">
                  {contentFrom}


                </div>

              </div>

              <div className="sidebarRight-shiftRow">
                <div className="sidebarRight-shiftRowLeft">
                  <h3>TO</h3>
                </div>
                <div className="sidebarRight-shiftRowRight">
                  {contentTo}
                </div>

              </div>

            </div>
            <div className="sidebarRight-description">
              <h3>
                {!productView &&
                  <>
                    WHAT'S THIS ALL ABOUT?
                  </>
                }
                {productView &&
                  <>
                    USER NEEDS AT A GLANCE
                  </>
                }
              </h3>
              {!productView && contentDescription}
              {productView && rawEmeergingNeeds}
            </div>


            {!productView &&
              <>
                <h3>DRIVERS &amp; EXAMPLES</h3>

                {
                  driversRelatedToShift &&

                  driversRelatedToShift.map((driver) => {

                    const titleArray = driver.properties.Name.title
                    var rawTitle = ''
                    var cleanTitle = ''
                    var titleNoLabel = ''
                    var titleWithNo = ''
                    driversCounter += 1

                    {
                      titleArray &&
                        titleArray.map((textPiece) => (rawTitle += textPiece.text.content))
                      cleanTitle = rawTitle.replace(/\r?\n|\r/g, '')
                      titleNoLabel = cleanTitle.split(': ')[1]
                      titleWithNo = 'Driver ' + driversCounter + ': ' + titleNoLabel


                    }



                    const descriptionArray = driver.properties.Description && driver.properties.Description.rich_text
                    var rawDescription = ''
                    var contentDescription = ''
                    {
                      descriptionArray &&
                        descriptionArray.map((textPiece) => (rawDescription += textPiece.text.content))
                      contentDescription = rawDescription.replace(/\r?\n|\r/g, '')
                    }


                    return (

                      <>
                        <div className="sidebarRight-whiteBox" id={driver.id}>

                          <h4>{titleWithNo}</h4>
                          {contentDescription}
                          <div className="sidebarRight-boxLine">
                            <div className="sidebarRight-boxLineDot"></div>
                          </div>
                          <h4 style={{ marginBottom: '15px' }}>Supporting examples</h4>


                          {

                            (driver.properties['Related content'].relation).map((driverRelation) => {

                              //getting the id of the relation (= a Notion page ID)
                              const relationID = driverRelation.id

                              //now we need to find in the notionData array the page with said ID
                              const relatedItem = allData.find(({ id }) => id === relationID);

                              //get type of related item
                              const relatedItemType = relatedItem.properties.Type.select.name

                              if (relatedItemType === 'Signal') {

                                const titleArray = relatedItem.properties.Name.title
                                var rawTitle = ''
                                var cleanTitle = ''
                                var titleNoLabel = ''
                                var titleWithNo = ''

                                {
                                  titleArray &&
                                    titleArray.map((textPiece) => (rawTitle += textPiece.text.content))
                                  cleanTitle = rawTitle.replace(/\r?\n|\r/g, '')
                                  titleNoLabel = cleanTitle.split(': ')[1]

                                }



                                const descriptionArray = relatedItem.properties.Description && relatedItem.properties.Description.rich_text
                                var rawDescription = ''
                                var contentDescription = ''
                                {
                                  descriptionArray &&
                                    descriptionArray.map((textPiece) => (rawDescription += textPiece.text.content))
                                  contentDescription = rawDescription.replace(/\r?\n|\r/g, '')
                                }


                                const mediaFilesArray = relatedItem.properties['Photo/ Video'].files[0] && relatedItem.properties['Photo/ Video'].files

                                // var image = ''
                                // const mediaSource = relatedItem.properties['Cover image'].files[0] && relatedItem.properties['Cover image'].files[0].type

                                // //defining the media url based on whether it's external vs file
                                // if (mediaSource === 'external') {
                                //   image = relatedItem.properties['Cover image'].files[0].external.url
                                // }

                                // else if (mediaSource === 'file') {
                                //   image = relatedItem.properties['Cover image'].files[0].file.url

                                // }


                                return (
                                  <>
                                    <div className="sidebarRight-whiteBox-2Columns" id={relatedItem.id}>

                                      <div className="sidebarRight-whiteBox-2Columns-text">
                                        <h5>{titleNoLabel}</h5>
                                        {contentDescription}
                                      </div>


                                      {mediaFilesArray &&

                                        mediaFilesArray.map((file) => {

                                          const mediaSource = file.type
                                          var mediaURL = ''

                                          //defining the media url based on whether it's external vs file
                                          if (mediaSource === 'external') {
                                            mediaURL = file.external.url
                                          }

                                          else if (mediaSource === 'file') {
                                            mediaURL = file.file.url

                                          }

                                          //figuring out if it's a photo or video
                                          const fileNameSplit = file.name.split('.')
                                          const fileEnding = fileNameSplit[1]

                                          if (fileEnding === 'mp4' || fileEnding === 'MP4') {

                                            if (mediaURL.startsWith('http')) {

                                              const newID = makeID(16)

                                              return (
                                                <video controls playsInline src={mediaURL} onClick={() => stopOtherVideos(newID)} id={"popupVideoPlayer" + newID} alt="signalVideo" className="sidebarRight-whiteBox-2Columns-image" key={file.name} style={{ maxWidth: '100%', maxHeight: '100%' }} />
                                              )

                                            }

                                            else {

                                              const localFile = require(`${mediaURL}`)
                                              const newID = makeID(16)


                                              return (
                                                <video controls src={localFile} onClick={() => stopOtherVideos(newID)} id={"popupVideoPlayer" + newID} alt="signalVideo" className="sidebarRight-whiteBox-2Columns-image" key={file.name} style={{ maxWidth: '100%', maxHeight: '100%' }} />


                                              )

                                            }

                                          }

                                          else {

                                            if (mediaURL.startsWith('http')) {



                                              return (

                                                <img src={mediaURL} loading="lazy" alt="signalImage" className="sidebarRight-whiteBox-2Columns-image" key={file.name} style={{ maxWidth: '100%', maxHeight: '100%' }} />


                                              )

                                            }

                                            else {
                                              const localFile = require(`${mediaURL}`)
                                              return (
                                                <img src={localFile} loading="lazy" alt="signalImage" className="sidebarRight-whiteBox-2Columns-image" key={file.name} style={{ maxWidth: '100%', maxHeight: '100%' }} />

                                              )
                                            }

                                          }



                                        })
                                      }

                                      {/* <img src={image} className="sidebarRight-whiteBox-2Columns-image" alt="signalImage" /> */}

                                    </div>
                                  </>
                                )

                              }



                            })


                          }

                        </div>
                      </>
                    )

                  })
                }



              </>
            }
            {productView &&
              <>
                <h3>NEEDS &amp; PRODUCT IMPLICATIONS</h3>

                {
                  needsRelatedToShift &&

                  needsRelatedToShift.map((need) => {

                    const titleArray = need.properties.Name.title
                    var rawTitle = ''
                    var cleanTitle = ''
                    var titleNoLabel = ''
                    var titleWithNo = ''
                    needsCounter += 1

                    {
                      titleArray &&
                        titleArray.map((textPiece) => (rawTitle += textPiece.text.content))
                      cleanTitle = rawTitle.replace(/\r?\n|\r/g, '')
                      titleNoLabel = cleanTitle.split(': ')[1]
                      titleWithNo = 'Need ' + needsCounter + ': ' + titleNoLabel


                    }



                    const descriptionArray = need.properties.Description && need.properties.Description.rich_text
                    var rawDescription = ''
                    var contentDescription = ''
                    {
                      descriptionArray &&
                        descriptionArray.map((textPiece) => (rawDescription += textPiece.text.content))
                      contentDescription = rawDescription.replace(/\r?\n|\r/g, '')
                    }


                    return (

                      <>
                        <div className="sidebarRight-whiteBox" id={need.id}>

                          <h4>{titleWithNo}</h4>
                          {contentDescription}
                          <div className="sidebarRight-boxLine">
                            <div className="sidebarRight-boxLineDot"></div>
                          </div>
                          <h4 style={{ marginBottom: '15px' }}>Product implications</h4>


                          {

                            (need.properties['Related content'].relation).map((needRelation) => {

                              //getting the id of the relation (= a Notion page ID)
                              const relationID = needRelation.id

                              //now we need to find in the notionData array the page with said ID
                              const relatedItem = allData.find(({ id }) => id === relationID);

                              //get type of related item
                              const relatedItemType = relatedItem.properties.Type.select.name

                              if (relatedItemType === 'Opportunity Area') {

                                const titleArray = relatedItem.properties.Name.title
                                var rawTitle = ''
                                var cleanTitle = ''
                                var titleNoLabel = ''
                                var titleWithNo = ''

                                {
                                  titleArray &&
                                    titleArray.map((textPiece) => (rawTitle += textPiece.text.content))
                                  cleanTitle = rawTitle.replace(/\r?\n|\r/g, '')
                                  titleNoLabel = cleanTitle.split(': ')[1]

                                }



                                const descriptionArray = relatedItem.properties.Description && relatedItem.properties.Description.rich_text
                                var rawDescription = ''
                                var contentDescription = ''
                                {
                                  descriptionArray &&
                                    descriptionArray.map((textPiece) => (rawDescription += textPiece.text.content))
                                  contentDescription = rawDescription.replace(/\r?\n|\r/g, '')
                                }

                                const mediaFilesArray = relatedItem.properties['Photo/ Video'].files[0] && relatedItem.properties['Photo/ Video'].files

                                // var image = ''

                                // const mediaSource = relatedItem.properties['Cover image'].files[0] && relatedItem.properties['Cover image'].files[0].type

                                // //defining the media url based on whether it's external vs file
                                // if (mediaSource === 'external') {
                                //   image = relatedItem.properties['Cover image'].files[0].external.url
                                // }

                                // else if (mediaSource === 'file') {
                                //   image = relatedItem.properties['Cover image'].files[0].file.url

                                // }


                                return (
                                  <>
                                    <div className="sidebarRight-whiteBox-2Columns" id={relatedItem.id}>

                                      <div className="sidebarRight-whiteBox-2Columns-text">
                                        <h4>{titleNoLabel}</h4>
                                        {contentDescription}
                                      </div>

                                      {mediaFilesArray &&

                                        mediaFilesArray.map((file) => {

                                          const mediaSource = file.type
                                          var mediaURL = ''

                                          //defining the media url based on whether it's external vs file
                                          if (mediaSource === 'external') {
                                            mediaURL = file.external.url
                                          }

                                          else if (mediaSource === 'file') {
                                            mediaURL = file.file.url

                                          }

                                          //figuring out if it's a photo or video
                                          const fileNameSplit = file.name.split('.')
                                          const fileEnding = fileNameSplit[1]

                                          if (fileEnding === 'mp4' || fileEnding === 'MP4') {

                                            if (mediaURL.startsWith('http')) {
                                              const newID = makeID(16)


                                              return (


                                                <video controls src={mediaURL} onClick={() => stopOtherVideos(newID)} id={"popupVideoPlayer" + newID} alt="oppVideo" className="sidebarRight-whiteBox-2Columns-image" key={file.name} />

                                              )

                                            }

                                            else {
                                              const localFile = require(`${mediaURL}`)
                                              const newID = makeID(16)

                                              return (

                                                <video controls src={localFile} onClick={() => stopOtherVideos(newID)} id={"popupVideoPlayer" + newID} alt="oppVideo" className="sidebarRight-whiteBox-2Columns-image" key={file.name} />
                                              )

                                            }

                                          }

                                          else {

                                            if (mediaURL.startsWith('http')) {

                                              return (

                                                <img src={mediaURL} loading="lazy" alt="oppImage" className="sidebarRight-whiteBox-2Columns-image" key={file.name} />

                                              )

                                            }

                                            else {
                                              const localFile = require(`${mediaURL}`)
                                              return (
                                                <img src={localFile} loading="lazy" alt="oppImage" className="sidebarRight-whiteBox-2Columns-image" key={file.name} />

                                              )

                                            }

                                          }



                                        })
                                      }

                                      {/* <img src={image} className="sidebarRight-whiteBox-2Columns-image" alt="oppImage" /> */}

                                    </div>
                                  </>
                                )

                              }



                            })


                          }

                        </div>
                      </>
                    )

                  })
                }




              </>
            }


          </>



        </div>


      </div>
    </div>

  );
}

export default SidebarRight;