import React from 'react';
import { AuthContextProvider } from './authContext';
import NoLogin from './NoLogin';
import Main from './Main';
import { useState, useRef } from 'react';
import { useEffect } from 'react';
import AuthContext from "./authContext";
import netlifyIdentity from 'netlify-identity-widget'
import Navbar from './Navbar';
import SidebarLeft from './SidebarLeft';
import SidebarRight from './SidebarRight';
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Introduction from './Introduction';
import ProductOpportunities from './ProductOpportunities';
import MarketDeepDives from './MarketDeepDives';
import VideoReels from './VideoReels';
import Credits from './Credits';

import closeButton from './media/close.svg';
import minimizeButton from './media/minimize.svg';
import maximizeInactiveButton from './media/maximizeInactive.svg';
import maximizeButton from './media/maximize.svg';
import popupSignal from './media/popupSignal.svg';
import popupDriver from './media/popupDriver.svg';
import popupNeed from './media/popupNeed.svg';
import popupOpp from './media/popupOpp.svg';

import loadingIcon from './media/loading.svg';

import sandClock from './media/sandClock.gif'

import arrowCursor from './media/arrowCursor.svg'
import magnifier from './media/magnifier.svg'
import drag from './media/drag.png'
import smallNode from './media/smallNode.png'
import bigNode from './media/bigNode.png'

import useFetch from './useFetch';
import useFetchPost from './useFetchPost';


// var gradientVideo = require('./media/gradient.mp4')
// var gradientVideo02 = require('./media/gradient02.mp4')

import gradientVideo from './media/gradient.mp4';
import gradientVideo02 from './media/gradient02.mp4';

import helpIcon from './media/help.svg';
import Animations from './Animations';


//

function App() {

  const [navbarBG, setNavbarBG] = useState(undefined)
  const [showViewToggle, setShowViewToggle] = useState(false)
  const [graphDone, setGraphDone] = useState(false)
  const childRef = useRef()
  const childRef02 = useRef()
  const [productView, setProductView] = useState(false)

  const [user, setUser] = useState(null)
  const [access, setAccess] = useState(false)
  const [error, setError] = useState(null)

  //Oct 18
  // const [appLevelMasterArray, setAppLevelMasterArray] = useState(null)
  // const appLevelMasterArray = notionDataNetwork

  const [token, setToken] = useState(undefined)

  const body = JSON.stringify({
    userEmail: user && user.email
  })

  const fetchURL = 'api/get-notion-data-network'
  const { data: appLevelMasterArray } = useFetchPost(fetchURL, token, body)
  const { data: networkNodesAll } = useFetchPost('api/get-network-nodes-all', token, body)
  const { data: networkNodesIns } = useFetchPost('api/get-network-nodes-ins', token, body)
  const { data: networkNodesOpp } = useFetchPost('api/get-network-nodes-opp', token, body)
  const { data: notionDataMarket } = useFetchPost('api/get-notion-data-market', token, body)
  const { data: sizzle } = useFetchPost('api/get-sizzle', token, body)
  const { data: credits } = useFetchPost('api/get-credits', token, body)
  const { data: market } = useFetchPost('api/get-market', token, body)
  const { data: intro } = useFetchPost('api/get-intro', token, body)








  const [appHasFetched, setAppHasFetched] = useState(null)
  // useFetch('/api/fetchNotion?site=network&secret=ZqQBumxrt7UHeqL2x3A11QBTxdBneT3Q', setAppLevelMasterArray, setAppHasFetched, setShowViewToggle)

  const networkPage = useRef(null)

  //



  //Scrolling & navbar
  var lastScrollTop = window.scrollY
  const navbarScrollBehavior = useRef(undefined)

  window.addEventListener('scroll', () => {
    const newScrollTop = window.scrollY

    //"has scrolled down"
    if (newScrollTop > lastScrollTop && navbarScrollBehavior.current === true) {
      document.getElementById('navbar').style.top = '-64px'
    }

    //"has scrolled up"
    else if (newScrollTop < lastScrollTop && navbarScrollBehavior.current === true) {
      document.getElementById('navbar').style.top = '0px'
    }
    lastScrollTop = newScrollTop <= 0 ? 0 : newScrollTop; // For Mobile or negative scrolling

  })


  //Actions for vis navigation buttons
  const rightButton = Array.from(document.getElementsByClassName('vis-button vis-right'))[0]
  const leftButton = Array.from(document.getElementsByClassName('vis-button vis-left'))[0]

  rightButton && rightButton.addEventListener('mousedown', function () {
    childRef.current.closeAllPopups()
  })

  leftButton && leftButton.addEventListener('mousedown', function () {
    childRef.current.closeAllPopups()

  })

  rightButton && rightButton.addEventListener('mouseup', function () {

    const position = childRef.current.getViewPosition()

    if (position.x > 3617) {
      rightButton.style.display = 'none'

    }

    if (position.x > -3947) {
      leftButton.style.display = 'unset'

    }

  })

  leftButton && leftButton.addEventListener('click', function () {

  })

  leftButton && leftButton.addEventListener('mouseup', function () {

    const position = childRef.current.getViewPosition()

    if (position.x < 3617) {
      rightButton.style.display = 'unset'

    }

    if (position.x < -3947) {
      leftButton.style.display = 'none'

    }

  })



  function toggleViewMode() {


    // const app = document.getElementById('app')
    // const pageMain = document.getElementById('page-main')
    // const expText = document.getElementById('navbar-expText')
    // const prodText = document.getElementById('navbar-prodText')
    // const logoutText = document.getElementById('navbar-logoutText')
    // const burgerIcon = document.getElementById('burger-icon')
    // const bgVideo = document.getElementById('bgVideo01')

    //Product view
    if (!productView) {

      // bgVideo.style.zIndex = '0'

      childRef.current.showProductNodes()

      // childRef.current.setLegendToImplications()


      // childRef02.current.changeLogoutIcon('implications')

      // app.style.backgroundColor = '#D1C1D4'
      // expText.style.color = '#7e7480'
      // prodText.style.color = 'black'
      // logoutText.style.color = '#7e7480'
      // burgerIcon.style.filter = 'invert(1)'

      setTimeout(() => {
        setProductView(true)
      }, 200);

    }

    //Exploration view
    else {

      // bgVideo.style.zIndex = '10'



      childRef.current.showExplorationNodes()
      // childRef.current.setLegendToInsights()


      // childRef02.current.changeLogoutIcon('insights')


      // app.style.backgroundColor = '#795FB0'
      // expText.style.color = 'white'
      // prodText.style.color = '#bcaed8'
      // logoutText.style.color = '#bcaed8'
      // burgerIcon.style.filter = 'invert(0)'



      setTimeout(() => {
        setProductView(false)
      }, 200);
    }
  }

  var tutorialOpen = false

  // var timeoutApp = undefined

  const timeoutApp = useRef(undefined)

  function stopTimeoutApp() {

    window.clearTimeout(timeoutApp.current)



  }

  function toggleTutorial() {

    const tutorial = document.getElementById('graph-tutorial')
    const tutorialContent = document.getElementById('graph-tutorial-content')
    const helpIcon = document.getElementById('graph-helpIcon')




    tutorial.style.width = "80vw"
    tutorial.style.height = "100px"
    tutorial.style.bottom = "40px"
    tutorial.style.left = "calc(20vw / 2)"

    helpIcon.style.display = 'none'

    setTimeout(() => {

      tutorialContent.style.display = 'flex'


    }, 400);


    // timeoutApp.current = window.setTimeout(() => {

    //   tutorialContent.style.display = 'none'

    //   tutorial.style.width = "0vw"
    //   tutorial.style.height = "0px"
    //   tutorial.style.bottom = "34px"
    //   tutorial.style.left = "70px"




    //   setTimeout(() => {

    //     helpIcon.style.display = 'flex'




    //   }, 400);


    // }, 10000)




  }

  //Update popup content ------------------------------------------------------------------------------------------------------

  const [signalTitle, setSignalTitle] = useState(null)
  const [signalImage, setSignalImage] = useState(null)
  const [signalDescription, setSignalDescription] = useState(null)

  const [driverTitle, setDriverTitle] = useState(null)
  const [driverDescription, setDriverDescription] = useState(null)

  const [oppTitle, setOppTitle] = useState(null)
  const [oppImage, setOppImage] = useState(null)
  const [oppDescription, setOppDescription] = useState(null)

  const [needTitle, setNeedTitle] = useState(null)
  const [needDescription, setNeedDescription] = useState(null)


  const [lastPopupParentShift, setLastPopupParentShift] = useState(undefined)
  const [lastPopupItemID, setLastPopupItemID] = useState(undefined)


  function updatePopupContent(type, notionInfo, parentShifts) {

    setLastPopupParentShift(parentShifts[0])
    setLastPopupItemID(notionInfo.id)

    const titleArray = notionInfo.properties.Name.title
    var rawTitle = ''
    var contentTitle = ''
    {
      titleArray &&
        titleArray.map((textPiece) => (rawTitle += textPiece.text.content))
      contentTitle = rawTitle.replace(/\r?\n|\r/g, '')
    }
    const titleSplit = contentTitle.split(': ')
    const titleWithoutType = titleSplit[1]

    const descriptionArray = notionInfo.properties.Description && notionInfo.properties.Description.rich_text
    var rawDescription = ''
    var contentDescription = ''
    {
      descriptionArray &&
        descriptionArray.map((textPiece) => (rawDescription += textPiece.text.content))
      contentDescription = rawDescription.replace(/\r?\n|\r/g, '')
    }

    const mediaFilesArray = notionInfo.properties['Photo/ Video'].files[0] && notionInfo.properties['Photo/ Video'].files


    switch (type) {
      case 'signal':
        setSignalTitle(titleWithoutType)
        setSignalDescription(contentDescription)
        setSignalImage(mediaFilesArray)
        break;

      case 'driver':
        setDriverTitle(titleWithoutType)
        setDriverDescription(contentDescription)
        break;

      case 'opp':
        setOppTitle(titleWithoutType)
        setOppDescription(contentDescription)
        setOppImage(mediaFilesArray)
        break;

      case 'need':
        setNeedTitle(titleWithoutType)
        setNeedDescription(contentDescription)
        break;

    }




  }



  //Sidebar toggling


  // const [sidebarLeftOpen, setSidebarLeftOpen] = useState(false)

  const sidebarLeftOpen = useRef(false)

  const [sidebarRightOpen, setSidebarRightOpen] = useState(false)
  var sidebarRightOpenLocal = false

  //current size: below 600px width
  const mediaQuery = window.matchMedia('(max-width: 600px)')
  const [below600px, setBelow600px] = useState(mediaQuery.matches)
  window.matchMedia('(max-width: 600px)').addEventListener('change', e => {
    setBelow600px(e.matches)

  });

  //general size: below 1200px screen width
  const mediaQuery2 = window.matchMedia('(max-device-width: 1024px)')
  const [mobileDevice, setMobileDevice] = useState(mediaQuery2.matches)
  window.matchMedia('(max-device-width: 1024px)').addEventListener('change', e => {
    setMobileDevice(e.matches)

  });


  useEffect(() => {


    if (rightButton && leftButton && below600px) {
      rightButton.style.zIndex = '-9000'
      leftButton.style.zIndex = '-9000'
      rightButton.style.opacity = '0'
      leftButton.style.opacity = '0'

    }

    else if (rightButton && leftButton && !below600px) {
      rightButton.style.zIndex = '0'
      leftButton.style.zIndex = '0'
      rightButton.style.opacity = '1'
      leftButton.style.opacity = '1'

    }

    const sidebarLeft = document.getElementById('sidebarLeft')

    if (!below600px && sidebarLeftOpen.current === false) {
      sidebarLeft.style.left = '-374px'
    }

    else if (below600px && sidebarLeftOpen.current === false) {

      sidebarLeft.style.left = '-280px'

    }




  }, [below600px, rightButton, leftButton]);




  function toggleSidebarLeft() {



    const sidebar = document.getElementById('sidebarLeft')

    if (sidebarLeftOpen.current === false) {
      setTimeout(() => {
        // setSidebarLeftOpen(true)
        sidebarLeftOpen.current = true
      }, 200);
      sidebar.style.left = '0px'


    }

    else if (sidebarLeftOpen.current === true) {


      sidebar.style.left = !below600px ? '-374px' : '-280px'
      setTimeout(() => {
        // setSidebarLeftOpen(false)
        sidebarLeftOpen.current = false

      }, 200);

    }
  }

  function toggleSidebarRight(notionInfo, source) {


    //update sidebar data
    if (notionInfo !== 'override') {

      updateSidebarRightData(notionInfo)

    }

    const sidebar = document.getElementById('sidebarRight')

    if (notionInfo === 'override') {

      sidebar.style.right = !below600px ? '-533px' : '-83vw'
      const rightButton = Array.from(document.getElementsByClassName('vis-button vis-right'))[0]
      rightButton.style.right = '40px'

      setTimeout(() => {
        childRef.current.setSidebarRightOpen(false)
        setSidebarRightOpen(false)
      }, 200);

      setTimeout(() => {

        updateSidebarRightData(undefined)

      }, 600);

      return
    }

    //open sidebar
    if (!sidebarRightOpenLocal) {
      setTimeout(() => {
        childRef.current.setSidebarRightOpen(true)
        setSidebarRightOpen(true)



      }, 200);
      sidebar.style.right = '0px'


    }

    //close sidebar
    else {
      sidebar.style.right = !below600px ? '-533px' : '-83vw'
      setTimeout(() => {
        childRef.current.setSidebarRightOpen(false)
        setSidebarRightOpen(false)


      }, 200);

      setTimeout(() => {

        updateSidebarRightData(undefined)



      }, 600);


    }


    //always scroll to the top again
    document.getElementById('sidebarRight').scrollTo(0, 0)



    if (source === 'popupMaximize') {

      //if panel was opened via a popup maximize click...

      //we shift the graph's focus to the parent shift
      childRef.current.focusNode()

      //and we scroll down to the element after 1 sec
      setTimeout(() => {

        const targetDiv = document.getElementById(lastPopupItemID)
        const targetTop = targetDiv.offsetTop

        document.getElementById('sidebarRight').scrollTo({
          top: targetTop,
          behavior: 'smooth'
        })

      }, 1000);







    }

  }






  const [sidebarRightData, setSidebarRightData] = useState(undefined)

  function updateSidebarRightData(data) {

    //always scroll to the top again
    document.getElementById('sidebarRight').scrollTo(0, 0)

    //setting data to undefined and update it 1ms later – with this we achieve that the image switches back to grey while loading

    setSidebarRightData(undefined)

    setTimeout(() => {
      setSidebarRightData(data)
    }, 1);

  }


  //------------------------------------------------------------------------------------------------------
  // Netlify Identity related code -------------------------------------------------------

  //variables for states of user, access, or any error that may occur 


  useEffect(() => {

    if (user) {
      setToken(user.token.access_token)
    }

  }, [user]);

  //state boolean to indicate whether Netlify identity is ready to be used. NEEDS TO BE 'false' for production! If this causes trouble in development mode, put it to 'true' for the time being.
  const [authReady, setAuthReady] = useState(false)

  //state boolean to indicate whether there was a failed login attempt. this is helpful to have to show different content after failed attempt ('you are not welcome here, bitch!')
  const [loginFailed, setLoginFailed] = useState(false)



  //a useEffect that only runs once, getting ready all the things we need for Netlify identity
  useEffect(() => {


    //event listener: what should happen when someone logs in
    netlifyIdentity.on('login', (user) => {

      //set user state to save user info
      setUser(user)

      //close Netlify login dialogue
      netlifyIdentity.close()

      // console.log('login event')
      // console.log('user is: ' + JSON.stringify(user))

    })

    //event listener: what should happen when someone logs out
    netlifyIdentity.on('logout', () => {

      //set user state back to null
      setUser(null)
      // console.log('logout event')

    })

    //event listener: what should happen once Netlify identity has been initialized
    netlifyIdentity.on('init', (user) => {

      //set user state to save user info. this may seem redundant but appeared to be necessary - I believe it's for instances where the page gets reloaded and a user still exists (saved in cookie), so we need to set a user not based on a login event but just based on a (re-)initialization of Netlify identity
      setUser(user)

      //set boolean state to indicate we are ready for someone to login 
      setAuthReady(true)
      // console.log('init event')

    })


    //initialize Netlify identity with first render. Note: it's important to initialize Netlify identity only here, AFTER we have defined all the above event listeners.

    netlifyIdentity.init()


  }, [])

  //use these variables for onClick actions (click on button x to log in)
  const login = () => {
    netlifyIdentity.open()
  }

  //use these variables for onClick actions (click on button x to log oout)

  const logout = () => {
    netlifyIdentity.logout()


    childRef.current && childRef.current.closeAllPopups()

    document.getElementById('bgVideo01').style.display = 'none'
    document.getElementById('bgVideo02').style.display = 'none'


    document.getElementById('graph-tutorial').style.display = 'none'
    document.getElementById('graph-tutorial-content').style.display = 'none'
    document.getElementById('graph-helpIcon').style.display = 'none'

  }



  //a useEffect that runs whenever the user or authReady values change
  useEffect(() => {

    //if both Netlify identity is ready and we have a user to check...
    if (authReady && user) {

      //creating a url to call the 'getaccess' function that includes the (to be checked) user's email
      const urlString = '/api/getaccess'

      //calling the function with said url
      fetch(urlString, {
        method: 'POST',
        headers: {
          'Authorization': `Bearer ${user.token.access_token}`,
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          userEmail: user.email
        }),
      })
        .then(res => {

          //throwing error if no result could be generated
          if (!res.ok) {
            throw Error('Log in to see content')
          }

          //otherwise return result and json'ify it
          return res.json()
        })

        .then(data => {

          //if function granted access, we set access/error/loginFailed states accordingly
          if (data.granted) {
            // console.log('granted')
            setAccess(true)
            setError(null)
            setLoginFailed(false)
          }

          //if function did NOT grant access, we set access/error/loginFailed states accordingly and log out user
          else {
            // console.log('NOT granted')
            setAccess(false)
            setError(false)
            setLoginFailed(true)
            netlifyIdentity.logout()
          }
        })

        //catch error if there is one
        .catch((err) => {
          setError(err.message)
          setAccess(false)
        })
    }

  }, [user, authReady])

  //END of Netlify Identity related code
  //----------------------------------------------------------------------------------------------------------

  useEffect(() => {

    if (productView && rightButton !== undefined && graphDone) {

      rightButton.style.display = 'unset'

    }

    else if (!productView && leftButton !== undefined && rightButton !== undefined) {

      leftButton.style.display = 'none'
      rightButton.style.display = 'none'

    }



  }, [productView, graphDone]);

  // const videoRef = useRef();

  useEffect(() => {


    if (sidebarRightOpen) {
      sidebarRightOpenLocal = true
    }

    else {
      sidebarRightOpenLocal = false
    }

  }, [sidebarRightOpen]);



  const shouldUpdate = false

  const currentSite = useRef(undefined)

  //Aux function to create random string
  function makeID(length) {
    var result = '';
    var characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    var charactersLength = characters.length;
    for (var i = 0; i < length; i++) {
      result += characters.charAt(Math.floor(Math.random() *
        charactersLength));
    }
    return result;
  }

  const allVideoPlayers = useRef([])

  function stopOtherVideos(videoPlayerID) {
    if (videoPlayerID === 'all') {

      // console.log('inside all... allvideoPlayers: ' + JSON.stringify(allVideoPlayers))

      allVideoPlayers.current && allVideoPlayers.current.map((videoPlayer) => {

        const possiblePlayer = document.getElementById('popupVideoPlayer' + videoPlayer)
        // console.log('trying to pause player with id: ' + videoPlayer)
        possiblePlayer && possiblePlayer.pause()
      })

      return
    }

    var iterationsCounter = 1


    if (videoPlayerID !== allVideoPlayers.current[allVideoPlayers.current.length - 1]) {

      if (videoPlayerID !== 'all') {
        // console.log('adding player with id: ' + videoPlayerID)
        allVideoPlayers.current.push(videoPlayerID)
      }

      allVideoPlayers.current && allVideoPlayers.current.map((videoPlayer) => {

        // console.log('allVideoPlayers.length: ' + allVideoPlayers.current.length)
        // console.log('iterationsCounter: ' + iterationsCounter)


        if (allVideoPlayers.current.length - iterationsCounter !== 0) {
          const possiblePlayer = document.getElementById('popupVideoPlayer' + videoPlayer)
          possiblePlayer && possiblePlayer.pause()
          // console.log('stopping player with id: ' + videoPlayer)

          iterationsCounter += 1

        }

        else {
          // console.log('NOT stopping player, last itme, id: ' + videoPlayer)

          iterationsCounter += 1


        }


      })




    }

  }



  return (

    <BrowserRouter>


      <div className="App" id="app">

        {/* <video playsInline muted autoPlay loop className='bgVideo' id="bgVideo" ref={videoRef}>
          <source src={gradientVideoURL} type="video/mp4" />
        </video> */}

        <video playsInline muted autoPlay loop className='bgVideo01' id="bgVideo01" style={!productView ? { zIndex: '10' } : { zIndex: '0' }}>
          <source src={gradientVideo} type="video/mp4" />
        </video>

        <video playsInline muted autoPlay loop className='bgVideo02' id="bgVideo02" style={!productView ? { zIndex: '0' } : { zIndex: '10' }}>
          <source src={gradientVideo02} type="video/mp4" />
        </video>


        {/* {error && (
                <div>{error}</div>
            )} */}

        <SidebarLeft toggleSidebarLeft={toggleSidebarLeft} sidebarLeftOpen={sidebarLeftOpen} setShowViewToggle={setShowViewToggle} toggleViewMode={toggleViewMode} productView={productView} setProductView={setProductView} sidebarRightOpen={sidebarRightOpen} setSidebarRightOpen={setSidebarRightOpen} setSidebarRightData={setSidebarRightData} below600px={below600px} networkPage={networkPage} currentSite={currentSite} />

        <SidebarRight toggleSidebarRight={toggleSidebarRight} sidebarRightOpen={sidebarRightOpen} sidebarRightData={sidebarRightData} productView={productView} allData={appLevelMasterArray} below600px={below600px} makeID={makeID} stopOtherVideos={stopOtherVideos} />


        <Navbar access={access} user={user} logout={logout} toggleSidebarLeft={toggleSidebarLeft} sidebarLeftOpen={sidebarLeftOpen} showViewToggle={showViewToggle} setShowViewToggle={setShowViewToggle} toggleViewMode={toggleViewMode} productView={productView} ref={childRef02} toggleTutorial={toggleTutorial} navbarBG={navbarBG} below600px={below600px} mobileDevice={mobileDevice} networkPage={networkPage} />


        {!user &&
          <NoLogin access={access} setAccess={setAccess} error={error} setError={setError} user={user} authReady={authReady} login={login} logout={logout} loginFailed={loginFailed} setGraphDone={setGraphDone} setSidebarRightOpen={setSidebarRightOpen} setNavbarBG={setNavbarBG} />
        }

        {user && access &&
          <>
            <Routes>

              <Route path='/' element={<Introduction intro={intro} setGraphDone={setGraphDone} setSidebarRightOpen={setSidebarRightOpen} setNavbarBG={setNavbarBG} navbarScrollBehavior={navbarScrollBehavior} mobileDevice={mobileDevice} networkPage={networkPage} />}>
                <Route index element={<Introduction setGraphDone={setGraphDone} setSidebarRightOpen={setSidebarRightOpen} setNavbarBG={setNavbarBG} navbarScrollBehavior={navbarScrollBehavior} mobileDevice={mobileDevice} networkPage={networkPage} />} />
              </Route>

              <Route path='/network' element={<Main networkNodesAll={networkNodesAll} networkNodesIns={networkNodesIns} toggleSidebarRight={toggleSidebarRight} updateSidebarRightData={updateSidebarRightData} shouldUpdate={shouldUpdate} showViewToggle={showViewToggle} setShowViewToggle={setShowViewToggle} ref={childRef} updatePopupContent={updatePopupContent} stopTimeoutApp={stopTimeoutApp} setGraphDone={setGraphDone} graphDone={graphDone} appLevelMasterArray={appLevelMasterArray} setSidebarRightOpen={setSidebarRightOpen} sidebarRightOpen={sidebarRightOpen} setNavbarBG={setNavbarBG} below600px={below600px} navbarScrollBehavior={navbarScrollBehavior} appHasFetched={appHasFetched} networkPage={networkPage} mobileDevice={mobileDevice} currentSite={currentSite} stopOtherVideos={stopOtherVideos} />} />

              <Route path='/productOpp' element={<ProductOpportunities networkNodesAll={networkNodesAll} networkNodesOpp={networkNodesOpp} networkNodesIns={networkNodesIns} toggleSidebarRight={toggleSidebarRight} updateSidebarRightData={updateSidebarRightData} shouldUpdate={shouldUpdate} showViewToggle={showViewToggle} setShowViewToggle={setShowViewToggle} ref={childRef} updatePopupContent={updatePopupContent} stopTimeoutApp={stopTimeoutApp} setProductView={setProductView} toggleViewMode={toggleViewMode} setGraphDone={setGraphDone} graphDone={graphDone} appLevelMasterArray={appLevelMasterArray} setSidebarRightOpen={setSidebarRightOpen} setNavbarBG={setNavbarBG} below600px={below600px} navbarScrollBehavior={navbarScrollBehavior} mobileDevice={mobileDevice} appHasFetched={appHasFetched} networkPage={networkPage} currentSite={currentSite} stopOtherVideos={stopOtherVideos} />} />

              <Route path='/marketDD' element={<MarketDeepDives market={market} notionDataMarket={notionDataMarket} setGraphDone={setGraphDone} setSidebarRightOpen={setSidebarRightOpen} setNavbarBG={setNavbarBG} shouldUpdate={shouldUpdate} navbarScrollBehavior={navbarScrollBehavior} mobileDevice={mobileDevice} networkPage={networkPage} />} />

              <Route path='/videoReels' element={<VideoReels sizzle={sizzle} setGraphDone={setGraphDone} setSidebarRightOpen={setSidebarRightOpen} setNavbarBG={setNavbarBG} navbarScrollBehavior={navbarScrollBehavior} mobileDevice={mobileDevice} networkPage={networkPage} />} />

              <Route path='/credits' element={<Credits credits={credits} setGraphDone={setGraphDone} setSidebarRightOpen={setSidebarRightOpen} setNavbarBG={setNavbarBG} navbarScrollBehavior={navbarScrollBehavior} mobileDevice={mobileDevice} networkPage={networkPage} />} />

              <Route path='/animations' element={<Animations setGraphDone={setGraphDone} setSidebarRightOpen={setSidebarRightOpen} setNavbarBG={setNavbarBG} mobileDevice={mobileDevice} networkPage={networkPage} />} />



            </Routes>


            {!graphDone && networkPage.current &&
              <img src={sandClock} className="loading-icon" alt="Loading Network" />
            }

            <div className="popup-signal" id="popup-signal">
              <img src={popupSignal} alt='popupSignal' className="popup-signal-bgImage" />
              <div className="popup-signal-buttonUnit">
                <img src={minimizeButton} alt='minimizeButton' className="popup-signal-button" />
                <img src={maximizeInactiveButton} alt='maximizeButton' className="popup-signal-button-max" />
                <img src={closeButton} alt='closeButton' className="popup-signal-button-link" onClick={() => childRef.current.closeAllPopups()} />
              </div>


              <div className="popup-signal-whitebox">
                <div className="popup-description">
                  <h4>{signalTitle}</h4>

                  {signalImage &&

                    signalImage.map((file) => {

                      const mediaSource = file.type
                      var mediaURL = ''

                      //defining the media url based on whether it's external vs file
                      if (mediaSource === 'external') {
                        mediaURL = file.external.url
                      }

                      else if (mediaSource === 'file') {
                        mediaURL = file.file.url

                      }

                      //figuring out if it's a photo or video
                      const fileNameSplit = file.name.split('.')
                      const fileEnding = fileNameSplit[1]

                      if (fileEnding === 'mp4' || fileEnding === 'MP4') {

                        const newID = makeID(16)

                        if (mediaURL.startsWith('http')) {
                          return (

                            <video controls src={mediaURL} alt="signalVideo" onClick={() => stopOtherVideos(newID)} id={"popupVideoPlayer" + newID} className="popup-image" key={file.name} />

                          )

                        }

                        else {

                          const localFile = require(`${mediaURL}`)
                          const newID = makeID(16)


                          return (
                            <video controls src={localFile} alt="signalVideo" onClick={() => stopOtherVideos(newID)} id={"popupVideoPlayer" + newID} className="popup-image" key={file.name} />
                          )
                        }

                      }

                      else {

                        if (mediaURL.startsWith('http')) {

                          return (

                            <img src={mediaURL} alt="signalImage" className="popup-image" key={file.name} />

                          )

                        }

                        else {
                          const localFile = require(`${mediaURL}`)

                          return (

                            <img src={localFile} alt="signalImage" className="popup-image" key={file.name} />

                          )


                        }

                      }



                    })
                  }

                  {signalDescription}
                </div>
                {/* <div className="popup-bottomRow">
                  <div className="popup-bottomRow-close" onClick={() => childRef.current.closeAllPopups()}>&times;</div>

                </div> */}
              </div>
            </div>

            <div className="popup-driver" id="popup-driver">
              <img src={popupDriver} alt='popupDriver' className="popup-driver-bgImage" />
              <div className="popup-driver-buttonUnit">
                <img src={minimizeButton} alt='minimizeButton' className="popup-driver-button" />
                <img src={maximizeInactiveButton} alt='maximizeButton' className="popup-driver-button-max" />
                <img src={closeButton} alt='closeButton' className="popup-driver-button-link" onClick={() => childRef.current.closeAllPopups()} />
              </div>


              <div className="popup-driver-whitebox">
                <div className="popup-description">
                  <h4>{driverTitle}</h4>
                  {driverDescription}
                </div>
                {/* <div className="popup-bottomRow">
                  <div className="popup-bottomRow-close" onClick={() => childRef.current.closeAllPopups()}>&times;</div>

                </div> */}
              </div>
            </div>

            <div className="popup-driver" id="popup-need">
              <img src={popupNeed} alt='popupDriver' className="popup-driver-bgImage" />
              <div className="popup-driver-buttonUnit">
                <img src={minimizeButton} alt='minimizeButton' className="popup-driver-button" />
                <img src={maximizeButton} alt='maximizeButton' className="popup-need-button-max" onClick={() => { toggleSidebarRight(lastPopupParentShift, 'popupMaximize'); setTimeout(() => { childRef.current.closeAllPopups() }, 0); childRef.current.repositionNavButtons('focus') }} />
                <img src={closeButton} alt='closeButton' className="popup-driver-button-link" onClick={() => childRef.current.closeAllPopups()} />
              </div>


              <div className="popup-driver-whitebox">
                <div className="popup-description">
                  <h4>{needTitle}</h4>
                  {needDescription}
                </div>
                {/* <div className="popup-bottomRow">
                  <div className="popup-bottomRow-close" onClick={() => childRef.current.closeAllPopups()}>&times;</div>

                </div> */}
              </div>
            </div>

            <div className="popup-signal" id="popup-opp">
              <img src={popupOpp} alt='popupSignal' className="popup-signal-bgImage" />
              <div className="popup-signal-buttonUnit">
                <img src={minimizeButton} alt='minimizeButton' className="popup-signal-button" />
                <img src={maximizeButton} alt='maximizeButton' className="popup-opp-button-max" onClick={() => { toggleSidebarRight(lastPopupParentShift, 'popupMaximize'); setTimeout(() => { childRef.current.closeAllPopups() }, 0); childRef.current.repositionNavButtons('focus') }} />
                <img src={closeButton} alt='closeButton' className="popup-signal-button-link" onClick={() => childRef.current.closeAllPopups()} />
              </div>


              <div className="popup-signal-whitebox">
                <div className="popup-description">
                  <h4>{oppTitle}</h4>

                  {oppImage &&

                    oppImage.map((file) => {

                      const mediaSource = file.type
                      var mediaURL = ''

                      //defining the media url based on whether it's external vs file
                      if (mediaSource === 'external') {
                        mediaURL = file.external.url
                      }

                      else if (mediaSource === 'file') {
                        mediaURL = file.file.url

                      }

                      //figuring out if it's a photo or video
                      const fileNameSplit = file.name.split('.')
                      const fileEnding = fileNameSplit[1]

                      if (fileEnding === 'mp4' || fileEnding === 'MP4') {

                        if (mediaURL.startsWith('http')) {

                          const newID = makeID(16)


                          return (

                            <video controls src={mediaURL} alt="oppVideo" onClick={() => stopOtherVideos(newID)} id={"popupVideoPlayer" + newID} className="popup-image" key={file.name} />

                          )

                        }

                        else {
                          const localFile = require(`${mediaURL}`)
                          const newID = makeID(16)


                          return (
                            <video controls src={localFile} alt="oppVideo" onClick={() => stopOtherVideos(newID)} id={"popupVideoPlayer" + newID} className="popup-image" key={file.name} />

                          )
                        }

                      }

                      else {

                        if (mediaURL.startsWith('http')) {


                          return (

                            <img src={mediaURL} alt="oppImage" className="popup-image" key={file.name} />

                          )

                        }

                        else {

                          const localFile = require(`${mediaURL}`)


                          return (
                            <img src={localFile} alt="oppImage" className="popup-image" key={file.name} />

                          )


                        }

                      }



                    })
                  }
                  {oppDescription}
                </div>
                {/* <div className="popup-bottomRow">
                  <div className="popup-bottomRow-close" onClick={() => childRef.current.closeAllPopups()}>&times;</div>

                </div> */}
              </div>
            </div>

            <div className="graph-tutorial" id="graph-tutorial">

              <div className="graph-tutorial-content" id="graph-tutorial-content" style={!productView ? { width: '80%' } : { width: '85%' }}>

                <div className="graph-tutorial-column1" style={!productView ? { width: '33%' } : { width: '33%' }}>


                  <div className="graph-tutorial-columnRow1">
                    <img src={bigNode} alt="shiftNode" style={{ width: '22px', height: '22px', marginRight: '12px' }} />
                    Large nodes: Key themes
                  </div>

                  <div className="graph-tutorial-columnRow2">
                    <img src={drag} alt="drag" style={{ width: '18px', height: '18px', marginRight: '12px' }} />
                    <div style={{ color: '#FFE41E' }}>Drag</div>&nbsp;to move canvas
                  </div>

                </div>

                <div className="graph-tutorial-column2" style={!productView ? { width: '40%' } : { width: '32%' }}>


                  <div className="graph-tutorial-columnRow1">
                    {!productView &&
                      <>
                        <div className="tutorialNode" style={{ backgroundColor: '#CFFFB7' }} />
                        &nbsp;Medium nodes: Behavioral drivers
                      </>
                    }
                    {productView && <>
                      <div className="tutorialNode" style={{ backgroundColor: '#FFFFFF' }} />
                      &nbsp;Medium nodes: User needs</>
                    }
                  </div>

                  <div className="graph-tutorial-columnRow2">
                    <img src={arrowCursor} alt="drag" style={{ width: '18px', height: '18px', marginRight: '12px' }} />
                    <div style={{ color: '#FFE41E' }}>Click</div>&nbsp;node to learn more
                  </div>

                </div>

                <div className="graph-tutorial-column3" style={!productView ? { width: '26%' } : { width: '34%' }}>

                  <div className="graph-tutorial-columnRow1">
                    {!productView &&
                      <>
                        <img src={smallNode} alt="shiftNode" style={{ width: '22px', height: '22px', marginRight: '12px' }} />
                        Small nodes: Examples
                      </>
                    }
                    {productView && <>
                      <div className="tutorialNode" style={{ backgroundColor: '#6580EF' }} />
                      &nbsp;Small nodes: Product opportunities</>
                    }


                  </div>

                  <div className="graph-tutorial-columnRow2">
                    <img src={magnifier} alt="drag" style={{ width: '18px', height: '18px', marginRight: '12px' }} />
                    <div style={{ color: '#FFE41E' }}>Scroll/pinch</div>&nbsp;to zoom
                  </div>


                </div>

                <div className="graph-tutorial-dottedLine"></div>

              </div>
            </div>



            <img src={helpIcon} className="graph-helpIcon" style={!productView ? {} : { filter: 'invert(1)' }} id="graph-helpIcon" onClick={() => toggleTutorial()} />

          </>
        }
      </div>


    </BrowserRouter>

  );
}

export default App;
